import axios from "axios";
import axiosConfig from '../axiosConfig';
import {
    GET_IP_LOOKUP_FAIL,
    GET_IP_LOOKUP_SUCCESS,
    GET_IP_LOOKUP_REQUEST,
    GET_OWN_IP_SUCCESS,
    GET_IPV6_SUCCESS,
    GET_IPV6_FAILED,
    UPDATE_DETAILS_IP_INFO,
    SAVE_MY_IP_DETAILS_FAIL,
    SAVE_MY_IP_DETAILS_SUCCESS,
    SAVE_MY_IP_DETAILS_REQUEST,
    SET_PRECISE_LOCATION,
    SAVE_USER_IPINFO_REQUEST,
    SAVE_USER_IPINFO_SUCCESS,
    SAVE_USER_IPINFO_FAILED,
    GET_USER_IPINFO_REQUEST,
    GET_USER_IPINFO_SUCCESS,
    GET_USER_IPINFO_FAILED,
    SET_LOCATION_PERMISSION_STATUS
} from "../types";
import { BASE_URL } from "../config";
 
const handleIpLookupActionResponse = async(response,dispatch)=>{ 
    if (navigator.geolocation) {
        let locationStatus = await navigator.permissions.query({ name: 'geolocation' }).then((res)=>res?.state);
        if (locationStatus !== 'granted') {
            return dispatch({ type: GET_IP_LOOKUP_SUCCESS, payload: response.data })
        }else {
            navigator.geolocation.getCurrentPosition((position)=>{
                const { latitude, longitude } = position.coords;  
                let tempLocation = response.data;
                tempLocation.location.latitude = latitude;
                tempLocation.location.longitude = longitude; 
                dispatch({ type: SET_LOCATION_PERMISSION_STATUS, payload: true })
                return dispatch({ type: GET_IP_LOOKUP_SUCCESS, payload: tempLocation })
            }, ()=>{
                return dispatch({ type: GET_IP_LOOKUP_SUCCESS, payload: response.data })
            },{ enableHighAccuracy: true });
        }
    }else{
        return dispatch({ type: GET_IP_LOOKUP_SUCCESS, payload: response.data })
    }
}
 
export const IpLookupAction = (ip) => (dispatch) => { 
    const payload = { ip };
    saveUserIPInfo(payload);
    dispatch({ type: GET_IP_LOOKUP_REQUEST });
    axios.post(`${BASE_URL}ip-lookup`, payload)
        .then((response) => { 
            handleIpLookupActionResponse(response,dispatch); 
         })
        .catch((error) => { return dispatch({ type: GET_IP_LOOKUP_FAIL, payload: error.response.data }) })
}

export const GetOwnIpActionOld = () => (dispatch) => {
    dispatch({ type: GET_IP_LOOKUP_REQUEST });
    axios.get(`https://geolocation-db.com/json/`)
        .then((response) => { return dispatch({ type: GET_OWN_IP_SUCCESS, payload: response.data }) })
        .catch((error) => { 
            axios.get(`https://ipapi.co/json/`)
            .then((response) => {
                let ipData ={};
                ipData.country_code = response?.data?.country_code;
                ipData.country_name = response?.data?.country_name;
                ipData.city = response?.data?.city;
                ipData.postal = response?.data?.postal;
                ipData.latitude = response?.data?.latitude;
                ipData.longitude = response?.data?.longitude;
                ipData.IPv4 = response?.data?.ip;
                ipData.state = response?.data?.region;
                return dispatch({ type: GET_OWN_IP_SUCCESS, payload: ipData }) 
            })
            .catch((err) => {  
                return dispatch({ type: GET_IP_LOOKUP_FAIL, payload: error.response.data }) 
            })
        })
} 

const fetchIpDetailsData = async (dispatch,retryCount=0) => { 
    try { 
        let response = await axios.get(`https://geolocation-db.com/json/`);  
        if(response.status===200 &&  response.data) {
            return dispatch({ type: GET_OWN_IP_SUCCESS, payload: response.data })
        } 
    } catch (error) { 
        retryCount ++;  
        if (retryCount <= 9) { 
            fetchIpDetailsData(dispatch,retryCount);
        }else{ 
            return dispatch({ type: GET_IP_LOOKUP_FAIL, payload: error?.response?.data }) 
        }
    }
}

export const GetOwnIpAction = ()=>(dispatch)=>{
    dispatch({ type: GET_IP_LOOKUP_REQUEST }); 
    fetchIpDetailsData(dispatch);
}

export const UpdateDetailsIpInfo = (ip) => (dispatch) => {
    return dispatch({ type: UPDATE_DETAILS_IP_INFO, payload: ip })
}

export const saveMyIpDetails = (ip) => (dispatch) => {
    const payload = { ip };
    dispatch({ type: SAVE_MY_IP_DETAILS_REQUEST });
    return axios.post(`${BASE_URL}ip-lookup`, payload)
        .then((response) => { return dispatch({ type: SAVE_MY_IP_DETAILS_SUCCESS, payload: response.data }) })
        .catch((error) => { return dispatch({ type: SAVE_MY_IP_DETAILS_FAIL, payload: error }) })
}

export const getIPversion6 = () => (dispatch) => {
    axios.get("https://api64.ipify.org/")
        .then((response) => { return dispatch({ type: GET_IPV6_SUCCESS, payload: response.data }) })
        .catch((error) => { return dispatch({ type: GET_IPV6_FAILED, payload: error.data }) })
}

export const setPreciseLocation = (data) => (dispatch) => {
    return dispatch({ type: SET_PRECISE_LOCATION, payload: data })
}

export const saveUserIPInfo = (ip,email) => (dispatch) => {
    const payload = { ipdetails:ip,email:email };
    dispatch({ type: SAVE_USER_IPINFO_REQUEST });
    axiosConfig.post(`${BASE_URL}save-user-ipinfo`, payload)
        .then((response) => { return dispatch({ type: SAVE_USER_IPINFO_SUCCESS, payload: response?.data }) })
        .catch((error) => { return dispatch({ type: SAVE_USER_IPINFO_FAILED, payload: error?.response?.data }) })
}

export const getUserIPInfoHistory = (email) => (dispatch) => { 
    const payload = { email:email };
    dispatch({ type: GET_USER_IPINFO_REQUEST });
    axiosConfig.post(`${BASE_URL}get-user-ipinfo`,payload)
        .then((response) => { return dispatch({ type: GET_USER_IPINFO_SUCCESS, payload: response?.data?.response }) })
        .catch((error) => { return dispatch({ type: GET_USER_IPINFO_FAILED, payload: error?.response?.data }) })
}