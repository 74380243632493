import React, { useState } from 'react'
import Papa from "papaparse"; 
import ReactLoading from "react-loading"; 
import { Form, Row, Col, Button } from 'react-bootstrap';
//import Menubar from '../../components/Menubar/Menubar';

const UserValidate = () => { 
  const [file, setFile] = useState();
  const [validationType, setValidationType] = useState('1');
  const [fileError,setFileError] = useState('');  
  const [uploadedCSVReport, setUploadedCSVReport] = useState({});
  const [csvData,setCsvData] = useState([]); 
  const [showReport, setShowReport] = useState(false); 
  const [reportLoading, setReportLoading] = useState(false);  
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false); 
  //const [parsedData, setParsedData] = useState([]);
  //const [tableRows, setTableRows] = useState([]);
  //const [values, setValues] = useState([]); 

  const handleSubmit = (e) => {
      setReportLoading(true);
      setShowReport(false);
      e.preventDefault();     
      // console.log(`log1---->values`,csvData); 
      // console.log(`log1---->validationType`,validationType , typeof validationType);  
      let regexPattern;
      if(validationType === '5'){ //IPv4
        regexPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i; 
      }else if(validationType === '2'){ //IDFA
        regexPattern = /^[0-9A-Z]{8}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{12}$/; 
      }else if(validationType === '3'){ //BEESWAX
        regexPattern = /^[^\s]{22}$/; 
      }else if(validationType === '4'){ //SHA1
        regexPattern = /^[a-z0-9]{40}$/; 
      }else if(validationType === '1'){ //GAID - 1
        regexPattern = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/;  
      }

      let successItemArr = [];
      let errorItemArr = [];
      if(csvData && csvData.length > 0 ){
        csvData.forEach(element => {
          if (regexPattern.test(element)) {
            successItemArr.push(element); 
          }else{
            errorItemArr.push(element);
          }
        }); 
      }
      setUploadedCSVReport( {
        totalCount:csvData.length,
        successCount:successItemArr.length,
        errorCount:errorItemArr.length, 
        successItemArr:successItemArr,
        errorItemArr:errorItemArr,
        csvData:csvData
      });
      setShowReport(true);  
      setReportLoading(false);
  };

  const onFileChangeHandle = (e) => {
    const allowedExtensions = ['csv','xlsx','xls']; 
    const tempFile = e.target.files[0];
    const fileExtension = tempFile.name.split('.').pop().toLowerCase(); 

    if (!allowedExtensions.includes(fileExtension)) {
      setFileError(`Invalid file type. Please select a file with one of the allowed extensions: ${allowedExtensions.join(', ')}`);
      return;
    }else{
      setDisableSubmitBtn(true);
      setFileError('');
      setFile(e.target.files[0]);

      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
  
          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
            return null
          });  
          // Parsed Data Response in array format
          //setParsedData(results.data); 
          // Filtered Column Names
          //setTableRows(rowsArray[0]); 
          // Filtered Values
          //setValues(valuesArray);
          valuesArray.push(rowsArray[0]);

          let newValuesArr = [];
          valuesArray.forEach((item,i)=>{
            newValuesArr.push(item[0]); 
          }) 
          setCsvData(newValuesArr)
        },
      });
      let timeOut = tempFile.size > (20 * 1024 * 1024) ? 200 : 100; 
      setTimeout(()=>{
        setDisableSubmitBtn(false);
      }, timeOut);
    }
  }; 

  const handleSelectChange = (e)=>{
    setValidationType(e.target.value)
  } 

  async function downloadDataAsCSV(data, filename) {
    const csv = data.map(row => row).join("\n")
    await downloadCSVChunk(csv, `${filename}.csv`);
  }
  
  function downloadCSVChunk(csvData, filename) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const downloadApprovedFiles = ()=>{
    downloadDataAsCSV(uploadedCSVReport?.successItemArr, 'Approved_Records');
  }

  const downloadRejectedFiles = ()=>{
    downloadDataAsCSV(uploadedCSVReport?.errorItemArr, 'Failed_Records');
  }

  const validateTypesList = [
    { title:'GAID', value:1 },
    { title:'IDFA', value:2 },
    { title:'BEESWAX', value:3 },
    { title:'SHA1', value:4 },
    { title:'IPv4', value:5 },
  ]

  return (
    <div className='min-h-screen flex flex-col px-8	justify-left'>
        <h1 className='py-4 text-xl font-bold'>User ID Validator</h1>  
          <Form noValidate onSubmit={handleSubmit}>
            <Row className='mb-3'> 
                <Col xs={1}><Form.Label>Upload File</Form.Label> </Col> 
                <Col xs={5}> 
                  <Form.Group controlId="formFile" className="mb-3"> 
                    <Form.Control type="file" onChange={onFileChangeHandle} /> 
                    {fileError && <div className='w-full text-[0.875em] text-[#dc3545] mt-1'>{fileError}</div>}
                  </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xs={1}><Form.Label>Type</Form.Label> </Col>
                <Col xs={5}> 
                  <Form.Select aria-label="Default select validationTypeSelection"  onChange={handleSelectChange} value={validationType}>  
                    {validateTypesList.map((i,k)=> {
                        return <option key={k} value={i.value} >{i.title}</option>
                    })}
                  </Form.Select>
                </Col>
            </Row>
            {file && validationType  && !disableSubmitBtn ? <Button type="submit"  variant="primary" >Submit</Button> : <Button style={{cursor:'not-allowed'}} >Submit</Button>}
          </Form>
          {showReport && !reportLoading && (
            <Row className='py-2'>
              <Col xs={12} className='font-bold'>Validation Result:</Col>
              <Col xs={12} className='font-normal'>{uploadedCSVReport?.totalCount} records analyzed. {uploadedCSVReport?.successCount} OK, {uploadedCSVReport?.errorCount} errors.</Col>
              <Col xs={12} className='font-normal py-2 gap-3 flex'>
                  <Button className='btn-green' variant="success" onClick={downloadApprovedFiles}>Download Approved Records</Button>
                  <Button className='btn-green' variant="success" onClick={downloadRejectedFiles}>Download Failed Records</Button>
              </Col>
            </Row>
          )}
          {reportLoading &&   <div className='flex flex-row items-center px-20 py-10'> <ReactLoading type={'spin'} color='#343a40' height={30} width={30} /></div>}
    </div>
  )
}

export default UserValidate;