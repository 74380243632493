import { useState, useEffect } from "react";
import { createPortal } from "react-dom";


function IFrame({ children,iframeWidth }) {
  const [ref, setRef] = useState();
  const container = ref?.contentDocument?.body;
  return (
    <iframe
      title="iframeTestAdstrQA"
      ref={setRef}
      width={iframeWidth}
      height="auto"
      id='test-iframe-id' 
    >
      {container && createPortal(children, container)}
    </iframe>
  );
}

function MyComponent({ userId, width,protocolVal,tagVal,tagType, formField }) { 
  
  useEffect(() => {
    if(tagType){ 
      const iframe = document.getElementById('test-iframe-id');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      // Create a script element
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript'; 

      scriptElement.text = `  
      var scriptTag = document.createElement('script');  
      scriptTag.src = '${protocolVal}://noas.powerium.net/getAd?idfa=${userId}';  
      document.body.appendChild(scriptTag)`;
      // Append the script element to the head of the iframe document
      iframeDocument.head.appendChild(scriptElement);

      const scriptElement2 = document.createElement('script');
      scriptElement2.type = 'text/javascript'; 

      scriptElement2.text = `  
      var scriptTag = document.createElement('script'); 
      scriptTag.src = '{{IMPRESSION_URL}}';       
      document.body.appendChild(scriptTag)`; 
      iframeDocument.head.appendChild(scriptElement2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId,protocolVal]); 

 
  

  let scriptTestTemp; 
  scriptTestTemp = ` 
  var clickURLs = [
				"https://imp.powerium.net/DSPCLogger/ClickProcessor?idfa=${userId}&user_id_sha1=${formField.user_id_sha1 || '{{USER_ID_SHA1}}'}&appName=${formField.app_name || '{{APP_NAME}}'}&appVer=&bundle=${formField.app_bundle || '{{APP_BUNDLE}}'}&appCategory=&appId=${formField.app_id || '{{APP_ID}}'}&sourceId={{EXCHANGE_HANDLE}}&domain=${formField.domain || '{{DOMAIN}}'}&source={{INVENTORY_SOURCE}}&page={{PAGE}}&pubId={{PUBLISHER_ID}}&dnt={{DNT}}&uid={{AUCTION_ID}}&ua=${formField.user_agent || '{{USER_AGENT_ESC}}'}&ip=${formField.ipv4_address || '{{IP_ADDRESS_ESC}}'}&os=${formField.operating_sys || '{{PLATFORM_OS}}'}&deviceMake=${formField.device_manufacturer || '{{DEVICE_MAKE}}'}&deviceModel=${formField.device_model || '{{DEVICE_MODEL}}'}&country=${formField.country_code || '{{COUNTRY_CODE}}'}&lat={{LAT}}&lon={{LONG}}&width={{WIDTH}}&height={{HEIGHT}}&creativeId={{CREATIVE_ID}}&campaignId={{CAMPAIGN_ID}}&campaignName=&exc={{INVENTORY_SOURCE}}&placementId={{PLACEMENT_ID}}&zip={{ZIP_CODE}}&ipv6=${formField.ipv6_address || '{{IP_ADDRESS_IPV6}}'}&dsp=BW",
				"{{CLICK_URL}}",
			];
			function clicker() {
				for (var i = 0; i < clickURLs.length; i++) {
					new Image().src = clickURLs[i];
				}
			}
			function getCookies() {
				var cookies = document.cookie.split(";");
				var ret = "";
				for (var i = 0; i < cookies.length; i++) {
					ret += cookies[i] + "~";
				}
				return ret;
			}
			getUA()
				.then((reso) => {
					var url =
						"https://imp.powerium.net/DSPCLogger/Impoint?idfa=${userId}&user_id_sha1=${formField.user_id_sha1 || '{{USER_ID_SHA1}}'}&appName=${formField.app_name || '{{APP_NAME}}'}&bundle=${formField.app_bundle || '{{APP_BUNDLE}}'}&appId=${formField.app_id || '{{APP_ID}}'}&sourceId={{EXCHANGE_HANDLE}}&domain=${formField.domain || '{{DOMAIN}}'}&source={{INVENTORY_SOURCE}}&page={{PAGE}}&pubId={{PUBLISHER_ID}}&dnt={{DNT}}&uid={{AUCTION_ID}}&ua=${formField.user_agent || '{{USER_AGENT_ESC}}'}&ip=${formField.ipv4_address || '{{IP_ADDRESS_ESC}}'}&os=${formField.operating_sys || '{{PLATFORM_OS}}'}&deviceMake=${formField.device_manufacturer || '{{DEVICE_MAKE}}'}&deviceModel=${formField.device_model || '{{DEVICE_MODEL}}'}&country=${formField.country_code || '{{COUNTRY_CODE}}'}&lat={{LAT}}&lon={{LONG}}&width={{WIDTH}}&height={{HEIGHT}}&creativeId={{CREATIVE_ID}}&campaignId={{CAMPAIGN_ID}}&campaignName=&exc={{INVENTORY_SOURCE}}&placementId={{PLACEMENT_ID}}&zip={{ZIP_CODE}}&ipv6=${formField.ipv6_address || '{{IP_ADDRESS_IPV6}}'}&lineItem={{LINE_ITEM_ID}}&dsp=BW&cookie=" +
						getCookies() +
						"&newplat=" +
						reso.platform +
						"&newmodel=" +
						reso.model +
						"&newplatver=" +
						reso.platformVersion +
						"&newbrowver=" +
						reso.uaFullVersion;
					new Image().src = url;
				})
				.catch((err) => {});
			function getUA() {
				return new Promise(function (resolve, reject) {
					resolve("");
				});
			}
  `;  
  let scriptTest = scriptTestTemp.replace(new RegExp('{{USER_ID}}', 'g'), userId);

  let iframeWidthNew =  width - (width * 5) / 100;


  return ( 
    <>
      <a href='https://s.click.aliexpress.com/e/_DcjZmiP' rel="noreferrer" target='_blank' className="flex justify-start items-center"   >
        <img src='https://cloud-images.s3.eu-west-1.amazonaws.com/MyDSP_Beeswax/Creative_Upload_Test/QA_New_Banners/AliExp_Tronsmart_Trip_Speaker_468x60.png' id="222233" alt="" style={{ width: `${iframeWidthNew}px`,border:'1px solid white' }} />
      </a>
      <script type="text/javascript">{scriptTest}</script>
    </>
  )
}

export default function TestAdsterraQA({ userId, width, protocolVal, tagVal, tagType, formField }) {
  let iframeWidth = window.innerWidth >= 780 ? 780 : window.innerWidth - (window.innerWidth * 20) / 100;

  return (
    <IFrame iframeWidth={iframeWidth}>
      <MyComponent
        userId={userId}
        width={iframeWidth}
        protocolVal={protocolVal}
        tagVal={tagVal}
        tagType={tagType}
        formField={formField}
      />
    </IFrame>
  );
}