import React from "react";
import { Link, useLocation } from "react-router-dom";

const Links = [
	{ title: "JSON To CSV", href: "/json-to-csv" },
	{ title: "CSV To JSON", href: "/csv-to-json" },
];

const JsonCsVMenuBar = () => {
	const path = useLocation().pathname;

	return (
		<div className="flex flex-row py-3">
			<nav className={` customNavMenu py-2  font-semibold`}>
				{Links.map((link) => (
					<Link
						to={link.href}
						key={link.href}
						className={`${
							path === link.href ? "active" : ""
						} transition-all`}
					>
						{link.title}
					</Link>
				))}
			</nav>
		</div>
	);
};

export default JsonCsVMenuBar;
