import React, { useEffect, useState } from 'react'
import axiosConfigXForwardIP from "../../axiosConfigXForwardIP"

const ExternalLoggerBlankPageForJsonData = () => {
  const [htmlContent, setHtmlContent] = useState('')

  const getExternalLoggerJSON = () => {
    axiosConfigXForwardIP
      .get("https://events-ui.myweb3id.io/show-logger-file")
      .then((response) => {
        setHtmlContent(response.data)
      })
      .catch((error) => {
        console.error('Error fetching the HTML content:', error)
      })
  }

  useEffect(() => {
    getExternalLoggerJSON()
  }, [])

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  )
}

export default ExternalLoggerBlankPageForJsonData
