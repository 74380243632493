/* eslint-disable no-unused-vars */
import { useState,useRef,useEffect  } from 'react';
import JsonCsVMenuBar from '../../components/JsonCsVMenuBar/JsonCsVMenuBar';
 
const CSVtoJson = () => {
    const [csvData, setCsvData] = useState(null); 
    const [jsonData, setJsonData] = useState(null);
    const [error, setError] = useState(null);
    const [isFileLoaded, setIsFileLoaded] = useState(false);
    const [fileName, setFileName] = useState('');
    
    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null);
  
    const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileName(file.name);

			const reader = new FileReader();
			reader.onload = (event) => {
				const content = event.target.result;
                
				try {
					const csv = content;
                    const json = csvToNestedJson(csv);
					setCsvData(csv);
                    setJsonData(json);
					setError(null);
					setIsFileLoaded(true);
				} catch (err) {
					setError("Invalid JSON file");
					setCsvData(null);
                    setJsonData(null);
					setIsFileLoaded(false);
				}
			};
			reader.readAsText(file);
		}
	};

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const csvToNestedJson = (csv) => {
        const rows = csv.trim().split("\n").slice(1);
        const jsonObject = {};
        const linesRow = csv.trim().split('\n');
        const headersToCheckCSV = linesRow[0].split(',');

        if (headersToCheckCSV.length === 2 && headersToCheckCSV[0]==='Key') {
            rows.forEach(row => {
                const [key, value] = row.split(/,(.+)/);
                const keys = key.split('.');
                let currentLevel = jsonObject;
            
                keys.forEach((k, i) => {
                    if (i === keys.length - 1) {
                        currentLevel[k] = value;
                    } else {
                        if (!currentLevel[k]) {
                            currentLevel[k] = {};
                        }
                        currentLevel = currentLevel[k];
                    }
                });
            });
            
            setJsonData(jsonObject)
            return jsonObject;
        } else {
            function csvToJson(csv) {
                const lines = csv.trim().split('\n');
                const headers = lines[0].split(','); // Get the header row
                
                const result = {};
                const values = lines[1].split(','); // Get the first data row
    
                // Loop through headers to construct the JSON structure
                for (let i = 0; i < headers.length; i++) {
                    const headerParts = headers[i].trim().split('.'); // Split by underscore to create nested keys
                    let currentLevel = result;
                    
                    for (let j = 0; j < headerParts.length - 1; j++) {
                        const key = headerParts[j];
                        if (!currentLevel[key]) {
                            currentLevel[key] = {};
                        }   
                        currentLevel = currentLevel[key];
                    }
    
                    currentLevel[headerParts[headerParts.length - 1]] = values[i];
                }
    
                return result;
            }
    
            // Convert CSV to JSON
            const jsonResult = csvToJson(csv);
            setJsonData(jsonResult)
            return jsonResult;
        }        
    }

    const handleSaveClick = () => {
        try {
            const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
            const url = URL.createObjectURL(jsonBlob);
        
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName.replace(".csv", "")}_new_updated.json`;
            document.body.appendChild(link); // Attach link to the body
            link.click();
            document.body.removeChild(link); // Remove link after clicking
        
            URL.revokeObjectURL(url);
            cleareJsonData()
        } catch (err) {
            console.error(err);
        }

    }

    const cleareJsonData =()=>{
        setIsFileLoaded(false);
        setCsvData(null);
        setJsonData(null);
        setError(null);
        setFileName('');
        fileInputRef.current.value = '';
        textAreaRef.current.value = '';
    }

    useEffect(()=>{
        document.title='CSV To JSON';
    },[])

    return (
        <>
            <div className={`jsonUpdatePage px-3 w-full flex flex-col h-screen overflow-hidden`}>
                <JsonCsVMenuBar />
                <div className='flex flex-col gap-2 flex-1'>
                    {/* <h1 className='font-bold'>CSV To JSON</h1> */}
                    <h2>Load CSV File {` `} : {` `} 
                        <input type="file" accept=".csv" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
                        <button className='bg-[#404cf1] text-[#fff] ml-2  font-bold p-2 px-3 rounded' onClick={handleButtonClick} >Browse</button> 
                        {jsonData && <button className='bg-[#404cf1] text-[#fff] ml-2 font-bold p-2 px-3 rounded' onClick={handleSaveClick} >Save as JSON</button> }
                    </h2>
                    <div className="flex-1">
                        <textarea
                            ref={textAreaRef}
                            rows={5}
                            className='bg-transparent border border-solid border-black w-full p-4 h-full'
                            placeholder='Converted JSON Code will see here!!'
                            value={jsonData ? JSON.stringify(jsonData, null, 2) : ""}
                            readOnly
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CSVtoJson;
