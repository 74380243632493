import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import { getExternalLogger, resetExternalEvents } from "../../actions/userAgentAction";
import Table from "../../components/Pagination/Table";
import ResetConfirmModal from "./ResetConfirmModal";
import filterStore from "../../store/zustand/filter.store";

const ExternalLogger = () => {
	const { loggerData, resetEventLoader } = useSelector((state) => state.userAgentReducer);
	const dispatch = useDispatch();
	const [dialogVisible, setDialogVisible] = useState(false);

	// filter store
	const { search } = filterStore();

	useEffect(() => {
		dispatch(getExternalLogger());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Highlight matching text within the string
	const highlightText = (text, searchTerm) => {
		if (!searchTerm) return text;
		const regex = new RegExp(`(${searchTerm})`, "gi");
		return text.split(regex).map((part, index) =>
			regex.test(part) ? (
				<span key={index} style={{ backgroundColor: "yellow" }}>
					{part}
				</span>
			) : (
				part
			)
		);
	};

	const sizePerPageList = [
		{ text: "10", value: 10 },
		{ text: "25", value: 25 },
		{ text: "50", value: 50 },
	];

	const columns = [
		{
			Header: "Timestamp",
			accessor: "timestamp",
			sort: false,
		},
		{
			Header: "URL",
			accessor: "url",
			sort: false,
			maxWidth: 500,
			minWidth: 450,
			width: 450,
			Cell: ({ row }) => {
				return <p>{highlightText(row.original.url, search)}</p>;
			},
		},
		{
			Header: "User IP",
			accessor: "userIP",
			sort: false,
		},
		{
			Header: "Referrer",
			accessor: "referrer",
			sort: false,
			maxWidth: 300,
			minWidth: 180,
			width: 180,
		},
		{
			Header: "Client Type",
			accessor: "clientType",
			sort: false,
		},
		{
			Header: "Client Name",
			accessor: "clientName",
			sort: false,
		},
		{
			Header: "Device Type",
			accessor: "devicetype",
			sort: false,
		},
		{
			Header: "Device Brand",
			accessor: "deviceBrand",
			sort: false,
		},
		{
			Header: "Device Model",
			accessor: "deviceModel",
			sort: false,
		},
		{
			Header: "OS Name",
			accessor: "osName",
			sort: false,
		},
		{
			Header: "OS Version",
			accessor: "osVersion",
			sort: false,
		},
		{
			Header: "User Agent",
			accessor: "userAgent",
			sort: false,
		},
		{
			Header: "X-Forward",
			accessor: "x-forwarded-for",
			sort: false,
		},
		{
			Header: "Register IP",
			accessor: "register_ip",
			sort: false,
		},
		{
			Header: "Socket IP",
			accessor: "socket_ip",
			sort: false,
		},
		{
			Header: "Remote Address",
			accessor: "remote_addresss",
			sort: false,
		},
	];

	let hhh = [];
	let ddd = [];

	hhh = [
		{ label: "Timestamp", key: "timestamp" },
		{ label: "URL", key: "url" },
		{ label: "User IP", key: "userIP" },
		{ label: "Referrer", key: "referrer" },
		{ label: "Client Type", key: "clientType" },
		{ label: "Client Name", key: "clientName" },
		{ label: "Device Type", key: "devicetype" },
		{ label: "Device Brand", key: "deviceBrand" },
		{ label: "Device Model", key: "deviceModel" },
		{ label: "OS Name", key: "osName" },
		{ label: "OS Version", key: "osVersion" },
		{ label: "User Agent", key: "userAgent" },
		{ label: "X-Forward", key: "x-forwarded-for" },
		{ label: "Register IP", key: "register_ip" },
		{ label: "Socket IP", key: "socket_ip" },
		{ label: "Remote Address", key: "remote_addresss" },
	];
	ddd = loggerData;

	const resetCallbackFun = () => {
		dispatch(getExternalLogger());
		setDialogVisible(false);
	};

	const handleEventReset = () => {
		dispatch(resetExternalEvents(resetCallbackFun));
	};

	return (
		<>
			<Card style={{ width: "100%" }}>
				<Card.Body>
					<Table columns={columns} pageSize={10} sizePerPageList={sizePerPageList} isSortable={false} pagination={true} isSearchable={true} customTableClassName={"LoggerTable"} searchBoxClass={"flex items-center gap-4"} />
				</Card.Body>
			</Card>
			{dialogVisible && <ResetConfirmModal title={"Reset Events"} message={"Are you sure you want to reset events?"} visible={dialogVisible} setVisible={setDialogVisible} onConfirmation={handleEventReset} loading={resetEventLoader} />}
		</>
	);
};

export default ExternalLogger;
