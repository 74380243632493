import {
    GET_EXTERNAL_LOGGER_JSONONLY_REQUEST,
    GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS,
    GET_EXTERNAL_LOGGER_JSONONLY_FAILED
} from '../types';

const initialState = {
    loggerJsonLoader:false, //JSON logger YD-63,
    loggerJsonData:[], //JSON logger YD-63,
}

const loggerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXTERNAL_LOGGER_JSONONLY_REQUEST:
            return { ...state, loggerJsonLoader:true }
        case GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS:
            return { ...state, loggerJsonLoader:false, loggerJsonData:action.payload }
        case GET_EXTERNAL_LOGGER_JSONONLY_FAILED:
            return { ...state, loggerJsonLoader:false, loggerJsonData:[] } 
        default:
            return state;
    }
}
export default loggerReducer;