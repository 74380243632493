import { GET_EXTERNAL_LOGGER_JSONONLY_REQUEST,GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS,GET_EXTERNAL_LOGGER_JSONONLY_FAILED } from "../types"; 
import axiosConfigXForwardIP from "./../axiosConfigXForwardIP";

export const getExternalLoggerJSONOnly = () => (dispatch) => {
	dispatch({ type: GET_EXTERNAL_LOGGER_JSONONLY_REQUEST });
	axiosConfigXForwardIP
		.get("https://events.myweb3id.io/reverse_events")
		.then((response) => {
			let oldData = response.data; 
			let latestData = oldData.filter(
				(obj) => Object.keys(obj).length > 1
			);
			let newFormattedData = [];
			latestData.forEach((data) => {
				let tempObject = {};
				tempObject["timestamp"] = data.timestamp;
				tempObject["requestData"] = JSON.stringify(data);
				newFormattedData.push(tempObject); 
			})
			console.log(`369--->new`,newFormattedData);
			return dispatch({
				type: GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS,
				payload: newFormattedData,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_EXTERNAL_LOGGER_JSONONLY_FAILED,
				payload: error.data,
			});
		});
};