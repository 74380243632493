import { MdDelete } from "react-icons/md";
import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ADD_NEW_CAMPAIGN, REFRESH_CAMPAIGN } from "../../types";
import Table from "../../components/Pagination/Table";
import { addNewCampaign, fetchCampaigns, deleteCampaign } from "../../services/campaign.service";

const MiniPlatformC = () => {
	const cancelBtn = React.useRef();
	const dispatch = useDispatch();
	const { campaigns } = useSelector((state) => state.campaignReducer);
	const [campaignData, setCampaignData] = useState({
		userId: "",
		adUrl: "",
	});
	const [campaignErrorData, setCampaignErrorData] = useState({
		userId: "",
		adUrl: "",
	});
	useEffect(() => {
		document.title = `Mini Platform C`;

		fetchCampaign();

		// Re render the component every 15 seconds
		const intervalId = setInterval(() => {
			// dispatch({ type: REFRESH_CAMPAIGN });

			fetchCampaign();
		}, 15000);
		return () => clearInterval(intervalId);
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchCampaign = async () => {
		// Check if client id exists in the local storage
		let clientId = localStorage.getItem("clientId");

		if (!clientId) {
			// create random client id if not exists
			const randomClientId = Math.random().toString(36).substring(2, 15);
			localStorage.setItem("clientId", randomClientId);
			clientId = randomClientId;
		}

		// Fetch campaigns from the API
		const campaignRes = await fetchCampaigns(clientId);
	
		if (campaignRes.error) {
			console.error("Error fetching campaigns:", campaignRes.error);
			return;
		}

		const campaignData = campaignRes.campaigns;
		dispatch({ type: "SET_CAMPAIGNS", payload: campaignData });
	}
		

	const saveCampaign = () => {
		const urlRegex = /^(https?:\/\/)[\w.-]+(\.[a-z]{2,10})(\/[^\s]*)?$/i;

		if (campaignData.userId === "") {
			setCampaignErrorData({
				userId: "User ID is required",
				adUrl: "",
			});
			return;
		}

		if (campaignData.adUrl === "") {
			setCampaignErrorData({
				userId: "",
				adUrl: "AD URL is required",
			});
			return;
		}

		if (urlRegex.test(campaignData.adUrl) === false) {
			setCampaignErrorData({
				userId: "",
				adUrl: "AD URL is not valid",
			});
			return;
		}

		// Check if user Id exists in the campaigns array
		const existingCampaign = campaigns.find(
			(campaign) => campaign.userId === campaignData.userId
		);

		if (existingCampaign) {
			// If user Id exists, update the existing campaign
			setCampaignErrorData({
				userId: "User ID already exists in active campaign, please input a different one",
				adUrl: "",
			});
			return;
		} else {
			// If user Id doesn't exist, add a new campaign
			const newCampaign = {
				clientId: localStorage.getItem("clientId"),
				userId: campaignData.userId,
				adUrl: campaignData.adUrl,
				time: new Date().toLocaleString(),
			};

			(async () => {
				const addNewCampaignRes = await addNewCampaign(newCampaign);
				console.log(addNewCampaignRes, "ADD NEW CAMPAIGN RES");
				if (addNewCampaignRes.error) {
					console.error("Error adding new campaign:", addNewCampaignRes.error);
					if (addNewCampaignRes.error === "DUPLICATE_USER_ID") {
						alert("User id already exists, please input a different one");
						return;
					}
					alert(addNewCampaign.error);
					return;
				}

				if (addNewCampaignRes.campaigns) {
					dispatch({ type: ADD_NEW_CAMPAIGN, payload: addNewCampaignRes.campaigns });
				}
			})()

			setCampaignData({
				userId: "",
				adUrl: "",
			});
			setCampaignErrorData({
				userId: "",
				adUrl: "",
			});
			cancelBtn.current.click();
		}
	};

	const deletecampaign = async (row) => {
		const deleteCampaignRes = await deleteCampaign(row.original.userId);
		if (deleteCampaignRes) {
			dispatch({ type: "DELETE_CAMPAIGN", payload: row.original.userId });
		}
	}

	const handleChange = (e) => {
		setCampaignData({
			...campaignData,
			[e.target.name]: e.target.value,
		});
	};

	const columns = [
		{
			Header: "User ID",
			accessor: "userId",
			sort: false,
			width: 50,
		},
		{
			Header: "Ad URL",
			accessor: "adUrl",
			sort: false,
		},
		{
			Header: "Creation Date/Time",
			accessor: "time",
			sort: "desc",
		},
		{
			Header: "Action",
			accessor: "action",
			sort: false,
			width: 50,
			Cell: ({ row }) => (
				<Dialog.Root>
					<Dialog.Trigger asChild>
						<button
							type="button"
							className="relative z-10 text-red-500 text-xl"
						>
							<MdDelete />
						</button>
					</Dialog.Trigger>
					<Dialog.Portal>
						<Dialog.Overlay className="DialogOverlay" />
						<Dialog.Content className="DialogContent rounded-md shadow-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-[90vw] max-w-[450px] max-h-[85vh] p-6 focus:outline-none [animation:contentShow_150ms_cubic-bezier(0.16,1,0.3,1)]">
							<h2 className="font-semibold text-black text-lg mb-[15px]">
								Are you sure you want to delete this campaign?
							</h2>
							<div className="flex gap-2 justify-center">
								<button
									type="button"
									className="focus:outline-none relative z-10 min-w-[100px] text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-100 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-800"
									onClick={() => deletecampaign(row)}
								>
									Yes
								</button>
								<Dialog.Close asChild>
									<button
										ref={cancelBtn}
										type="button"
										className="focus:outline-none relative z-10 min-w-[100px] text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-100 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800"
									>
										CANCEL
									</button>
								</Dialog.Close>
							</div>
						</Dialog.Content>
					</Dialog.Portal>
				</Dialog.Root>
			),
		},
	];

	return (
		<>
			<div className="px-[12px] py-4">
				<div className="relative bg-[white] bg-clip-border border border-solid rounded-md shadow-md min-h-screen">
					<div className="px-[15px] pt-5 flex justify-end">
						<Dialog.Root>
							<Dialog.Trigger asChild>
								<button
									type="button"
									className="focus:outline-none relative z-10 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 font-medium rounded-lg text-sm px-4 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
								>
									Create Campaign
								</button>
							</Dialog.Trigger>
							<Dialog.Portal>
								<Dialog.Overlay className="DialogOverlay" />
								<Dialog.Content className="DialogContent rounded-md shadow-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-[90vw] max-w-[450px] max-h-[85vh] p-6 focus:outline-none [animation:contentShow_150ms_cubic-bezier(0.16,1,0.3,1)]">
									<h2 className="font-semibold text-black text-lg mb-[15px]">
										Create New Campaign
									</h2>
									<div className="flex flex-col gap-4">
										<div>
											<h3 className="font-semibold mb-1">
												User ID
											</h3>
											<input
												className="w-full border-2 border-gray-300 rounded-md p-2"
												placeholder="User ID"
												name="userId"
												value={campaignData.userId}
												onChange={handleChange}
											/>
											{campaignErrorData.userId && (
												<span className="text-red-600 text-sm">
													{campaignErrorData.userId}
												</span>
											)}
										</div>
										<div>
											<h3 className="font-semibold mb-1">
												AD URL
											</h3>
											<input
												className="w-full border-2 border-gray-300 rounded-md p-2"
												placeholder="AD URL"
												name="adUrl"
												value={campaignData.adUrl}
												onChange={handleChange}
											/>
											{campaignErrorData.adUrl && (
												<span className="text-red-600 text-sm">
													{campaignErrorData.adUrl}
												</span>
											)}
										</div>
										<div className="flex gap-2">
											<button
												type="button"
												className="focus:outline-none relative z-10 min-w-[100px] text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-100 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-800"
												onClick={saveCampaign}
											>
												SAVE
											</button>
											<Dialog.Close asChild>
												<button
													ref={cancelBtn}
													type="button"
													className="focus:outline-none relative z-10 min-w-[100px] text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-100 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800"
												>
													CANCEL
												</button>
											</Dialog.Close>
										</div>
									</div>
								</Dialog.Content>
							</Dialog.Portal>
						</Dialog.Root>
					</div>

					<div className="px-[15px]">
						<Table
							columns={columns}
							data={campaigns}
							pageSize={10}
							isSortable={true}
							pagination={true}
							isSearchable={true}
							customTableClassName={"LoggerTable miniPlatformTable w-full"}
							searchBoxClass={"globalsearchbox sm:!-mt-[50px]"}
							initialState={{
								sortBy: [
									{
										id: "time",
										desc: true,
									},
								],
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default MiniPlatformC;
