import React, { useEffect, useState } from "react";
import { isIPv6 } from "is-ip";
import Menubar from "../../components/Menubar/Menubar";
import {
  IpLookupAction,
  UpdateDetailsIpInfo,
} from "../../actions/ipLookupAction";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";

//CSS
import "./IpLookUp.css";

//images
import AdPlacement from "../../components/AdPlacement/AdPlacement";
import GoogleMaps from "../Home/GoogleMaps";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-scroll";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IpLookUp = () => {
  const [ip, setIp] = useState("");
  const [Error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const { ipLookupError, ipLookupData, IPVersion6 } = useSelector(
    (state) => state.ipLookupReducer
  );
  const [showDetails, setShowDetails] = useState(false);

  const handleSearchButton = () => {
    if (!ip) {
      setShowError(true);
      setError("Please enter a valid IP address...!");
    } else {
      setShowDetails(true);
      dispatch(UpdateDetailsIpInfo(ip));
    }
  };

  useEffect(() => {
    if (ipLookupError) {
      setError(ipLookupError);
    }
    setShowError(true);
  }, [ipLookupError]);
  return (
    <div>
      <Menubar />
      <section className="p-3 flex flex-col">
        <div className="flex flex-col gap-3 items-center max-w-5xl w-full mx-auto">
          <img src="/images/ip.png" alt="ip" className="w-40 mt-10" />
          <div className="mt-30 py-4 uppercase text-lg font-semibold text-[#0A1A44]">
            Look up IP Address Location
          </div>
          <p className="max-w-md text-[#4A5571]">
            By using our IP Lookup tool, you can determine the location of an
            Internet user, be it in a specific part of the country or in the
            world, by identifying their IPv4 or IPv6 address.
          </p>
          <div
            className="flex flex-row gap-3 rounded-lg bg-[#C2EFF3] px-3 py-2 my-4"
            id="input1"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchButton();
              }}
            >
              <input
                type="text"
                className="bg-transparent border-none outline-none"
                placeholder="Search"
                value={ip}
                onChange={(e) => {
                  setIp(e.target.value);
                  setShowError(false);
                }}
              />
            </form>
            <Link to="input1" smooth={true} spy={true} duration={0} delay={0}>
              <img
                src="/icons/search.svg"
                alt="icon"
                className="cursor-pointer"
                onClick={() => handleSearchButton()}
              />
            </Link>
          </div>
          {showError ? (
            <h4 className="text-center text-red-500">{Error}</h4>
          ) : (
            ""
          )}
          <AdPlacement />
        </div>
        <div className="py-3">
          {showDetails && (
            <IpDetails
              ipv6={
                (ipLookupData.IPv6 && ipLookupData.IPv6) ||
                (isIPv6(IPVersion6) && IPVersion6 && IPVersion6) ||
                "Not Detected"
              }
            />
          )}
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              How much information does an IP lookup tool retrieve?
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-1.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              It is important to note that an IP locator tool cannot provide you
              with personally identifiable information about a user on the
              Internet. However, ISPs can still hunt down the owner of an IP
              address and trace it if they need to. This means it is crucial to
              be aware of where your data and online activities are being sent
              so that you can protect your privacy.
            </div>
          </div>
          <img
            src="/images/iplookup/ip-lookup-page-image-1.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/iplookup/ip-lookup-page-image-2.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              Benefits of an IP lookup tool
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-2.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Overall, IP lookup tools can be a helpful way to gain insight into
              your online activity and the location of where your data is being
              sent. However, it is essential to remember that ISPs have the
              power to trace IP addresses if necessary. Therefore, monitoring
              your Network and protecting your privacy should always be a top
              priority.
              <br />
              <br />
              By taking the time to understand how IP addresses work and what
              information can be revealed about you through an IP lookup tool,
              you can ensure that your data is secure and not vulnerable to
              potential risks or threats. Knowing your public IP address can
              also help you troubleshoot network issues quickly, allowing for a
              smoother online experience. <br />
              <br />
              The IP Location Lookup tool is an easy and efficient way to track
              information about a particular IP address quickly. With the
              variety of Geo IP databases available, you can be sure that your
              results are accurate and up-to-date. Start using it today to gain
              insight into where your data is being sent.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              What is a Reverse IP lookup
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-3.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Most people today use reverse IP lookups for various purposes.
              Whether you are trying to verify a customer's identity or scout
              out a potential business partner, reverse IP lookup techniques can
              provide invaluable insights into the details of an online actor.
              With a reverse IP lookup, you can enter an IP address and learn
              about its owner, including its internet service provider (ISP),
              Hostname, domain, and geographical location. Beyond simply
              providing links between an individual's online activities and
              their physical residences, reverse IP lookup services may also
              offer useful information such as the latitude and longitude
              coordinates of the user, as well as country code, postal code,
              state, and region related to the search query. It's important to
              note that your ISP will usually handle setting up and maintaining
              reverse DNS records associated with your IP address as part of its
              service package.
            </div>
          </div>
          <img
            src="/images/iplookup/ip-lookup-page-image-3.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/iplookup/ip-lookup-page-image-4.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              What are iPv6 and iPv4 Addresses?
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-4.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              As the number of users and connected devices worldwide has
              increased, IP addresses have also evolved. IP addresses uniquely
              identify a device on a given network, and the IPv4 address is one
              of the most commonly used types of IP address. It uses a 32-bit
              system to generate 4.3 billion different combinations – but with a
              world population of nearly 8 billion, this older version of IP
              addressing can no longer cope with the demand. As a result, an
              improved version known as iPv6 was developed from the ground up to
              provide 128-bit hexadecimal addresses, which generates far more
              unique numbers and supports enhanced security features.
              Effectively, IPv6 allows for better scaling, enabling many more IP
              addresses than were possible with IPv4.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              How to find the IP address on an iPhone or Android?
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-5.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              IP addresses provide a critical link between digital devices and
              the Internet. IP stands for Internet Protocol, and IP addresses
              are essential in connecting your devices to the wide web. When
              checking an IP address, it is essential to remember that there are
              two types: public IPs, which allow a device to be located on
              networks outside of your own, and private IPs, which allow for
              communication within internal networks. Checking the IP address of
              any device, including your smartphone, can help you identify it
              both at home and on the global Internet. In essence, IP addresses
              allow us to take full advantage of today's interconnected world.
            </div>
          </div>
          <img
            src="/images/iplookup/ip-lookup-page-image-5.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              iPhones
            </div>
            <div>
              Want to find out your iPhone's local IP address? It's easy:
              <br />
              <br />
              <ol>
                <li>
                  1. Head into Settings, select WiFi and connect to your home
                  network.
                </li>
                <li>2. Tap on the name of that connection for more details.</li>
                <li>3. Now look for an IPv4 Address header</li>
                <li>4. Here, you can view your device's current IP.</li>
              </ol>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              Android Phones
            </div>
            <div>
              Depending on the type of Android device, the location of
              information and menu names may be slightly different, but this
              will give you a general idea:
              <br />
              <br />
              <ol>
                <li>
                  {
                    "1. Start by accessing Settings and then scroll down to select Network & internet > WiFi."
                  }
                </li>
                <li>
                  2. Connect if not already connected to your specific Network
                  in that same section before advancing further.
                </li>
                <li>
                  3. Next step would be tapping on its name, which expands
                  another area called Advanced.
                </li>
                <li>
                  4. Under there lies all vital info, such as Network Details,
                  where one can spot their own personal IP address, including
                  any IPv6 addresses listed at the bottom (if available).
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col items-center">
            <div className="uppercase text-3xl font-semibold">
              Finding the Public IP of your phone
            </div>

            <div className="max-w-lg mt-6">
              Uncovering your private IP address on a local network is only the
              first step - now, let's take it one step further and uncover its
              global identity. With just a few taps on your phone or computer
              mouse clicks, you can easily find out what this public IP address
              is that any device worldwide will be able to identify! So, no
              matter which carrier you're using, mapping out where your online
              presence lies in the universe has always been more complex.
              <br />
              <br />
              Accessing a public IP checker website from your Android Chrome
              browser or iPhone Safari can find helpful information such as the
              geographical area, ISP name, and more. No need to look up
              someone's exact address - an IP trace will narrow it down quite
              accurately to their city. Get access to all this info in just a
              few taps on your smartphone's screen.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/iplookup/ip-lookup-page-image-8.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              Is it illegal to look up an IP address?
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-8.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Checking someone else's IP address is a harmless but powerful
              security tool. Each device connected to the Internet has its
              unique IP address, and an IP lookup tool can easily display it
              within seconds. This promotes accountability on the Internet, as
              people have the knowledge to look up IP addresses and track down
              suspicious activity. While checking someone's IP address does not
              reveal personal information like their name or email address, it
              provides enough data for people to take necessary steps in
              regulating online behavior and investigating perpetrators of
              cybercrimes.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              Are IP Lookup, IP Checker, and IP Locator different tools?
            </div>
            <img
              src="/images/iplookup/ip-lookup-page-image-9.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              IP Lookup, IP Checker, IP Locator - whatever the name may be, it's
              still the same lookup tool. An IP lookup is an essential service
              that can tell you information about your public IP address, such
              as what country or city you are connecting from and the IP address
              owner or Internet Service Provider (ISP). In addition, a good IP
              lookup tool enables you to perform a reverse IP lookup to check
              what websites share your IP address. All in all, it's a helpful
              feature for many different circumstances.
            </div>
          </div>
          <img
            src="/images/iplookup/ip-lookup-page-image-9.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

const IpDetails = ({ ips, ipv6 = null }) => {
  const dispatch = useDispatch();
  const [isIPv4Copied, setIPv4Copied] = useState(false);
  const [isIPv6Copied, setIPv6Copied] = useState(false);
  const { ipLookupLoader, ipLookupData, findIpDetail } = useSelector(
    (state) => state.ipLookupReducer
  );

  useEffect(() => {
    if (findIpDetail) {
      dispatch(IpLookupAction(findIpDetail));
    } else if (ips) {
      dispatch(IpLookupAction(ips));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findIpDetail, ips]);

  function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return degrees + "° " + minutes + "′ " + seconds + "″ ";
  }

  function latitudeConvertDMS(lat) {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? "N" : "S";
    return latitude + " " + latitudeCardinal;
  }
  function longitudeConvertDMS(lng) {
    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? "E" : "W";
    return longitude + " " + longitudeCardinal;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv4Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv4Copied]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv6Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv6Copied]);

  if (ipLookupLoader) {
    return (
      <div className="loading__icon">
        <ReactLoading
          type={"spin"}
          color={"#333"}
          height={25}
          width={25}
          style={{
            textAlign: "center",
            margin: "0 auto",
            width: "30px",
            height: "30px",
          }}
        />
      </div>
    );
  }
  return (
    <div className="my-10 grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl mx-auto">
      <div className="col-span-1 md:col-span-2 rounded-3xl overflow-hidden bg-[#9cc7cd]">
        <div className="p-3 px-8 uppercase text-white bg-[#0A1A44] flex flex-row items-center gap-3">
          IP Details For IPv4: {findIpDetail || ips}
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip
                id={`tooltip-top`}
                style={{ width: "150px", textAlign: "center" }}
              >
                {isIPv4Copied ? "Copied!" : "Copy to clipboard"}
              </Tooltip>
            }
          >
            <img
              onClick={() => {
                navigator.clipboard.writeText(findIpDetail || ips);
                setIPv4Copied(true);
              }}
              src="/icons/copy.svg"
              alt="icon"
              className="h-4 cursor-pointer"
            />
          </OverlayTrigger>
          {ipv6 && (
            <>
              {"IPv6: " + ipv6}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip
                    id={`tooltip-top`}
                    style={{ width: "150px", textAlign: "center" }}
                  >
                    {isIPv6Copied ? "Copied!" : "Copy to clipboard"}
                  </Tooltip>
                }
              >
                <img
                  onClick={() => {
                    navigator.clipboard.writeText(ipv6);
                    setIPv6Copied(true);
                  }}
                  src="/icons/copy.svg"
                  alt="icon"
                  className="h-4 cursor-pointer"
                />
              </OverlayTrigger>
            </>
          )}
        </div>

        {Object.keys(ipLookupData).length <= 1 ? (
          <div className="no-data">
            <p> No data found for {findIpDetail || ips} address </p>
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-3 text-sm px-8">
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">Decimal: </span>
              {ipLookupData?.decimal}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">Hostname: </span>
              {ipLookupData?.hostname}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">ASN: </span>
              {ipLookupData?.ASN}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">ISP: </span>
              {ipLookupData?.ISP}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">Services: </span>
              None detected
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">Assignment: </span>
              Likely static IP
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">Country: </span>
              {ipLookupData?.Country}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">State/Region: </span>
              {ipLookupData?.State}
            </p>
            <p className="text-[#4A5571]">
              <span className="font-semibold text-black">City: </span>
              {ipLookupData?.City}
            </p>
          </div>
        )}
      </div>
      <div className="rounded-3xl overflow-hidden bg-[#9cc7cd] flex flex-col gap-4 text-sm p-6">
        <div className="rounded-2xl overflow-hidden h-60">
          {ipLookupData?.location?.latitude &&
            ipLookupData?.location?.longitude && (
              <GoogleMaps
                lat={ipLookupData?.location?.latitude}
                lng={ipLookupData?.location?.longitude}
              />
            )}
        </div>
        <p className="text-[#4A5571]">
          <span className="font-semibold text-black">Latitude: </span>
          {ipLookupData?.location?.latitude +
            " (" +
            latitudeConvertDMS(ipLookupData?.location?.latitude) +
            ")"}
        </p>
        <p className="text-[#4A5571]">
          <span className="font-semibold text-black">Longitude: </span>
          {ipLookupData?.location?.longitude +
            " (" +
            longitudeConvertDMS(ipLookupData?.location?.longitude) +
            ")"}
        </p>
      </div>
    </div>
  );
};

export default IpLookUp;
