import React, { useCallback, useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import ReactLoading from "react-loading";
import { animateScroll } from "react-scroll";

import TestBannerAdsterra from "./TestBannerAdsterra";
import Footer from "../../components/Footer/Footer";
import { FloatingLabel, Form } from "react-bootstrap";

const TestBannerHome = () => {
	const [cookies, setCookie] = useCookies("userIdSearch");
	const [userId, setUserId] = useState(
		cookies?.userIdSearch ? cookies?.userIdSearch : ""
	);
	//const [protocolVal, setProtocolVal] = useState("https");
	const [hideIframe, setHideIframe] = useState(true);
	const [tagType, setTagType] = useState("Default");
	const [tagVal, setTagVal] = useState("");
	const [targetType, setTargetType] = useState("Default");
	const [refreshLoader, setRefreshLoader] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const scrollRef = useRef();

	const handleSearchButton = (e) => {
		e.preventDefault();
		setCookie("userIdSearch", userId, { path: "/" });
	};

	const onSubmit = (e) => {
		handleSearchButton(e);
		animateScroll.scrollTo(scrollRef?.current?.getBoundingClientRect().top);
	};

	const onChange = (event) => {
		setHideIframe(true);
		setUserId(event.target.value);
		setCookie("userIdSearch", event.target.value, { path: "/" });
	};

	const handleRefreshClick = () => {
		setHideIframe(false);
		setRefreshLoader(true);
		setTimeout(() => {
			setRefreshLoader(false);
			forceUpdate();
		}, 200);
	};

	useEffect(() => {
		document.title = `Test Banner`;
	}, []);

	return (
		<div>
			<section
				className="px-3 externalLoggerSection"
				style={{
					minHeight: "calc(100vh- 144px)",
					padding: "100px 0px 50px 0px",
				}}
			>
				<div
					className="flex flex-col p-6 max-w-5xl w-full mx-auto rounded-3xl mt-6"
					style={{ background: "#fff" }}
				>
					<form onSubmit={onSubmit}>
						<div className="grid grid-cols-1">
							<div className="flex flex-col text-center">
								<div
									className={`hidden`}
									style={{
										display: "flex",
										justifyContent: "space-around",
										marginBottom: "20px",
										flexWrap: "wrap",
										gap: "10px",
									}}
								>
									<div
										className="flex-row gap-3 rounded-lg bg-white px-3 py-2"
										style={{
											display: "inline-flex",
											border: "1px solid #ced4da",
										}}
									>
										<input
											type="text"
											className="bg-transparent border-none outline-none"
											placeholder="Enter User ID"
											value={userId}
											onChange={onChange}
										/>
										<img
											src="/icons/search.svg"
											alt="icon"
											className="cursor-pointer"
											onClick={onSubmit}
										/>
									</div>
									{/* <div className="protocolFields" style={{display:'flex', justifyContent:'space-around',alignItems:'center'}} >
										<span className="font-bold" style={{padding:'0px 10px'}}>Protocol:</span>
										<div>
											{['radio'].map((type)=>(
												<div key={`Protocol-inline-${type}`}>
													<Form.Check inline label="https" type={type} id={`Protocol-${type}-1`} checked={protocolVal==="https"? true:false } 
														onChange={(e)=>{
															setHideIframe(true);
															setProtocolVal(e.target.checked ? 'https':'');
														}} 
													 > 
													</Form.Check>
													<Form.Check inline label="http" type={type} id={`Protocol-${type}-2`} checked={protocolVal==='http'?true:false} 
														onChange={(e)=>{
															setHideIframe(true);
															setProtocolVal(e.target.checked ? 'http':'');
														}}
													>
													</Form.Check>
												</div>
											))}
										</div>
									</div> */}
								</div>
								<h1 className="font-bold mb-3">
									My USER ID Is: {userId}{" "}
								</h1>
							</div>
						</div>
						<div className="my-3 w-full border-b-2 border-[#ced4da]"></div>
						<div className="grid grid-cols-1 mb-3">
							<div className="flex flex-col text-center">
								<div
									className="protocolFields"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<span
										className="font-bold"
										style={{ padding: "0px 10px" }}
									>
										Tag:
									</span>
									<div>
										{["radio"].map((type) => (
											<div key={`Tag-inline-${type}`}>
												<Form.Check
													inline
													label="Default"
													type={type}
													id={`Tag-${type}-1`}
													checked={
														tagType === "Default"
															? true
															: false
													}
													onChange={(e) => {
														setTagType(
															e.target.checked
																? "Default"
																: ""
														);
														setTagVal("");
													}}
												></Form.Check>
												<Form.Check
													inline
													label="Custom"
													type={type}
													id={`Tag-${type}-2`}
													checked={
														tagType === "Custom"
															? true
															: false
													}
													onChange={(e) => {
														setTagType(
															e.target.checked
																? "Custom"
																: ""
														);
													}}
												></Form.Check>
											</div>
										))}
									</div>
								</div>
								{tagType === "Default" && (
									<div
										className="protocolFields my-3"
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<span
											className="font-bold"
											style={{ padding: "0px 10px" }}
										>
											Target:
										</span>
										<div>
											{["radio"].map((type) => (
												<div
													key={`target-inline-${type}`}
												>
													<Form.Check
														inline
														label="Default"
														type={type}
														id={`target-${type}-1`}
														checked={
															targetType ===
															"Default"
														}
														onChange={(e) => {
															setTargetType(
																e.target.checked
																	? "Default"
																	: ""
															);
														}}
													/>
													<Form.Check
														inline
														label="Mini Platform C"
														type={type}
														id={`target-${type}-2`}
														checked={
															targetType ===
															"miniPlatformC"
														}
														onChange={(e) => {
															setTargetType(
																e.target.checked
																	? "miniPlatformC"
																	: ""
															);
														}}
													/>
												</div>
											))}
										</div>
									</div>
								)}
								<FloatingLabel
									controlId="floatingTextarea2"
									label=""
									className="pt-2 text-center"
								>
									<Form.Control
										as="textarea"
										placeholder="Leave a comment here"
										disabled={
											tagType === "Default" ? true : false
										}
										style={{
											minHeight: "150px",
											maxWidth: "720px",
											display: "inline-block",
										}}
										onChange={(e) =>
											setTagVal(e.target.value)
										}
										value={
											tagType === "Custom" ? tagVal : ""
										}
									/>
								</FloatingLabel>
							</div>
						</div>
						<div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mx-auto">
							<button
								onClick={handleRefreshClick}
								className={`flex-1 bg-[#0A1A44] text-[#fff] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center ${
									userId ? "" : " cursor-not-allowed"
								}`}
								disabled={userId ? false : true}
							>
								Refresh Ad{" "}
								<img
									src="/icons/add.svg"
									alt="icon"
									className="h-6"
									style={{ color: "#fff" }}
								/>
							</button>
						</div>
					</form>
				</div>
				{!hideIframe && (
					<div
						className="flex flex-col justify-center items-center max-w-5xl w-full mx-auto "
						style={{ margin: "50px 0px " }}
					>
						{refreshLoader ? (
							<ReactLoading
								type={"spin"}
								color={"#333"}
								height={25}
								width={25}
								style={{
									textAlign: "center",
									margin: "0 auto",
									width: "30px",
									height: "30px",
								}}
							/>
						) : (
							<TestBannerAdsterra
								userId={userId}
								width={width}
								setWidth={setWidth}
								tagType={tagType}
								tagVal={tagVal}
								targetType={targetType}
								// protocolVal={protocolVal} tagType={tagType} tagVal={tagVal}
							/>
						)}
					</div>
				)}
			</section>
			<Footer isShowBgColor={true} />
		</div>
	);
};

export default TestBannerHome;
