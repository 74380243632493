import { useEffect, useRef, useState } from 'react';
import './style.css'
import { Button, Col, Form, Row } from 'react-bootstrap';
import Papa from "papaparse";
import * as XLSX from 'xlsx';

 
const UrlWhiteLister = () => {
    const MAX_FILE_LENGTH = 5 * 1024 * 1024; // 5MB limit (in bytes)
    const formRef = useRef(null);
    const [fileError, setFileError] = useState({
        ref: '',
        data: '',
    });
    const [file, setFile] = useState({
        ref: null,
        data: null,
    });
    const [searchType, setSearchType] = useState('domain');
    const [actions, setActions] = useState('whitelist');
    const [restultType, setResultType] = useState('From Data File');

    const [result, setResult] = useState({
        success: false,
        total_data_records: 0,
        total_ref_records: 0,
        matchedDataRows: [],
        unmatchedDataRows: [],
        matchedRefRows: [],
        unmatchedRefRows: [],
    });
    const [reportLoading, setReportLoading] = useState(false);

    useEffect(()=>{
        document.title='URL WhiteLister';
    },[]);

    // Convert the Excel file to CSV
    const convertXlsToCsv = async (xlsFile) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new Error('Failed to read file'));
            };
            reader.onload = () => {
                const data = new Uint8Array(reader.result);  // Convert file to array buffer
                const workbook = XLSX.read(data, { type: 'array' });  // Read the file as a workbook

                // Get the first sheet from the workbook
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                // Convert the sheet to JSON format
                const csvData = XLSX.utils.sheet_to_csv(worksheet, { header: 1 });  // Use header: 1 for arrays, header: 0 for JSON
                resolve(csvData);
            };
            reader.readAsArrayBuffer(xlsFile);  // Read the file as ArrayBuffer
        });
    }

    const parseCsvData = (csvDt) => {
        return new Promise((resolve, reject) => {
            // Parse the CSV data using PapaParse
            Papa.parse(csvDt, {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.trim(),
                transform: (value) => value.trim(),
                complete: function (results) {
                    const cleanedRows = results.data.map((row) => {
                        const cleanedRow = {};
                        for (const key in row) {
                            if (row.hasOwnProperty(key) && row[key] !== '') {
                                cleanedRow[key] = row[key].trim();
                            }
                        }
                        return cleanedRow;
                    });
                    resolve(cleanedRows);
                },
            });
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setReportLoading(true);

        let refCsv, dataCsv;
        // Parse the [Ref] csv file
        if (file.ref.extension === 'xlsx') {
            refCsv = await convertXlsToCsv(file.ref.file);
        } else if (file.ref.extension === 'csv') {
            refCsv = file.ref.file;
        }

        // Parse the [Data] csv file
        if (file.data.extension === 'xlsx') {
            dataCsv = await convertXlsToCsv(file.data.file);
        } else if (file.data.extension === 'csv') {
            dataCsv = file.data.file;
        } else if (file.data.extension === 'pcap') {
            // Handle pcap file
            alert('Pcap file is not supported yet.');
            return;
        }

        const parsedRefCsv = await parseCsvData(refCsv);
        const parsedDataCsv = await parseCsvData(dataCsv);

        const matchedRows = [];
        const unmatchedRows = [];
        const matchedRefRows = [];

        for (const dataRow of parsedDataCsv) {
            const matchingRefRow = parsedRefCsv.find(refRow => {
                const dataRowString = Object.values(dataRow).join(',');
                return dataRowString.includes(Object.values(refRow)[0]);
            });
            if (matchingRefRow) {
                matchedRows.push(dataRow);
                matchedRefRows.push(Object.values(matchingRefRow)[0]);
            } else {
                if (Object.entries(dataRow).length > 1) {
                    unmatchedRows.push(dataRow);
                }
            }
        }

        // Create an object to hold the count of each unique element
        const final_matchedRefRows = matchedRefRows.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;  // Increment the count or initialize it to 1
            return acc;
        }, {});

        const final_unmatchedRefRows = parsedRefCsv.filter(refRow => !Object.keys(final_matchedRefRows).includes(Object.values(refRow)[0]));

        setResult({
            success: true,
            total_data_records: parsedDataCsv.length,
            total_ref_records: parsedRefCsv.length,
            matchedDataRows: matchedRows,
            unmatchedDataRows: unmatchedRows,
            matchedRefRows: final_matchedRefRows,
            unmatchedRefRows: final_unmatchedRefRows,
        });

        setFile({
            ref: null,
            data: null,
        });
        formRef.current.reset();
        
        setReportLoading(false);
    }

    const handleFileChange = (e, type, allowedExtensions = ['csv','xlsx','xls']) => {
        const tempFile = e.target.files[0];
        const fileExtension = tempFile.name.split('.').pop().toLowerCase();

        if (tempFile.size > MAX_FILE_LENGTH) {
            setFileError({
                ...fileError,
                [type]: `File excceds max size of 5MB`
            });
            return;
        }

        if (!allowedExtensions.includes(fileExtension)) {
            setFileError({
                ...fileError,
                [type]: `Invalid file type. Please select a file with one of the allowed extensions: ${allowedExtensions.join(', ')}`
            });
            return;
        } else {
            setFileError({
                ...fileError,
                [type]: ''
            });
            setFile({
                ...file,
                [type]: {
                    extension: fileExtension,
                    file: e.target.files[0],
                }
            });
        }
    }

    const downloadResultCsv = () => {
        let csvContent;
        if (actions === 'whitelist' && restultType === "From Data File") {
            csvContent = Papa.unparse(result.matchedDataRows);
        }

        if (actions === 'whitelist' && restultType === "From Ref File") {
            const dataArray = Object.entries(result.matchedRefRows);
            csvContent = Papa.unparse(dataArray);
        }

        if (actions === 'blacklist' && restultType === "From Data File") {
            csvContent = Papa.unparse(result.unmatchedDataRows);
        }

        if (actions === 'blacklist' && restultType === "From Ref File") {
            csvContent = Papa.unparse(result.unmatchedRefRows);
        }

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'result.csv';
        link.click();
    }

    const resultText = () => {
        if (restultType === "From Data File") {
            return `Total ${result.total_data_records} Data Records Analyzed. ${result.matchedDataRows.length} Matched. ${result.unmatchedDataRows.length} Unmatched.`;
        }

        if (restultType === "From Ref File") {
            return `Total ${result.total_ref_records} Reference Records Analyzed. ${Object.entries(result.matchedRefRows).length} Matched. ${result.unmatchedRefRows.length} Unmatched.`;
        }
    }

    return (
        <div className='min-h-screen flex flex-col px-8	justify-left'>
            <h1 className='py-4 text-xl font-bold'>URL WhiteLister</h1>
            <Form noValidate onSubmit={handleSubmit} className='font-ibm mb-4' ref={formRef}>
                <div className='flex items-center gap-3 mb-3'>
                    <span className="font-bold inline-flex px-0" style={{padding:'0px 10px'}}>Search Type:</span>
                    <div key={`Tag-inline`}>
                        {['domain', 'app'].map((tag, index) => (
                            <Form.Check
                                className='mb-0 font-bold text-[#4b4b5a] capitalize'
                                key={`Tag-inline-${index}`}
                                inline
                                label={tag}
                                type={'radio'}
                                id={`Tag-inline-${index}`}
                                checked={searchType === tag}
                                onChange={(e) => {
                                    setSearchType(tag);
                                }}
                            >
                            </Form.Check>
                        ))}
                    </div>
                </div>
                <div className='flex items-center gap-3 mb-3'>
                    <span className="font-bold inline-flex px-0" style={{padding:'0px 10px'}}>Action:</span>
                    <div key={`Tag-inline`}>
                        {['whitelist', 'blacklist'].map((tag, index) => (
                            <Form.Check
                                className='mb-0 font-bold text-[#4b4b5a] capitalize'
                                key={`Tag-action-${index}`}
                                inline
                                label={tag}
                                type={'radio'}
                                id={`Tag-action-${index}`}
                                checked={actions === tag}
                                onChange={(e) => {
                                    setActions(tag);
                                }}
                            >
                            </Form.Check>
                        ))}
                    </div>
                </div>
                <div className='flex items-center gap-3 mb-3'>
                    <span className="font-bold inline-flex px-0" style={{padding:'0px 10px'}}>Results:</span>
                    <div key={`Tag-inline`}>
                        {['From Data File', 'From Ref File'].map((tag, index) => (
                            <Form.Check
                                className='mb-0 font-bold text-[#4b4b5a] capitalize'
                                key={`Tag-result-${index}`}
                                inline
                                label={tag}
                                type={'radio'}
                                id={`Tag-result-${index}`}
                                checked={restultType === tag}
                                onChange={(e) => {
                                    setResultType(tag);
                                }}
                            >
                            </Form.Check>
                        ))}
                    </div>
                </div>
                <Row className='mb-3'>
                    <Col xs={12}><Form.Label>Upload Reference File</Form.Label></Col>
                    <Col xs={5}>
                        <Form.Group controlId="formFile" className="mb-3"> 
                            <Form.Control type="file" accept=".xlsx,.csv" onChange={(e) => handleFileChange(e, 'ref')} />
                            {fileError.ref && <div className='w-full text-[0.875em] text-[#dc3545] mt-1'>{fileError.ref}</div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col xs={12}><Form.Label>Upload Data File</Form.Label></Col>
                    <Col xs={5}>
                        <Form.Group controlId="formFile" className="mb-3"> 
                            <Form.Control type="file" accept=".xlsx,.csv,.pcap" onChange={(e) => handleFileChange(e, 'data', ['csv','xlsx','xls', 'pcap'])} />
                            {fileError.data && <div className='w-full text-[0.875em] text-[#dc3545] mt-1'>{fileError.data}</div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" variant="primary" disabled={!file.ref || !file.data} style={{ cursor: file.ref && file.data ? 'pointer' : 'not-allowed' }}>Submit</Button>
            </Form>
            {
                reportLoading && (
                    <div role="status" className='text-black'>
                        {/* <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg> */}
                        Loading...
                    </div>
                )
            }
            {!reportLoading && result.success && (
                <Row>
                    <Col xs={12} className='font-bold'>Validation Result:</Col>
                    <Col xs={12} className='font-normal'>{resultText()}</Col>
                    <Col xs={12} className='font-normal py-2'>
                        <Button className='btn-green' variant="success" onClick={downloadResultCsv}>Download File</Button>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default UrlWhiteLister;