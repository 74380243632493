import React from "react";
import Menubar from "../../components/Menubar/Menubar";
import { Container, Row, Col } from "react-bootstrap";

//css
import "./SpeedTest.css";

//images
import speedtestwatch from "../../images/speed-test-watch.png";
import add from "../../images/add.jpg";
import blog1 from "../../images/speedtest-page-img-1.png";
import blog2 from "../../images/speedtest-page-img-2.png";
import blog3 from "../../images/speedtest-page-img-3.png";
import blog4 from "../../images/speedtest-page-img-4.png";
import blog5 from "../../images/speedtest-page-img-5.png";
import blog6 from "../../images/speedtest-page-img-6.jpg";
import blog7 from "../../images/speedtest-page-img-7-1.png";
import blog8 from "../../images/speedtest-page-img-7-2.png";


const SpeedTest = () => {
  const internetProviderDetail = JSON.parse(localStorage.getItem('MyIpDetail'))

  function DevScript() {
    const devCode =
    {
      sc_skin: '',
      sc_bgc: '0x123456 !important',
      sc_bc: '0x090A0A',
      sc_hc: '0xFFA333',
      sc_cc: '0x17B0E8',
      sc_w: 375,
      sc_h: 235,
      sc_userid: 108624868,
      sc_location: 'IN',
      version: 3
    }
    var sc_script = document.createElement('script');
    sc_script.setAttribute(
      'src',
      ('https:' === document.location.protocol ? 'https' : 'http') +
      '://www.speedcheckercdn.com/speedchecker.js'
    );
    document.getElementsByTagName('head')[0].appendChild(sc_script);
    ;
    return (
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: devCode }}
      />
    );
  }
  return (
    <div>
      <Menubar />
      <Container className="speedtestmeter-section">
        <Row>
          <Col lg={12} className="text-center">
            <div className="speedtest mb-4">
              <img src={speedtestwatch} alt="" />
            </div>
            <div className="text-center titlespeed">
              <h3>SPEED TEST</h3>
            </div>
            <div id="speedcheckerdiv"></div>
            {DevScript()}
            <div className="company-name">
              <h4 className="text-center">{internetProviderDetail?.internet_provider}</h4>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4 addbanner mt-4">
        <Row>
          <Col lg={12}>
            <img src={add} alt="" />
          </Col>
        </Row>
      </Container>
      {/* SEO BLOGS */}
      <div className="seo-section">
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog1} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>Internet Speed Test and its importance</h2>
                  <p>It's easy to take the internet connection for granted until it stops working. Everyday tasks like staying in touch with friends, family, and colleagues become significantly more difficult without access to the Internet.</p>
                  <p>The COVID-19 pandemic has only intensified our reliance on dependable Internet connections, as many of us now use digital platforms to keep in contact or stream content that requires higher bandwidths.</p>
                  <p>With Internet speeds expected faster than ever before, you should consider getting a high-speed Internet service provider committed to providing fast and reliable WIFI to keep up with today's quick-paced world.</p>
                  <p>Therefore, to ensure your ISP keeps its commitment, take advantage of Internet speed tests available online to check the service speeds you're receiving compared to what was promised by your ISP. By remaining current on Internet speeds, you can rest assured that your home will maintain a strong Internet connection.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog2} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Why do you need high-speed Internet? </h2>
                  <p>High-speed Internet is essential for many activities, from streaming video to playing games online. With Internet speeds that lag behind the rest of the world, a simple Internet speed test can show how important it is to have fast Internet connections. Having high-speed Internet ensures these activities are much smoother and more enjoyable, leaving more time to focus on what's important. For example, Internet speed can make all the difference when you're competing in an online game tournament or trying to finish up a project remotely but still stay connected with what's going on in different parts of the world - high-speed Internet enables this sort of work and plays to happen quickly and reliably. Make sure your home Internet connection has enough bandwidth to keep up with your needs by running an Internet speed test regularly. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog3} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>What is an Internet Speed Test, and why perform this test?</h2>
                  <p>Internet speed tests are valuable tools for analyzing your Internet connection. These tests measure the quality of your Internet devices and connection speeds, typically testing multiple aspects like download speed, upload speed, and ping. </p>
                  <p>Internet speed tests are used for various reasons; however, the most important reason is to ensure that you're getting what you pay from your Internet Service Provider (ISP). Other reasons or daily scenarios include:</p>
                  <ul>
                    <li>Without an Internet speed test, you wouldn't be able to tell if you were being disproportionately throttled in any way. The use of Internet speed tests lets us know if our Internet is running at expected speeds and performance with transparency.</li>
                    <li>A high-speed Internet connection is a key to staying connected and up-to-date with all your digital necessities. It is essential for streaming services, gaming, and more, and it can take time to keep up with all the changes. </li>
                    <li>To determine if you need to upgrade the bandwidth of your Internet, you should test it for both download and upload speeds, as well as ping, to get an accurate assessment of whether or not your Internet is capable of keeping up with the current trends. </li>
                    <li>With the average Internet speed in the US steadily increasing from 5 Mbps in 2009 to 99.3 Mbps last year, testing your Internet speed regularly can save time and money and avoid any potential complications while using a device or receiver.</li>
                    <li>A speed test is helpful for understanding exactly how your internet connection works. You can use it to determine the capabilities of your current setup, explore options for faster speeds in the future, and figure out which package best fits you and your family's needs. </li>
                  </ul>
                  <p>Do you need to get the right internet speed with the price you are paying? Then, it's time to change Internet service providers! </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog4} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Performing speed test on LAN and WiFi</h2>
                  <p>Regarding network performance, conducting speed tests on LAN and WiFi present some marked differences. When running a speed test on LAN, the physical connection provides added stability and higher bandwidth than testing with a wireless signal. As such, most chain stores will show faster results than what one could expect in the home environment due to the dedicated Ethernet connection from their broadband provider. On the other hand, when performing a speed test on WiFi, there is more of a reliance on external factors such as distance from the router, interference from other devices, or physical obstructions, which can drastically reduce performance. Ultimately, it's important to understand that everyone's home network is unique and your results may vary.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog5} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>What factors can slow down your Internet?</h2>
                  <p>You must be aware of some of the causes to determine the source of your slow Internet or whether you even have the problem. </p>
                  <p>Although there are more extensive articles on the subject, if you need to start from scratch or have a complex issue, start by reading about each of the following: </p>
                  <p><b>Network Interference: </b>Is there anything that could interfere with the network, according to number one? Additional anomalous indicators? Problems can arise from specific radio frequencies. Your internet speed will increase if you take these gadgets out of use or switch them off. You have identified the offender.</p>
                  <p><b>Inadequate Equipment: </b>Have you checked whether your router or receivers can handle the speeds of your internet plan? While it often goes unnoticed by those with rented equipment, many homeowners still need help to ensure their devices' compatibility. Make sure that any USB receiver and modem/router are able to keep up with up-to-date gigabit internet capabilities. Otherwise, you could be experiencing a noticeable drop in speed!</p>
                  <p>Poor Equipment Placement: Don't assume that your internet connection is faulty if it's running slow - consider the positioning of your devices! To ensure top-notch connectivity, ensure each item is close to a router or directly linked via an Ethernet cable. That way, signals can be transmitted and received in good time.</p>
                  <p><b>Background Programs:  </b>By default, your computer can run many programs or apps that impact internet speed without you knowing. Check if these are running in the background and consider turning them off for improved online performance - depending on how often you use the web. </p>
                  <p><b>Too Many Users: </b>Despite having a good internet plan, your connection can become sluggish if too many gadgets share the same bandwidth. To speed things up again, consider disabling some of your equipment or investing in an upgrade for both the plan and hardware – you'll be surfing faster than ever.</p>
                  <p><b>ISP Throttling: </b>Keep an eye on your connection speeds - if they're slower than expected and there's no other explanation, it could be that your ISP is throttling you. This can happen when you cross over monthly data limits or just because the company feels like it. Make sure to investigate what might have caused this to ensure everything's fair. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog6} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Perform your speed test today</h2>
                  <p>Using internet speed test tools is a great way to ensure that your internet connection is performing efficiently and up to standard. It can provide valuable insight into your current network status, as well as enable you to diagnose any potential issues and take corrective action. This proactive approach can save you time and money in the long run by avoiding disruption to your online activities with efficient troubleshooting methods. In addition, Internet speed tests give you the necessary data required to improve your connectivity speeds and overall user experience. Utilizing this free resource is a small investment of time with invaluable productivity and Internet performance returns.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog7} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog8} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div >
  );
};

export default SpeedTest;
