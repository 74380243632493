
export const GET_IP_LOOKUP_REQUEST = "GET_IP_LOOKUP_REQUEST";
export const GET_IP_LOOKUP_SUCCESS = "GET_IP_LOOKUP_SUCCESS";
export const GET_IP_LOOKUP_FAIL = "GET_IP_LOOKUP_FAIL";

export const GET_OWN_IP_SUCCESS = "GET_OWN_IP_SUCCESS";
export const UPDATE_DETAILS_IP_INFO = "UPDATE_DETAILS_IP_INFO";

export const GET_AGENT_REQUEST = "GET_AGENT_REQUEST";
export const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS";
export const GET_AGENT_FAIL = "GET_AGENT_FAIL";

export const GET_USER_AGENT_LOOKUP_REQUEST = "GET_USER_AGENT_LOOKUP_REQUEST";
export const GET_USER_AGENT_LOOKUP_SUCCESS = "GET_USER_AGENT_LOOKUP_SUCCESS";
export const GET_USER_AGENT_LOOKUP_FAIL = "GET_USER_AGENT_LOOKUP_FAIL";

export const SAVE_MY_IP_DETAILS_REQUEST = "SAVE_MY_IP_DETAILS_REQUEST";
export const SAVE_MY_IP_DETAILS_SUCCESS = "SAVE_MY_IP_DETAILS_SUCCESS";
export const SAVE_MY_IP_DETAILS_FAIL = "SAVE_MY_IP_DETAILS_FAIL";

export const GET_IPV6_SUCCESS = "GET_IPV6_SUCCESS"
export const GET_IPV6_FAILED = "GET_IPV6_FAILED";

export const SET_PRECISE_LOCATION= 'SET_PRECISE_LOCATION'

export const GET_USER_FOUND = 'GET_USER_FOUND'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_NOT_FOUND = 'GET_USER_NOT_FOUND'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const SAVE_USER_IPINFO_REQUEST = "SAVE_USER_IPINFO_REQUEST";
export const SAVE_USER_IPINFO_SUCCESS = "SAVE_USER_IPINFO_SUCCESS";
export const SAVE_USER_IPINFO_FAILED = "SAVE_USER_IPINFO_FAILED";

export const GET_USER_IPINFO_REQUEST = "GET_USER_IPINFO_REQUEST";
export const GET_USER_IPINFO_SUCCESS = "GET_USER_IPINFO_SUCCESS";
export const GET_USER_IPINFO_FAILED = "GET_USER_IPINFO_FAILED";

export const SET_LOCATION_PERMISSION_STATUS = 'SET_LOCATION_PERMISSION_STATUS'


export const GET_SYSTEM_INFO_REQUEST = 'GET_SYSTEM_INFO_REQUEST'; 
export const GET_SYSTEM_INFO_SUCCESS = 'GET_SYSTEM_INFO_SUCCESS'; 
export const GET_SYSTEM_INFO_FAILED = 'GET_SYSTEM_INFO_FAILED'; 

export const GET_EXTERNAL_LOGGER_REQUEST = 'GET_EXTERNAL_LOGGER_REQUEST';
export const GET_EXTERNAL_LOGGER_SUCCESS = 'GET_EXTERNAL_LOGGER_SUCCESS';
export const GET_EXTERNAL_LOGGER_FAILED = 'GET_EXTERNAL_LOGGER_FAILED';


export const RESET_EVENTS_REQUEST = 'RESET_EVENTS_REQUEST';
export const RESET_EVENTS_SUCCESS = 'RESET_EVENTS_SUCCESS';
export const RESET_EVENTS_FAILED = 'RESET_EVENTS_FAILED';


export const GET_EXTERNAL_LOGGER_POST_REQUEST = 'GET_EXTERNAL_LOGGER_POST_REQUEST';
export const GET_EXTERNAL_LOGGER_POST_SUCCESS = 'GET_EXTERNAL_LOGGER_POST_SUCCESS';
export const GET_EXTERNAL_LOGGER_POST_FAILED = 'GET_EXTERNAL_LOGGER_POST_FAILED';


export const GET_EXTERNAL_LOGGER_JSONONLY_REQUEST = 'GET_EXTERNAL_LOGGER_JSONONLY_REQUEST';
export const GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS = 'GET_EXTERNAL_LOGGER_JSONONLY_SUCCESS';
export const GET_EXTERNAL_LOGGER_JSONONLY_FAILED = 'GET_EXTERNAL_LOGGER_JSONONLY_FAILED';

