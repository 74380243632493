import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import parse from 'html-react-parser';

function Iframe({ children,iframeWidth }) {
  const [ref, setRef] = useState();
  const container = ref?.contentDocument?.body;
  return (
    <iframe title="iframeTestBannerAdstr" ref={setRef} width={iframeWidth} height="auto" id='test-iframe-id'>
      {container && createPortal(children, container)}
    </iframe>
  );
}

function MyComponent({userId, width, tagVal, tagType}){
  let tagTypeInLower = tagType.toLowerCase();
  let isDefaultIframe = tagType && tagTypeInLower==='default' ? true : false;
  
  useEffect(() => { 
    if (isDefaultIframe) {
      const iframe = document.getElementById('test-iframe-id');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      // Create a script element
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';  
      scriptElement.text = `
      var scriptTag = document.createElement('script');
      scriptTag.src = 'https://noas.mtrtb.com/getAd?idfa=${userId}';
      document.body.appendChild(scriptTag)`;
      // Append the script element to the head of the iframe document
      iframeDocument.head.appendChild(scriptElement); 
    } else {
      const iframe = document.getElementById('test-iframe-id');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript'; 

      if(tagVal.includes('powerium')){
        scriptElement.text = `  
        var scriptTag = document.createElement('script');  
        scriptTag.src = 'https://noas.powerium.net/getAd?idfa=${userId}';  
        document.body.appendChild(scriptTag)`; 
      }else{
        scriptElement.text = `  
        var scriptTag = document.createElement('script');  
        scriptTag.src = 'https://noas.mtrtb.com/getAd?idfa=${userId}';  
        document.body.appendChild(scriptTag)`; 
      }
       
      iframeDocument.head.appendChild(scriptElement);

      const scriptElement2 = document.createElement('script');
      scriptElement2.type = 'text/javascript'; 

      scriptElement2.text = `  
      var scriptTag = document.createElement('script'); 
      scriptTag.src = '{{IMPRESSION_URL}}';       
      document.body.appendChild(scriptTag)`; 
      iframeDocument.head.appendChild(scriptElement2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isDefaultIframe]); 

  let scriptTestTemp; 
  if(isDefaultIframe){
    scriptTestTemp = `
      var scriptTag = document.createElement('script');
      scriptTag.src = 'https://noas.mtrtb.com/getAd?idfa=${userId}';
      document.body.appendChild(scriptTag);
    `;
  }

  function isHTMLString(input) {
    // Regular expression to match HTML tags
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  
    return htmlRegex.test(input);
  }

  let iframeWidthNew =  width - (width * 5) / 100;

  if (isDefaultIframe) {
    let scriptTest = scriptTestTemp.replace(new RegExp('{{USER_ID}}', 'g'), userId);

    return (
      <>
        <a href='https://alitems.co/g/1e8d11449458cd0c4aa216525dc3e8/' rel="noreferrer" target='_blank' className="flex justify-start items-center"   >
          <img src='https://cloud-images.s3.eu-west-1.amazonaws.com/MyDSP_Beeswax/Ali_rt_shop/468x60_1.png' alt="" style={{ width: `${iframeWidthNew}px`,border:'1px solid white' }} />
        </a>
        <script type="text/javascript">{scriptTest}</script>
      </>
    )
  } else {
      let tagTemp = tagVal;
      if(tagVal.includes('powerium')){
        tagTemp = tagTemp.replaceAll(`<script src='https://noas.powerium.net/getAd?idfa={{USER_ID}}&bundle={{APP_BUNDLE}}&exc={{INVENTORY_SOURCE}}&dom={{DOMAIN}}&sha1={{USER_ID_SHA1}}'>`, '')
      }else{ 
        tagTemp = tagTemp.replaceAll(`<script src='https://noas.mtrtb.com/getAd?idfa={{USER_ID}}&bundle={{APP_BUNDLE}}&exc={{INVENTORY_SOURCE}}&dom={{DOMAIN}}&sha1={{USER_ID_SHA1}}'></script>`, '')
      }

      tagTemp = tagTemp.replaceAll(`<script src='{{IMPRESSION_URL}}'></script>`, '');
    // tagTemp = tagTemp.replaceAll(`<a href='https://s.click.aliexpress.com/e/_DcjZmiP' target='_blank' onClick='clicker();'>`, `<a href='https://s.click.aliexpress.com/e/_DcjZmiP' target='_blank' >`)
    // tagTemp = tagTemp.replaceAll(`<script>`, `<html type='text/javascript'>`); 

      tagTemp = tagTemp.replaceAll(`<html>`, ``); 
      tagTemp = tagTemp.replaceAll(`</html>`, ``); 
      tagTemp = tagTemp.replaceAll(`<body>`, ``); 
      tagTemp = tagTemp.replaceAll(`</body>`, ``); 
      tagTemp = tagTemp.replaceAll(`<script>`, `<script type='text/javascript'>`);

    let finalTagVal = tagTemp.replaceAll(`{{USER_ID}}`, userId);
    
    //finalTagVal = `${finalTagVal} <script type="text/javascript">${customQATagScript}</h>`;
    if(isHTMLString(finalTagVal)){
      return parse(finalTagVal.replaceAll(`{USER_ID}`, userId)) ;
    }else{
      return finalTagVal;
    }
  }
} 

export default function TestBannerAdsterra({ userId, width, tagVal, tagType }){
    let iframeWidth = window.innerWidth >=780 ? 780 : window.innerWidth - (window.innerWidth * 20 ) / 100;

    return (
      <Iframe iframeWidth={iframeWidth} >
          <MyComponent userId={userId} width={iframeWidth} tagVal={tagVal} tagType={tagType} />
      </Iframe>
    )
}