import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Form,
  Container,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link } from "react-scroll";

//css
import "./Home.css";

//components
import Menubar from "../../components/Menubar/Menubar";
import IpDetails from "../IpDetails/IpDetails";
import IpHistoryTable from "../IpHistoryTable/IpHistoryTable";
import GoogleMaps from "./GoogleMaps";

//images
import arrowdown from "../../images/arrow-down.png";
import plus from "../../images/plus.png";
import clock from "../../images/clock.png";
import add from "../../images/add.jpg";
import search from "../../images/search.png";
import result from "../../images/result.png";
import blog1 from "../../images/home-page-img-1.png";
import blog2 from "../../images/home-page-img-2.png";
import blog3 from "../../images/home-page-img-3.png";
import blog4 from "../../images/home-page-img-4.png";
import blog5 from "../../images/home-page-img-5.png";
import blog6 from "../../images/home-page-img-6.png";
import blog7 from "../../images/home-page-img-7.png";
import blog8 from "../../images/home-page-img-8.png";
import blog9 from "../../images/home-page-img-9.png";
import blog10 from "../../images/home-page-img-10.png";

//actions
import {
  IpLookupAction,
  GetOwnIpAction,
  UpdateDetailsIpInfo,
  saveMyIpDetails,
  getIPversion6,
} from "../../actions/ipLookupAction";
import { isIPv6 } from "is-ip";

const Home = () => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const { ipLookupError, ipLookupData, ownIpAddress, IPVersion6 } = useSelector(
    (state) => state.ipLookupReducer
  );
  const [ip, setIP] = useState("");
  const [Error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showHistoryTable, setshowHistoryTable] = useState(false);
  const [cookies, setCookie] = useCookies(["ipHistory"]);
  const [isIPv4Copied, setIPv4Copied] = useState(false);
  const [isIPv6Copied, setIPv6Copied] = useState(false);
  const storeCookies = (ipAddress, time, location) => {
    if (!cookies?.ipHistory)
      setCookie("ipHistory", [{ ipAddress, time, location }], { path: "/" });
    else
      setCookie(
        "ipHistory",
        [{ ipAddress, time, location }, ...cookies?.ipHistory],
        { path: "/" }
      );
  };
  useEffect(() => {
    if (!Object.keys(ownIpAddress).length) {
      dispatch(GetOwnIpAction());
      dispatch(getIPversion6());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("MyIpDetail");
    if (!!!data) {
      dispatch(saveMyIpDetails(ownIpAddress.IPv4));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipLookupData]);

  useEffect(() => {
    if (Object.keys(ownIpAddress).length && !Object.keys(ipLookupData).length) {
      dispatch(
        IpLookupAction(
          (ownIpAddress.IPv4 && ownIpAddress.IPv4) ||
            (isIPv6(IPVersion6) && IPVersion6)
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownIpAddress]);

  useEffect(() => {
    if (ipLookupError) {
      setError(ipLookupError);
    }
    setShowError(true);
  }, [ipLookupError]);

  const submitHandler = () => {
    setShowDetails(true);
    dispatch(UpdateDetailsIpInfo(ownIpAddress.IPv4));
  };
  const handleSearchButton = (e) => {
    e.preventDefault();
    if (!ip) {
      setShowError(true);
      setError("Please enter a valid IP address...!");
    } else {
      setShowDetails(true);
      dispatch(UpdateDetailsIpInfo(ip));
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv4Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv4Copied]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIPv6Copied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isIPv6Copied]);

  return (
    <div>
      <Menubar />
      <Container className="">
        <Row className="justify-content-center ">
          <Col lg={4}>
            <Form className="d-flex justify-content-center search_design">
              <Form.Control
                type="search"
                placeholder="Enter IP Address"
                className="me-2"
                aria-label="Search"
                value={ip}
                onChange={(event) => {
                  setIP(event.target.value);
                  setShowError(false);
                }}
              />
              <Link
                activeClass="active"
                to="ipDetails"
                spy={true}
                smooth={true}
                duration={0}
                delay={0}
              >
                <Button
                  variant="outline-success"
                  type="submit"
                  onClick={(e) => handleSearchButton(e)}
                >
                  <img src={search} alt="" />
                </Button>
              </Link>
            </Form>
            {showError ? <h4 className="error-message">{Error}</h4> : ""}
          </Col>
        </Row>
      </Container>
      <Container className="mb-4">
        <Row>
          <Col lg={4}>
            <div className="all_main">
              <div className="address_title text-center mb-4">
                <strong> IP Address Results:</strong>
              </div>
              <div className="address_wrap">
                <div className="address_wrap_label">
                  <div className="address_label">ISP : </div>
                  <div className="address_text">{ipLookupData.ISP}</div>
                </div>
                <div className="address_wrap_label">
                  <div className="address_label">City : </div>
                  <div className="address_text">{ipLookupData.City}</div>
                </div>
                <div className="address_wrap_label">
                  <div className="address_label">Region : </div>
                  <div className="address_text">{ipLookupData.State}</div>
                </div>
                <div className="address_wrap_label">
                  <div className="address_label">Country : </div>
                  <div className="address_text">{ipLookupData.Country}</div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="all_main">
              <div className="address_title text-center mb-4">
                <strong> IP Address Results:</strong>
              </div>
              <div className="ip_address_wrap_full mb-4">
                <div className="ip_address_wrap">
                  <div className="ip_address_label">IPv4 : </div>
                  <div className="it-text">
                    <div className="ip_address_text">
                      {ipLookupData.IPv4 && !ipLookupData.IPv6
                        ? ipLookupData.IPv4
                        : ownIpAddress.IPv4 &&
                          !ipLookupData.IPv4 &&
                          !ipLookupData.IPv6
                        ? ownIpAddress.IPv4
                        : "Not Detected"}
                    </div>
                    {(ipLookupData.IPv4 || ownIpAddress.IPv4) && (
                      <div className="ip_address_icon">
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              style={{ width: "150px", textAlign: "center" }}
                            >
                              {isIPv4Copied ? "Copied!" : "Copy to clipboard"}
                            </Tooltip>
                          }
                        >
                          <img
                            src={result}
                            style={{ height: "25px" }}
                            alt=""
                            onClick={() => {
                              navigator.clipboard.writeText(
                                ipLookupData.IPv4
                                  ? ipLookupData.IPv4
                                  : ownIpAddress.IPv4
                              );
                              setIPv4Copied(true);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>

                  <div className="ip_address_text ip-text">
                    {ipLookupData.IPv4 && !ipLookupData.IPv6
                      ? ipLookupData.IPv4
                      : ownIpAddress.IPv4 &&
                        !ipLookupData.IPv4 &&
                        !ipLookupData.IPv6
                      ? ownIpAddress.IPv4
                      : "Not Detected"}
                  </div>
                  {(ipLookupData.IPv4 || ownIpAddress.IPv4) && (
                    <div className="ip_address_icon ip-icon">
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip
                            id={`tooltip-top`}
                            style={{ width: "150px", textAlign: "center" }}
                          >
                            {isIPv4Copied ? "Copied!" : "Copy to clipboard"}
                          </Tooltip>
                        }
                      >
                        <img
                          src={result}
                          style={{ height: "25px" }}
                          alt=""
                          onClick={() => {
                            navigator.clipboard.writeText(
                              ipLookupData.IPv4
                                ? ipLookupData.IPv4
                                : ownIpAddress.IPv4
                            );
                            setIPv4Copied(true);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
                <div className="ip_address_wrap">
                  <div className="ip_address_label">IPv6 : </div>
                  <div className="it-text">
                    <div className="ip_address_text">
                      {(ipLookupData.IPv6 && ipLookupData.IPv6) ||
                        (isIPv6(IPVersion6) && IPVersion6) ||
                        "Not Detected!"}
                    </div>
                    {(ipLookupData.IPv6 ||
                      (isIPv6(IPVersion6) && IPVersion6)) && (
                      <div className="ip_address_icon">
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              style={{ width: "150px", textAlign: "center" }}
                            >
                              {isIPv6Copied ? "Copied!" : "Copy to clipboard"}
                            </Tooltip>
                          }
                        >
                          <img
                            src={result}
                            style={{ height: "25px" }}
                            alt=""
                            onClick={() => {
                              navigator.clipboard.writeText(
                                (ipLookupData.IPv6 && ipLookupData.IPv6) ||
                                  (isIPv6(IPVersion6) &&
                                    IPVersion6 &&
                                    IPVersion6) ||
                                  "Not Detected!"
                              );
                              setIPv6Copied(true);
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                  <div className="ip_address_text ip-text">
                    {(ipLookupData.IPv6 && ipLookupData.IPv6) ||
                      (isIPv6(IPVersion6) && IPVersion6) ||
                      "Not Detected!"}
                  </div>
                  {(ipLookupData.IPv6 ||
                    (isIPv6(IPVersion6) && IPVersion6)) && (
                    <div className="ip_address_icon ip-icon">
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip
                            id={`tooltip-top`}
                            style={{ width: "150px", textAlign: "center" }}
                          >
                            {isIPv6Copied ? "Copied!" : "Copy to clipboard"}
                          </Tooltip>
                        }
                      >
                        <img
                          src={result}
                          style={{ height: "25px" }}
                          alt=""
                          onClick={() => {
                            navigator.clipboard.writeText(
                              (ipLookupData.IPv6 && ipLookupData.IPv6) ||
                                (isIPv6(IPVersion6) && IPVersion6) ||
                                "Not Detected!"
                            );
                            setIPv6Copied(true);
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="button_wrap text-center">
              <Button
                onClick={() =>
                  storeCookies(
                    {
                      IPv4: ip ? ip : ownIpAddress.IPv4 && ownIpAddress.IPv4,
                      IPv6:
                        (isIPv6(IPVersion6) && IPVersion6) || "Not Detected!",
                    },
                    +new Date(),
                    ownIpAddress.city +
                      "," +
                      ownIpAddress.state +
                      "," +
                      ipLookupData.Country && ipLookupData.Country
                  )
                }
                variant="primary"
                size="lg"
                className="mb-4 d-flex align-items-center justify-content-center blue-button"
                style={{ maxWidth: "198px" }}
              >
                Add IP to History
                <div>
                  <img src={plus} alt="" />{" "}
                </div>
              </Button>
              <Button
                onClick={() => setshowHistoryTable(true)}
                variant="primary"
                size="lg"
                className="mb-4 d-flex align-items-center justify-content-center grey-button"
              >
                Show My IP History{" "}
                <div>
                  <img src={clock} alt="" />{" "}
                </div>
              </Button>
            </div>
          </Col>
          <Col lg={4}>
            <div className="all_main">
              <div className="address_title text-center mb-4">
                <strong> Map Location:</strong>
              </div>
              <div className="mb-4 map-div">
                {ipLookupData?.location?.latitude &&
                  ipLookupData?.location?.longitude && (
                    <GoogleMaps
                      lat={ipLookupData?.location?.latitude}
                      lng={ipLookupData?.location?.longitude}
                    />
                  )}
              </div>
              <div className="location-accure text-center">
                <div className="location-not-accure">
                  Location not accurate?
                </div>
                <div className="update-location">Update My IP Location</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={12}
            className="text-center mx-auto"
            style={{ maxWidth: "600px" }}
          >
            {showHistoryTable ? <IpHistoryTable cookies={cookies} /> : ""}
          </Col>
        </Row>
      </Container>
      <Container className="mb-4">
        <Row>
          <Col lg={12}>
            <div className="ip_info text-center">
              <h4 className="ip_title">Your IP Info</h4>
              <Link
                activeClass="active"
                to="ipDetails"
                spy={true}
                smooth={true}
                duration={0}
                delay={0}
              >
                <div className="ip_icon" onClick={submitHandler}>
                  <img src={arrowdown} style={{ height: "60px" }} alt="" />
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4 addbanner mt-4">
        <Row>
          <Col lg={12}>
            <img src={add} alt="" />
          </Col>
        </Row>
      </Container>
      <div id="ipDetails">{showDetails && <IpDetails />}</div>
      {/* SEO BLOGS */}
      <div className="seo-section">
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog1} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>A Comprehensive Guide to IP in General</h2>
                  <p>
                    If you've been using the Internet for a while, chances are
                    you've heard of IPs or IP addresses. But what exactly are
                    they? How do they work, and why are they important? In this
                    comprehensive guide, we will explain everything you need to
                    know about IPs, from their definition and components to how
                    to check and change your IP address.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog2} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>What is an IP Address? </h2>
                  <p>
                    An IP address is a unique numerical identifier assigned to
                    each device connected to the Internet. Every time data is
                    sent between two devices on the Internet; the sender
                    includes its own IP address so that the recipient knows
                    where it came from.
                  </p>
                  <p>
                    For example, when you visit a website, your computer sends
                    out a request for information with its own IP address
                    attached. The server receives this request along with your
                    computer's IP address, processes the request, and then sends
                    back the requested information.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog3} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How Does an IP Address Work? </h2>
                  <p>
                    An IP address consists of four numbers separated by periods
                    (for example, 199.121.232.11). Each number represents 8 bits
                    of data and can range from 0-255. This means that there can
                    be up to 4 billion possible combinations of numbers which
                    allows for billions of computers across the world to have
                    unique identifiers when communicating online.
                  </p>
                  <p>
                    The other type of commonly used IP address is IPv6 addresses
                    which consist of 8 sets of 4 hexadecimal characters (for
                    example, ABCD: EF12:3456:7890). The total number of IPv6
                    combinations is much larger than IPv4, allowing for
                    virtually limitless possibilities for future expansion and
                    growth on the Internet..
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog4} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>How to Check & Change Your IP Address? </h2>
                  <p>
                    You can easily check what your current public-facing IP
                    address is by visiting websites such as ipify or
                    whatsmyipaddress, which will display your current internet
                    protocol address in just a few seconds. You can also use
                    these tools to check if your ISP has assigned you a dynamic
                    or static IP address which affects how often it changes over
                    time as well as how easy it would be for someone else to
                    trace back activity on your connection to you specifically.
                  </p>
                  <p>
                    Changing your IP address usually requires contacting your
                    ISP and setting up a different physical connection, but
                    there are also services such as VPNs that can allow you to
                    "hide" behind another IP by routing all traffic through
                    their servers instead, thus making it more difficult for
                    anyone attempting to trace any activity back to you
                    directly.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog5} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>The Different Types of IP Addresses</h2>
                  <p>
                    There are multiple types of IP addresses that are used on
                    the Internet, each with its own set of features and
                    functions. The most commonly used IP address is IPv4, which
                    has 32 bits and allows for up to 4 billion unique devices to
                    be addressed.
                  </p>
                  <p>
                    However, IPv6 has become increasingly popular over the last
                    decade, as it offers 128 bits of address space - enough room
                    for millions more unique addresses than IPv4. Additionally,
                    there are two types of private IP addresses - those assigned
                    by a network administrator (e.g., an internal intranet) and
                    those that are self-assigned via DHCP (dynamic host
                    configuration protocol).
                  </p>
                  <p>
                    Finally, public/shared IP addresses are used by internet
                    service providers and allow customers to share one single
                    address for accessing the web. Understanding what purpose
                    each type of address serves is essential in the making sure
                    your online presence remains secure and functional.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog6} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>
                    What to do if You Think Someone is Using Your IP Address
                    Without Permission
                  </h2>
                  <p>
                    If you think that someone is illegally using your IP
                    address, the best thing to do is report it to law
                    enforcement. It is important to have all the evidence you
                    can provide and a clear record of the unauthorized use of
                    your IP address.
                  </p>
                  <p>
                    Document all of the instances where you believe someone was
                    accessing your address in as much detail as possible, and
                    then contact either your local police department or a
                    cybercrime division if you need further assistance.
                  </p>
                  <p>
                    If an investigation is needed, it would be beneficial to
                    find other witnesses or evidence regarding the illegal usage
                    and provide them with relevant information about what
                    happened. The quicker you act on this situation, the more
                    chances of catching the perpetrator before they cause more
                    harm with your IP address.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog7} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How to Hide Your IP Address</h2>
                  <p>
                    With the ever-growing threats of cybercrime, it becomes more
                    important than ever to take extra steps to ensure digital
                    privacy and security. One way to protect yourself online is
                    to hide your IP address. An IP address is basically like
                    your personal digital fingerprint that identifies you on the
                    Internet.
                  </p>
                  <p>
                    Hiding your IP address can prevent someone from snooping
                    around on your activity, including monitoring which websites
                    you visit or what information you share. There are a few
                    simple methods for hiding an IP address. Using a VPN
                    (Virtual Private Network) service is a secure and reliable
                    way to browse the Internet anonymously, as it encrypts
                    internet traffic and masks the user's location by
                    redirecting them through multiple servers.
                  </p>
                  <p>
                    Proxies are another option, but their effectiveness varies
                    depending on the type of proxy used and the security
                    settings. Ultimately, taking extra precautions, such as
                    masking your IP, helps keep your private information safe in
                    today's digital age.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog8} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>
                    The Benefits of Using a VPN with an IP Address Changer
                  </h2>
                  <p>
                    Staying secure and anonymous online has become increasingly
                    important for both personal and business use. Using a
                    Virtual Private Network (VPN) with an IP address changer can
                    offer multiple benefits to any user. By encrypting data
                    across the web, a VPN provides a layer of security from
                    malicious players and increased privacy from ISPs,
                    marketers, and other third parties.
                  </p>
                  <p>
                    Additionally, by changing the IP address, users can access
                    geo-restricted content and bypass censorship restrictions
                    within certain countries. VPNs allow users to connect to
                    remote servers and experience the advantages of fast speeds,
                    flexible protocols, strong encryption algorithms, no logging
                    policies, and zero bandwidth caps or data limits. With so
                    many options available in such an ever-evolving landscape,
                    users can benefit greatly by utilizing a reputable VPN with
                    an IP address changer.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog9} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How to Choose the Right VPN for Your Needs</h2>
                  <p>
                    Choosing the right virtual private network (VPN) for your
                    needs can be overwhelming, with the number of providers and
                    features available to consider. However, it is important to
                    do your research before making a decision.
                  </p>
                  <p>
                    Start by assessing your needs: consider the type of
                    protection you require, the countries you need access to,
                    any specific requirements such as support for gaming or
                    torrenting, and whether there are any time constraints on
                    data limits or bandwidth.
                  </p>
                  <p>
                    Once you have identified your requirements, compare
                    different providers and read online reviews before deciding
                    which one best suits your needs. Do not be afraid to reach
                    out and ask questions directly to customer support if
                    needed; having all the answers at hand will make it much
                    easier to select the right VPN provider for you.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog10} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Conclusion: </h2>
                  <p>
                    IP addresses play an essential role in ensuring secure
                    communication over the Internet by providing unique
                    identifiers that allow us to distinguish one device from
                    another when sending data across networks or between systems
                    online.
                  </p>
                  <p>
                    Knowing what an IP address is, how it works and how we can
                    check/change our own IP addresses helps us stay safe online
                    while still enjoying all the benefits that come with using
                    the web today!.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Home;
