import { combineReducers } from 'redux';
import ipLookupReducer from './ipLookupReducer';
import userAgentReducer from './userAgentReducer';
import authReducer from './authReducer';
import loggerReducer from './loggerReducer';
import campaignReducer from './campaignReducer';

const allReducers = combineReducers({
    ipLookupReducer, userAgentReducer,authReducer,loggerReducer, campaignReducer
});

export default allReducers