import React, { useEffect, useState } from "react";
import Menubar from "../../components/Menubar/Menubar";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { UpdateDetailsIpInfo } from "../../actions/ipLookupAction";
import { useDispatch, useSelector } from "react-redux";
import IpDetails from "../IpDetails/IpDetails";
import { Link } from "react-scroll";

//CSS
import "./IpLookUp.css";

//images
import adplacementnew from "../../images/ad-placement-new.png";
import iplocation from "../../images/ip-location.png";
import search from "../../images/search.png";
import blog1 from "../../images/ip-lookup-page-image-1.png";
import blog2 from "../../images/ip-lookup-page-image-2.png";
import blog3 from "../../images/ip-lookup-page-image-3.png";
import blog4 from "../../images/ip-lookup-page-image-4.png";
import blog5 from "../../images/ip-lookup-page-image-5.png";
import blog6 from "../../images/ip-lookup-page-image-6.png";
import blog7 from "../../images/ip-lookup-page-image-7.png";
import blog8 from "../../images/ip-lookup-page-image-8.png";
import blog9 from "../../images/ip-lookup-page-image-9.png";
import blog10 from "../../images/ip-lookup-page-image-10.png";
import blog11 from "../../images/ip-lookup-page-image-11.png";

const IpLookUp = () => {
  const [ip, setIp] = useState("");
  const [Error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const { ipLookupError } = useSelector((state) => state.ipLookupReducer);
  const [showDetails, setShowDetails] = useState(false);

  const handleSearchButton = () => {
    if (!ip) {
      setShowError(true);
      setError("Please enter a valid IP address...!");
    } else {
      setShowDetails(true);
      dispatch(UpdateDetailsIpInfo(ip));
    }
  };

  useEffect(() => {
    if (ipLookupError) {
      setError(ipLookupError);
    }
    setShowError(true);
  }, [ipLookupError]);
  return (
    <div>
      <Menubar />
      <Container className="location-section">
        <Row>
          <Col lg={4} className="d-none d-lg-block">
            <img src={adplacementnew} alt="" />
          </Col>
          <Col lg={4} className="text-center">
            <img src={iplocation} alt="" />
            <div className="text-center lookup">
              <h3>LOOKUP</h3>
            </div>
          </Col>
          <Col lg={4} className="d-none d-lg-block">
            <img src={adplacementnew} alt="" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12} className="text-center look-up-address mt-4">
            <h3>Look up IP Address Location</h3>
            <p>
              If you can find out the IPv4 or IPv6 address of an Internet user,
              you can get an <br /> idea what part of the country or world
              they're in by using our IP Lookup tool.
            </p>
          </Col>
          <Col lg={12}>
            <Col lg={4} className="m-auto search-lookup">
              <Form className="d-flex justify-content-center search_design mt-4">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  value={ip}
                  onChange={(e) => {
                    setIp(e.target.value);
                    setShowError(false);
                  }}
                />
                <Link
                  activeClass="active"
                  to="ipDetails"
                  spy={true}
                  smooth={true}
                  duration={0}
                  delay={0}
                >
                  <Button
                    variant="outline-success"
                    onClick={() => handleSearchButton()}
                  >
                    <img src={search} alt="" />
                  </Button>
                </Link>
              </Form>
            </Col>
            {showError ? (
              <h4 className="error-message" style={{ textAlign: "center" }}>
                {Error}
              </h4>
            ) : (
              ""
            )}
          </Col>
          <Col lg={12}>
            <div className="ad-placement">
              <h5>AD PLACEMENT</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <div id="ipDetails">{showDetails && <IpDetails />}</div>
      <div className="seo-section">
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog1} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>Benefits of an IP Lookup Tool </h2>
                  <p>
                    Using an IP lookup tool is a valuable way to understand the
                    origin of any internet traffic. It involves tracing the IP
                    address attributed to it and gathering information about its
                    geolocation, which includes the city, region, country, and
                    even ISP.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog2} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>What is an IP address, and why is it significant? </h2>
                  <p>
                    An IP address is your virtual address, and every
                    internet-connected gadget has an associated IP address,
                    which indicates its IP geolocation. Since your IP address
                    contains your location, anyone who knows it can look you up
                    and see where you are right down the street you live on. An
                    IP lookup tool can also reveal who your ISP is - as an IP
                    address is attributed to you by your internet service
                    provider (ISP).{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog3} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>
                    How much information does an IP lookup tool retrieve?{" "}
                  </h2>
                  <p>
                    It is important to note that an IP locator tool cannot
                    provide you with personally identifiable information about a
                    user on the Internet. However, ISPs can still hunt down the
                    owner of an IP address and trace it if they need to. This
                    means it is crucial to be aware of where your data and
                    online activities are being sent so that you can protect
                    your privacy.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog4} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Benefits of an IP lookup tool</h2>
                  <p>
                    Overall, IP lookup tools can be a helpful way to gain
                    insight into your online activity and the location of where
                    your data is being sent. However, it is essential to
                    remember that ISPs have the power to trace IP addresses if
                    necessary. Therefore, monitoring your Network and protecting
                    your privacy should always be a top priority.{" "}
                  </p>
                  <p>
                    By taking the time to understand how IP addresses work and
                    what information can be revealed about you through an IP
                    lookup tool, you can ensure that your data is secure and not
                    vulnerable to potential risks or threats. Knowing your
                    public IP address can also help you troubleshoot network
                    issues quickly, allowing for a smoother online experience.
                  </p>
                  <p>
                    The IP Location Lookup tool is an easy and efficient way to
                    track information about a particular IP address quickly.
                    With the variety of Geo IP databases available, you can be
                    sure that your results are accurate and up-to-date. Start
                    using it today to gain insight into where your data is being
                    sent.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog5} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>What is a Reverse IP lookup</h2>
                  <p>
                    Most people today use reverse IP lookups for various
                    purposes. Whether you are trying to verify a customer's
                    identity or scout out a potential business partner, reverse
                    IP lookup techniques can provide invaluable insights into
                    the details of an online actor. With a reverse IP lookup,
                    you can enter an IP address and learn about its owner,
                    including its internet service provider (ISP), Hostname,
                    domain, and geographical location. Beyond simply providing
                    links between an individual's online activities and their
                    physical residences, reverse IP lookup services may also
                    offer useful information such as the latitude and longitude
                    coordinates of the user, as well as country code, postal
                    code, state, and region related to the search query. It's
                    important to note that your ISP will usually handle setting
                    up and maintaining reverse DNS records associated with your
                    IP address as part of its service package.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog6} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>What are iPv6 and iPv4 Addresses?</h2>
                  <p>
                    As the number of users and connected devices worldwide has
                    increased, IP addresses have also evolved. IP addresses
                    uniquely identify a device on a given network, and the IPv4
                    address is one of the most commonly used types of IP
                    address. It uses a 32-bit system to generate 4.3 billion
                    different combinations – but with a world population of
                    nearly 8 billion, this older version of IP addressing can no
                    longer cope with the demand. As a result, an improved
                    version known as iPv6 was developed from the ground up to
                    provide 128-bit hexadecimal addresses, which generates far
                    more unique numbers and supports enhanced security features.
                    Effectively, IPv6 allows for better scaling, enabling many
                    more IP addresses than were possible with IPv4.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog7} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How to find the IP address on an iPhone or Android? </h2>
                  <p>
                    IP addresses provide a critical link between digital devices
                    and the Internet. IP stands for Internet Protocol, and IP
                    addresses are essential in connecting your devices to the
                    wide web. When checking an IP address, it is essential to
                    remember that there are two types: public IPs, which allow a
                    device to be located on networks outside of your own, and
                    private IPs, which allow for communication within internal
                    networks. Checking the IP address of any device, including
                    your smartphone, can help you identify it both at home and
                    on the global Internet. In essence, IP addresses allow us to
                    take full advantage of today's interconnected world.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h3>Android Phones </h3>
                  <p>
                    Depending on the type of Android device, the location of
                    information and menu names may be slightly different, but
                    this will give you a general idea:{" "}
                  </p>
                  <ol>
                    <li>
                      Start by accessing <b>Settings</b> and then scroll down to
                      select <b>Network & internet {">"} WiFi.</b>
                    </li>
                    <li>
                      Connect if not already connected to your specific Network
                      in that same section before advancing further.
                    </li>
                    <li>
                      Next step would be tapping on its name, which expands
                      another area called <b>Advanced.</b>
                    </li>
                    <li>
                      Under there lies all vital info, such as{" "}
                      <b>Network Details,</b> where one can spot their own
                      personal IP address, including any <b>IPv6 addresses</b>{" "}
                      listed at the bottom (if available).
                    </li>
                  </ol>
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h3>iPhones</h3>
                  <p>
                    Want to find out your iPhone's local IP address? It's easy:{" "}
                  </p>
                  <ol>
                    <li>
                      Head into <b>Settings,</b> select <b>WiFi</b> and connect
                      to your home network.
                    </li>
                    <li>
                      Tap on the name of that connection for more details.
                    </li>
                    <li>
                      Now look for an <b>IPv4 Address</b> header{" "}
                    </li>
                    <li>Here, you can view your device's current IP.</li>
                  </ol>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} className="order-lg-1">
                <div className="seo-section-content">
                  <h3>Finding the Public IP of your phone </h3>
                  <p>
                    Uncovering your private IP address on a local network is
                    only the first step - now, let's take it one step further
                    and uncover its global identity. With just a few taps on
                    your phone or computer mouse clicks, you can easily find out
                    what this public IP address is that any device worldwide
                    will be able to identify! So, no matter which carrier you're
                    using, mapping out where your online presence lies in the
                    universe has always been more complex.{" "}
                  </p>
                  <p>
                    Accessing a public IP checker website from your Android
                    Chrome browser or iPhone Safari can find helpful information
                    such as the geographical area, ISP name, and more. No need
                    to look up someone's exact address - an IP trace will narrow
                    it down quite accurately to their city. Get access to all
                    this info in just a few taps on your smartphone's screen.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog8} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>Is it illegal to look up an IP address? </h2>
                  <p>
                    Checking someone else's IP address is a harmless but
                    powerful security tool. Each device connected to the
                    Internet has its unique IP address, and an IP lookup tool
                    can easily display it within seconds. This promotes
                    accountability on the Internet, as people have the knowledge
                    to look up IP addresses and track down suspicious activity.
                    While checking someone's IP address does not reveal personal
                    information like their name or email address, it provides
                    enough data for people to take necessary steps in regulating
                    online behavior and investigating perpetrators of
                    cybercrimes.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog9} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>
                    Are IP Lookup, IP Checker, and IP Locator different tools?{" "}
                  </h2>
                  <p>
                    IP Lookup, IP Checker, IP Locator - whatever the name may
                    be, it's still the same lookup tool. An IP lookup is an
                    essential service that can tell you information about your
                    public IP address, such as what country or city you are
                    connecting from and the IP address owner or Internet Service
                    Provider (ISP). In addition, a good IP lookup tool enables
                    you to perform a reverse IP lookup to check what websites
                    share your IP address. All in all, it's a helpful feature
                    for many different circumstances.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog10} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>Download our IP Lookup Tool</h2>
                  <p>
                    The information provided by the IP Location Lookup tool can
                    be invaluable in ensuring that your data is protected and
                    secure. Download our IP lookup tool and start using it today
                    for a more secure online experience.
                  </p>
                  <div className="seo-section-image">
                    <img src={blog11} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default IpLookUp;
