import axios from "axios";

const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export const fetchCampaigns = async (clientId) => {
    try {
        const response = await axiosConfig.post(`cplatform/get_campaigns`, {
            clientId: clientId
        });
	    return {
            campaigns: response.data,
            error: null,
        };
    } catch (error) {
        console.error("Error fetching campaigns:", error);
        return {
            campaigns: [],
            error: error.message,
        };
    }
};

export const fetchCampaignById = async (userId) => {
    try {
        const response = await axiosConfig.post(`cplatform/trigger_campaign`, {
            userId: userId,
        });
        return {
            campaign: response.data,
            error: null,
        };
    } catch (error) {
        console.error("Error fetching campaign:", error);
        return {
            campaign: null,
            error: error.message,
        };
    }
};

export const deleteCampaign = async (userId) => {
    try {
        const response = await axiosConfig.post(`cplatform/delete_campaign`, {
            userId: userId,
        });
        return {
            campaigns: response.data,
            error: null,
        };
    } catch (error) {
        console.error("Error deleting campaign:", error);
        return {
            campaigns: [],
            error: error.message,
        };
    }
};

export const addNewCampaign = async (newCampaign) => {
    try {
        const response = await axiosConfig.get(`cplatform/save_campaign`, {
            params: {
                clientId: newCampaign.clientId,
                userId: newCampaign.userId,
                adUrl: newCampaign.adUrl,
                time: newCampaign.time,
            }
        });

        return {
            campaigns: response.data,
            error: null,
        };
    } catch (error) {
        console.error("Error adding new campaign:", error);
        return {
            campaigns: [],
            error: error?.response?.data?.error,
        };
    }
};