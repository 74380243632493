import { ADD_NEW_CAMPAIGN, UPDATE_CAMPAIGN, DELETE_CAMPAIGN, REFRESH_CAMPAIGN, SET_CAMPAIGNS } from "../types";

const INITIAL_STATE = {
	campaigns: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_CAMPAIGNS:
			localStorage.setItem("campaigns", JSON.stringify(action.payload));
			return {
				...state,
				campaigns: action.payload,
			};

		case ADD_NEW_CAMPAIGN:
			// store into local storage as well
			localStorage.setItem("campaigns", JSON.stringify([...state.campaigns, action.payload]));
			return {
				...state,
				campaigns: [...state.campaigns, action.payload],
			};

		case UPDATE_CAMPAIGN:
			return {
				...state,
				campaigns: state.campaigns.map((campaign) =>
					campaign.userId === action.payload.userId
						? { ...campaign, ...action.payload }
						: campaign
				),
			};

		case DELETE_CAMPAIGN:
			// store into local storage as well
			localStorage.setItem(
				"campaigns",
				JSON.stringify(
					state.campaigns.filter(
						(campaign) => campaign.userId !== action.payload
					)
				)
			);
			return {
				...state,
				campaigns: state.campaigns.filter(
					(campaign) => campaign.userId !== action.payload
				),
			};

		case REFRESH_CAMPAIGN:
			// Refresh the campaigns array and get the latest from the local  storage
			return {
				...state,
				campaigns: JSON.parse(localStorage.getItem("campaigns")) || [],
			};

		default:
			return state;
	}
};
