import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import parse from 'html-react-parser';


function IFrame({ children,iframeWidth }) {
  const [ref, setRef] = useState();
  const container = ref?.contentDocument?.body;
  return (
    <iframe
      title="iframeTestAdstr"
      ref={setRef}
      width={iframeWidth}
      height="auto"
      id='test-iframe-id' 
    >
      {container && createPortal(children, container)}
    </iframe>
  );
}

function MyComponent({ userId, width,protocolVal,tagVal,tagType, formField }) {
  let tagTypeInLower = tagType.toLowerCase();
  let isDefaultIframe = tagType && tagTypeInLower==='default' ? true : false;

  let USER_ID_SHA1 = formField.user_id_sha1 || '{{USER_ID_SHA1}}'
  let APP_NAME = formField.app_name || '{{APP_NAME}}'
  let APP_BUNDLE = formField.app_bundle || '{{APP_BUNDLE}}'
  let APP_ID = formField.app_id || '{{APP_ID}}'
  let DOMAIN = formField.domain || '{{DOMAIN}}'
  let USER_AGENT_ESC = formField.user_agent || '{{USER_AGENT_ESC}}'
  let IP_ADDRESS_ESC = formField.ipv4_address || '{{IP_ADDRESS_ESC}}'
  let PLATFORM_OS = formField.operating_sys || '{{PLATFORM_OS}}'
  let DEVICE_MAKE = formField.device_manufacturer || '{{DEVICE_MAKE}}'
  let DEVICE_MODEL = formField.device_model || '{{DEVICE_MODEL}}'
  let COUNTRY_CODE = formField.country_code || '{{COUNTRY_CODE}}'
  let IP_ADDRESS_IPV6 = formField.ipv6_address || '{{IP_ADDRESS_IPV6}}'
  
  useEffect(() => {
    if(isDefaultIframe){ 
      const iframe = document.getElementById('test-iframe-id');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      // Create a script element
      const scriptElementTag = document.createElement('script');
      scriptElementTag.type = 'text/javascript';  
      scriptElementTag.text = `  
      var scriptTag = document.createElement('script');
      scriptTag.src = '${protocolVal}://noas.mtrtb.com/getAd?idfa=${userId}';
      document.body.appendChild(scriptTag)`;
      iframeDocument.head.appendChild(scriptElementTag);

      // Create a script element
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript'; 

      //scriptTag.src = '${protocolVal}://noas.powerium.net/getAd?idfa=${userId}&bundle={{APP_BUNDLE}}&dom={{DOMAIN}}&sha1={{USER_ID_SHA1}}';       
      scriptElement.text = `  
      var scriptTag = document.createElement('script');  
      scriptTag.src = '${protocolVal}://dtech.adtimizer.co/DSPCLogger/Impoint?idfa=${userId}&user_id_sha1=${USER_ID_SHA1}&appName=${APP_NAME}&appVer=&bundle=${APP_BUNDLE}&appCategory=&appId=${APP_ID}&sourceId={{EXCHANGE_HANDLE}}&domain=${DOMAIN}&source={{INVENTORY_SOURCE}}&page={{PAGE}}&pubId={{PUBLISHER_ID}}&conn=&carrier=&dnt={{DNT}}&uid={{AUCTION_ID}}&ua=${USER_AGENT_ESC}&ip=${IP_ADDRESS_ESC}&os=${PLATFORM_OS}&osVersion=&deviceMake=${DEVICE_MAKE}&deviceModel=${DEVICE_MODEL}&location=&country=${COUNTRY_CODE}&lat={{LAT}}&lon={{LONG}}&width={{WIDTH}}&height={{HEIGHT}}&model=&cost=&creativeId={{CREATIVE_ID}}&campaignId={{CAMPAIGN_ID}}&campaignName=&exc={{INVENTORY_SOURCE}}&placementId={{PLACEMENT_ID}}&placementName=placement_name_enc&bidPrice=&winPrice=&language_enc=&zip={{ZIP_CODE}}&env=web&ipv6=${IP_ADDRESS_IPV6}&dsp=BW';
      document.body.appendChild(scriptTag)`;
      // Append the script element to the head of the iframe document
      iframeDocument.head.appendChild(scriptElement);

      const scriptElement2 = document.createElement('script');
      scriptElement2.type = 'text/javascript'; 

      scriptElement2.text = `  
      var scriptTag = document.createElement('script'); 
      scriptTag.src = '{{IMPRESSION_URL}}';       
      document.body.appendChild(scriptTag)`; 
      iframeDocument.head.appendChild(scriptElement2);
    }else{
      const iframe = document.getElementById('test-iframe-id');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript'; 

      if(tagVal.includes('powerium')){
        // scriptElement.text = `
        // var scriptTag = document.createElement('script');
        // scriptTag.src = '${protocolVal}://noas.powerium.net/getAd?idfa=${userId}&page=httpynet';
        // document.body.appendChild(scriptTag)`;

        const regex = /<script\s+src=['"]([^'"]+)['"]>/g;
        let match;

        while ((match = regex.exec(tagVal)) !== null) {
          const scriptSrc = match[1]; // Extracts the src attribute value
          if (scriptSrc.includes('noas.powerium.net/getAd')) {
            scriptElement.text = `
            var scriptTag = document.createElement('script');
            scriptTag.src = '${scriptSrc}';
            document.body.appendChild(scriptTag)`;
          }
        }
      }else{
        scriptElement.text = `  
        var scriptTag = document.createElement('script');  
        scriptTag.src = '${protocolVal}://noas.mtrtb.com/getAd?idfa=${userId}';  
        document.body.appendChild(scriptTag)`; 
      }
       
      iframeDocument.head.appendChild(scriptElement);

      const scriptElement2 = document.createElement('script');
      scriptElement2.type = 'text/javascript'; 

      scriptElement2.text = `  
      var scriptTag = document.createElement('script'); 
      scriptTag.src = '{{IMPRESSION_URL}}';       
      document.body.appendChild(scriptTag)`; 
      iframeDocument.head.appendChild(scriptElement2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId,isDefaultIframe,protocolVal]); 


  let scriptTestTemp; 
  if(isDefaultIframe){ 
    scriptTestTemp = ` 
    var clickURLs =["https://dtech.adtimizer.co/DSPCLogger/ClickProcessor?idfa=${userId}&user_id_sha1=${USER_ID_SHA1}&appName=${APP_NAME}&appVer=&bundle=${APP_BUNDLE}&appCategory=&appId=${APP_ID}&sourceId={{EXCHANGE_HANDLE}}&domain=${DOMAIN}&source={{INVENTORY_SOURCE}}&page={{PAGE}}&pubId={{PUBLISHER_ID}}&conn=&carrier=&dnt={{DNT}}&uid={{AUCTION_ID}}&ua=${USER_AGENT_ESC}&ip=${IP_ADDRESS_ESC}&os=${PLATFORM_OS}&osVersion=&deviceMake=${DEVICE_MAKE}&deviceModel=${DEVICE_MODEL}&location=&country=${COUNTRY_CODE}&lat={{LAT}}&lon={{LONG}}&width={{WIDTH}}&height={{HEIGHT}}&model=&cost=&creativeId={{CREATIVE_ID}}&campaignId={{CAMPAIGN_ID}}&campaignName=&exc={{INVENTORY_SOURCE}}&placementId={{PLACEMENT_ID}}&placementName=placement_name_enc&bidPrice=&winPrice=&language_enc=&zip={{ZIP_CODE}}&env=web&ipv6=${IP_ADDRESS_IPV6}&dsp=BW","{{CLICK_URL}}"];
    function clicker() {
    for (var i = 0; i < clickURLs.length; i++) {
                (new Image()).src = clickURLs[i];
            }
    }`;  
  } 
  
  

  function isHTMLString(input) {
    // Regular expression to match HTML tags
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  
    return htmlRegex.test(input);
  }
  let iframeWidthNew =  width - (width * 5) / 100;


  if(isDefaultIframe){
    let scriptTest = scriptTestTemp.replace(new RegExp('{{USER_ID}}', 'g'), userId);

    return (
      <>
        <a href='https://alitems.co/g/1e8d11449458cd0c4aa216525dc3e8/' rel="noreferrer" target='_blank' className="flex justify-start items-center"   >
          <img src='https://cloud-images.s3.eu-west-1.amazonaws.com/MyDSP_Beeswax/Ali_rt_shop/320x50_2.png' alt="" style={{ width: `${iframeWidthNew}px`,border:'1px solid white' }} />
        </a>
        <script type="text/javascript">{scriptTest}</script>
      </>
    )
  } else {  
    //
     let tagTemp =tagVal;
     if(tagVal.includes('powerium')){
        tagTemp = tagTemp.replaceAll(`<script src='https://noas.powerium.net/getAd?idfa={{USER_ID}}&bundle={{APP_BUNDLE}}&exc={{INVENTORY_SOURCE}}&dom={{DOMAIN}}&sha1={{USER_ID_SHA1}}'>`, '')
     }else{ 
       tagTemp = tagTemp.replaceAll(`<script src='https://noas.mtrtb.com/getAd?idfa={{USER_ID}}&bundle={{APP_BUNDLE}}&exc={{INVENTORY_SOURCE}}&dom={{DOMAIN}}&sha1={{USER_ID_SHA1}}'></script>`, '')
     }
    tagTemp = tagTemp.replaceAll(`<script src='{{IMPRESSION_URL}}'></script>`, ''); 
    // tagTemp = tagTemp.replaceAll(`<a href='https://s.click.aliexpress.com/e/_DcjZmiP' target='_blank' onClick='clicker();'>`, `<a href='https://s.click.aliexpress.com/e/_DcjZmiP' target='_blank' >`)
    // tagTemp = tagTemp.replaceAll(`<script>`, `<html type='text/javascript'>`); 

     tagTemp = tagTemp.replaceAll(`<html>`, ``); 
     tagTemp = tagTemp.replaceAll(`</html>`, ``); 
     tagTemp = tagTemp.replaceAll(`<body>`, ``); 
     tagTemp = tagTemp.replaceAll(`</body>`, ``); 
    tagTemp = tagTemp.replaceAll(`<script>`, `<script type='text/javascript'>`);  

    let finalTagVal = tagTemp.replaceAll(`{{USER_ID}}`, userId);
    
    //finalTagVal = `${finalTagVal} <script type="text/javascript">${customQATagScript}</h>`;
    if(isHTMLString(finalTagVal)){
      return parse(finalTagVal.replaceAll(`{USER_ID}`, userId)) ;
    }else{
      return finalTagVal;
    }
  }
}

export default function TestAdsterra({ userId, width,protocolVal,tagVal,tagType, formField }) {
  let iframeWidth = window.innerWidth >= 780 ? 780 : window.innerWidth - (window.innerWidth * 20) / 100;

  return (
    <IFrame iframeWidth={iframeWidth}>
      <MyComponent
        userId={userId}
        width={iframeWidth}
        protocolVal={protocolVal}
        tagVal={tagVal}
        tagType={tagType}
        formField={formField}
      />
    </IFrame>
  );
}




