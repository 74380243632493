import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Pagination = ({ tableProps, sizePerPageList,totalRecords }) => {
  /**
   * pagination count , index
   */
  const [pageCount, setPageCount] = useState(tableProps.pageCount);
  const [pageIndex, setPageIndex] = useState(
    tableProps.state.pageIndex
  );

  useEffect(() => {
    setPageCount(tableProps.pageCount);
    setPageIndex(tableProps.state.pageIndex);
  }, [tableProps.pageCount, tableProps.state.pageIndex]);

  /**
   * get filter pages
   */
  const filterPages = useCallback(
    (visiblePages, totalPages) => {
      return visiblePages.filter((page) => page <= pageCount);
    },
    [pageCount]
  );

  /**
   * handle visible pages
   */
  const getVisiblePages = useCallback(
    (page, total) => {
      if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
      } else {
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
          return [1, page - 1, page, page + 1, total];
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total];
        } else {
          return [1, 2, 3, 4, 5, total];
        }
      }
    },
    [filterPages]
  );

  /**
   * handle page change
   * @param page - current page
   * @returns
   */
  const changePage = (page) => {
    const activePage = pageIndex + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = getVisiblePages(page, pageCount);
    setVisiblePages(filterPages(visiblePages, pageCount));

    tableProps.gotoPage(page - 1);
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(null, pageCount);
    setVisiblePages(visiblePages);
  }, [pageCount, getVisiblePages]);

  const [visiblePages, setVisiblePages] = useState(
    getVisiblePages(null, pageCount)
  );
  const activePage = pageIndex + 1;
  
  const numberFormat = new Intl.NumberFormat("en-US")

  return (
    <>
      <div className="d-lg-flex align-items-center text-center pb-1 p-fixed">
        {sizePerPageList.length > 0 && (
          <div className="d-inline-block me-3">
            <label style={{ fontSize: ".875rem", fontWeight: "700", color: "#4b4b5a" }} className="me-1">Display :</label>
            <select
              style={{ color: "#4b4b5a", padding: ".45rem 2.7rem .45rem .9rem" }}
              value={tableProps.state.pageSize}
              onChange={(e) => {
                tableProps.setPageSize(Number(e.target.value));
              }}
              className="form-select d-inline-block w-auto fsz-875rem"
            >
              {(sizePerPageList || []).map((pageSize, index) => {
                return (
                  <option key={index} value={pageSize.value}>
                    {pageSize.text}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <span className="me-3" style={{ color: "#4b4b5a", fontSize: ".875rem" }}>
          Page{" "}
          <strong style={{ fontSize: ".875rem" }}>
            {pageIndex + 1} of {tableProps.pageOptions.length}
          </strong>{" "}
        </span>

        <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2 mx-2">
          <label style={{ fontWeight: "700", fontSize: ".875rem", color: "#4b4b5a" }}>Go to page : </label>
          <input
            type="number"
            value={pageIndex + 1}
            min="1"
            style={{ padding: "7px", color: "#4b4b5a", fontSize: ".875rem !important",width:'60px',textAlign:'center' }}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableProps.gotoPage(page);
              setPageIndex(tableProps.state.pageIndex);
            }}
            className="form-control  ms-1 d-inline-block fsz-875rem"
          />
        </span>
        <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
          <label style={{ fontWeight: "700", fontSize: ".875rem", color: "#4b4b5a" }}>Total Lines : </label> {numberFormat.format(totalRecords)}
        </span>

        <ul style={{ fontSize: ".875rem", }} className="pagination pagination-rounded d-inline-flex ms-auto fsz-875rem">
          <li
            key="prevpage"
            className={classNames("page-item", "paginate_button", "previous", "border-0", {
              disabled: activePage === 1,
            })}
            onClick={() => {
              if (activePage === 1) return;
              changePage(activePage - 1);
            }}
          >
            <Link to="#" className="page-link fsz-875rem">
              <i className="uil uil-angle-left">{"<"}</i>
            </Link>
          </li>
          {(visiblePages || []).map((page, index, array) => {
            return array[index - 1] + 1 < page ? (
              <React.Fragment key={page}>
                <li className="page-item disabled d-none d-xl-inline-block ">
                  <Link to="#" className="page-link" >
                    ...
                  </Link>
                </li>
                <li
                  className={classNames(
                    "page-item",
                    "d-none",
                    "d-xl-inline-block",
                    "fsz-875rem",
                    {
                      active: activePage === page,
                    }
                  )}
                  onClick={(e) => changePage(page)}
                >
                  <Link to="#" className="page-link fsz-875rem">
                    {page}
                  </Link>
                </li>
              </React.Fragment>
            ) : (
              <li
                key={page}
                className={classNames(
                  "page-item",
                  "d-none",
                  "fsz-875rem",
                  "d-xl-inline-block",
                  {
                    active: activePage === page,
                  }
                )}
                onClick={(e) => changePage(page)}
              >
                <Link to="#" className="page-link fsz-875rem">
                  {page}
                </Link>
              </li>
            );
          })}
          <li
            key="nextpage"
            className={classNames("page-item", "paginate_button", "next", {
              disabled: activePage === tableProps.pageCount,
            })}
            onClick={() => {
              if (activePage === tableProps.pageCount) return;
              changePage(activePage + 1);
            }}
          >
            <Link to="#" className="page-link fsz-875rem">
              <i className="uil uil-angle-right">{">"}</i>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;