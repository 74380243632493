import './App.css';
import Routes from './Routes';
import { Provider } from 'react-redux'
import {store,persistor} from './store/store';
//import AuthProvider from './AuthProvider';
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
   <div className='bg-gradient-to-r from-[#f3f4f8] to-[#f3f4f8]'>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <AuthProvider> */}
          <Routes/>
        {/* </AuthProvider> */}
      </PersistGate>
    </Provider>
   </div>
  );
}

export default App;
