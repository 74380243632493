import React from "react";
import { Link, useLocation } from "react-router-dom";

const Links = [
	{ title: "Event Logger", href: "/logger" },
	{ title: "Test Banner", href: "/test" },
	{ title: "User ID Validator", href: "/validator" },
];

const Menubar = () => {
	const path = useLocation().pathname;

	return (
		<div className="flex flex-row px-3 py-3">
			<nav className={` customNavMenu py-2  font-semibold`}>
				{Links.map((link) => (
					<Link
						to={link.href}
						key={link.href}
						className={`${
							path === link.href ? "active" : ""
						} transition-all`}
					>
						{link.title}
					</Link>
				))}
			</nav>
		</div>
	);
};

export default Menubar;
