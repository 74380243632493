import React,{useEffect} from "react";
import Menubar from "../../components/Menubar/Menubar";
import Footer from "../../components/Footer/Footer";
import ExternalLoggerPost from "./ExternalLoggerPost";

const HomePostMethod = () => {
    useEffect(() => { 
        document.title = `Event Logger Post Method`;
    }, []); 
    return <div>
        <Menubar  />
        <section className="px-3 externalLoggerSection" style={{ minHeight: 'calc(100vh - 136px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <ExternalLoggerPost />
        </section>
        <Footer />
    </div>
}

export default HomePostMethod;