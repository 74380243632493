import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import ScrollToTop from "./ScrollToTop";
import Home from "./views/Home/Home";
import HomeOld from "./views/Home/HomeOld";
import IpLookUp from "./views/IpLookUp/IpLookUp";
import IpLookUpOld from "./views/IpLookUp/IpLookUpOld";
import PrivacyPolicy from "./views/PrivacyPolicy";
import SpeedTest from "./views/SpeedTest/SpeedTest";
import SpeedTestOld from "./views/SpeedTest/SpeedTestOld";
import TermsAndCondition from "./views/TermsAndCondition";
import UserAgent from "./views/UserAgent/UserAgent";
import UserAgentOld from "./views/UserAgent/UserAgentOld";
import TestHome from "./views/TestHome/TestHome";
import Redirect from "./components/Redirect";
import BlankPage from "./views/BlankPage"; 
import TestBannerHome from "./views/TestBanner/TestBannerHome";
import UserValidate from "./views/UserValidate/UserValidate";
import HomePostMethod from "./views/Home/HomePostMethod";
import HomePostFile from "./views/Home/HomePostFile";
import ExternalLoggerBlankPageForJsonData from "./views/Home/ExternalLoggerBlankPage";
import HomeGetPost from "./views/HomeGetPost/HomeGetPost";
import JsonUpdateComponent from './views/JsonUpdate/JsonUpdateComponent'
import LoggerJson from "./views/LoggerJson/LoggerJson";
import JsonToCSV from './views/JsonToCSV/JsonToCSV'
import CSVtoJson from './views/CSVtoJson/CSVtoJson'
import UrlWhiteLister from "./views/UrlWhiteLister/UrlWhiteLister";

const Routes = () => {
  return (
    <Router>
      <Fragment>
        {/* <ScrollToTop /> */}

        <Switch>  
          <Route exact path="/redirect" component={Redirect} />
          {/* OLD */}
          <Route exact path="/old" component={HomeOld} />
          <Route exact path="/old/speed-test" component={SpeedTestOld} />
          <Route exact path="/old/ip-lookup" component={IpLookUpOld} />
          <Route exact path="/old/user-agent" component={UserAgentOld} />

          <Route exact path="/" component={BlankPage} />
          <Route exact path="/logger" component={Home} /> 
          <Route exact path="/logger-json" component={LoggerJson} /> 
          <Route exact path="/logger-post" component={HomePostMethod} /> 
          <Route exact path="/reverseevent-json" component={ExternalLoggerBlankPageForJsonData} /> 

          <Route exact path="/logger-file" component={HomePostFile} />  
          {/* <Route exact path="/ip-details" component={IpDetails} /> */}
          <Route exact path="/speed-test" component={SpeedTest} />
          <Route exact path="/ip-lookup" component={IpLookUp} />
          <Route exact path="/user-agent" component={UserAgent} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndCondition}
          />
          <Route exact path="/logger-get-post" component={HomeGetPost} /> 
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/testbanner" component={TestBannerHome} />
          <Route exact path="/test" component={TestHome} />
          <Route exact path="/validator" component={UserValidate} />
          <Route exact path="/json-update" component={JsonUpdateComponent} />
          <Route exact path="/json-to-csv" component={JsonToCSV} />
          <Route exact path="/csv-to-json" component={CSVtoJson} />
          <Route exact path="/url-whitelister" component={UrlWhiteLister} />
        </Switch>
      </Fragment>
    </Router>
  );
};

export default Routes;
