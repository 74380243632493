import {
    GET_USER_AGENT_LOOKUP_REQUEST,
    GET_USER_AGENT_LOOKUP_SUCCESS,
    GET_USER_AGENT_LOOKUP_FAIL,
    GET_SYSTEM_INFO_REQUEST,
    GET_SYSTEM_INFO_SUCCESS,
    GET_EXTERNAL_LOGGER_REQUEST,
    GET_EXTERNAL_LOGGER_SUCCESS,
    GET_EXTERNAL_LOGGER_FAILED,
    RESET_EVENTS_FAILED,
    RESET_EVENTS_REQUEST,
    RESET_EVENTS_SUCCESS,
    GET_EXTERNAL_LOGGER_POST_REQUEST,
    GET_EXTERNAL_LOGGER_POST_SUCCESS,
    GET_EXTERNAL_LOGGER_POST_FAILED
} from '../types';

const initialState = {
    lookupAgentLoader: false,
    lookupAgentData: {},
    lookupAgentError: {},
    readUserAgentSystemInfo:'',
    systemInfoLoader:false,
    externalloggerLoader:false,
    loggerData:[], //External Logger,
    resetEventLoader:false,
    externalloggerPostLoader:false, 
    loggerDataPost:[], //External Logger,
}

const userAgentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_AGENT_LOOKUP_REQUEST:
            return { ...state, lookupAgentLoader: true }

        case GET_USER_AGENT_LOOKUP_SUCCESS:
            return { ...state, lookupAgentLoader: false, lookupAgentData: action.payload }

        case GET_USER_AGENT_LOOKUP_FAIL:
            return { ...state, ipLookupLoader: false, lookupAgentError: { Error: 'Unknown Error Occured!' } }

        case GET_SYSTEM_INFO_REQUEST:
            return { ...state, systemInfoLoader: true }

        case GET_SYSTEM_INFO_SUCCESS:
            return { ...state, systemInfoLoader:false,readUserAgentSystemInfo:action.payload }

        case GET_EXTERNAL_LOGGER_REQUEST:
            return { ...state, externalloggerLoader:true }
        case GET_EXTERNAL_LOGGER_SUCCESS:
            return { ...state, externalloggerLoader:false, loggerData:action.payload }
        case GET_EXTERNAL_LOGGER_FAILED:
            return { ...state, externalloggerLoader:false, loggerData:[] } 
        case RESET_EVENTS_REQUEST:
            return { ...state, resetEventLoader:true }
        case RESET_EVENTS_SUCCESS:
            return { ...state, resetEventLoader:false }
        case RESET_EVENTS_FAILED:
            return { ...state, resetEventLoader:false }  
        case GET_EXTERNAL_LOGGER_POST_REQUEST:
            return { ...state, externalloggerPostLoader:true }
        case GET_EXTERNAL_LOGGER_POST_SUCCESS:
            return { ...state, externalloggerPostLoader:false, loggerDataPost:action.payload }
        case GET_EXTERNAL_LOGGER_POST_FAILED:
            return { ...state, externalloggerPostLoader:false, loggerDataPost:[] } 
        default:
            return state;
    }
}
export default userAgentReducer;