import React, { useEffect } from "react";
import Menubar from "../../components/Menubar/Menubar";
import Footer from "../../components/Footer/Footer";
import ExternalLogger from "./ExternalLogger";

const HomeGetPost = () => {
	useEffect(() => {
		document.title = `GET/POST Logger`;
	}, []);
	return (
		<div>
			<Menubar />
			<section
				className="px-3 externalLoggerSection"
				style={{
					minHeight: "calc(100vh - 136px)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<ExternalLogger />
			</section>
			<Footer />
		</div>
	);
};

export default HomeGetPost;
