import React, { useEffect } from "react";
import filterStore from "../store/zustand/filter.store";

const Excludebar = () => {
	// filter store
	const { excludeValue, setExcludeValue } = filterStore();

  const [val, setVal] = React.useState("");
	
  const handleExcludeValue = () => {
    setExcludeValue(val);
  };

	useEffect(() => {
		setVal(excludeValue);
	}, [excludeValue])

	return (
		<div className="d-flex items-center color-4b4b5a">
			Exclude :{" "}
			<input
				type="search"
				value={val || ""}
				onChange={(e) => {
					setVal(e.target.value);
				}}
				placeholder={"Exclude records..."}
				className="form-control w-auto ms-3 me-1 color-4b4b5a"
			/>
			<button type="button" className="focus:outline-none ms-3 relative z-10 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 font-bold rounded-lg text-sm px-4 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800" onClick={handleExcludeValue}>
				Exclude
			</button>
		</div>
	);
};

export default Excludebar;
