/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { animateScroll } from "react-scroll";
import ReactLoading from "react-loading";

import Menubar from "../../components/Menubar/Menubar";
import Footer from "../../components/Footer/Footer";
import { FloatingLabel, Form } from "react-bootstrap";
import TestAdsterraQA from "./TestAdsterraQA";
import TestAdsterra from "./TestAdsterra";

const FORM_FIELDS = [
	{
		label: "USER ID SHA1",
		name: "user_id_sha1",
	},
	{
		label: "IPv4 Address",
		name: "ipv4_address",
	},
	{
		label: "App Name",
		name: "app_name",
	},
	{
		label: "IPv6 Address",
		name: "ipv6_address",
	},
	{
		label: "App Bundle",
		name: "app_bundle",
	},
	{
		label: "User Agent",
		name: "user_agent",
	},
	{
		label: "App ID",
		name: "app_id",
	},
	{
		label: "Device Manufacturer",
		name: "device_manufacturer",
	},
	{
		label: "Domain",
		name: "domain",
	},
	{
		label: "Device Model",
		name: "device_model",
	},
	{
		label: "Country Code",
		name: "country_code",
	},
	{
		label: "Operating System",
		name: "operating_sys",
	},
];

const Test = () => {
	const [cookies, setCookie] = useCookies("userIdSearch");
	const [userId, setUserId] = useState(
		cookies?.userIdSearch ? cookies?.userIdSearch : ""
	);
	const [hideIframe, setHideIframe] = useState(true);
	const [refreshLoader, setRefreshLoader] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [, updateState] = useState();
	const [showSearch, setShowSearch] = useState(false);
	const [protocolVal, setProtocolVal] = useState("https");
	const [tagType, setTagType] = useState("Default");
	const [tagVal, setTagVal] = useState("");
	const [parameterType, setParameterType] = useState("Default");
	const [parameterVal, setParameterVal] = useState("");
	const [formField, setFormField] = useState({
		user_id_sha1: "",
		ipv4_address: "",
		app_name: "",
		ipv6_address: "",
		app_bundle: "",
		user_agent: "",
		app_id: "",
		device_manufacturer: "",
		domain: "",
		device_model: "",
		country_code: "",
		operating_sys: "",
	});

	const scrollRef = useRef();

	const forceUpdate = useCallback(() => updateState({}), []);

	const handleSearchButton = (e) => {
		e.preventDefault();
		setCookie("userIdSearch", userId, { path: "/" });
	};

	const handleRefreshClick = () => {
		setHideIframe(false);
		setRefreshLoader(true);
		setTimeout(() => {
			setRefreshLoader(false);
			forceUpdate();
		}, 200);
	};

	const onChange = (event) => {
		setHideIframe(true);
		setUserId(event.target.value);
		setCookie("userIdSearch", event.target.value, { path: "/" });
	};

	const onSubmit = (e) => {
		handleSearchButton(e);
		animateScroll.scrollTo(scrollRef?.current?.getBoundingClientRect().top);
	};

	useEffect(() => {
		document.title = `Test Banner`;
	}, []);

	return (
		<div>
			<Menubar />
			<section
				className="px-3 externalLoggerSection "
				style={{
					minHeight: "calc(100vh - 144px)",
					padding: "50px 0px 50px 0px",
				}}
			>
				<div
					className="flex flex-col p-6 max-w-5xl w-full mx-auto rounded-3xl mt-6"
					style={{
						background: "#fff",
					}}
				>
					<form onSubmit={onSubmit}>
						<div className="grid grid-cols-1">
							<div className="flex flex-col text-center">
								<div
									className={`${
										showSearch ? "block" : "hidden"
									} md:block`}
									style={{
										display: "flex",
										justifyContent: "space-around",
										marginBottom: "20px",
										flexWrap: "wrap",
										gap: "10px",
									}}
								>
									<div
										className="flex-row gap-3 rounded-lg bg-white px-3 py-2"
										style={{
											display: "inline-flex",
											border: "1px solid #ced4da",
										}}
									>
										<input
											type="text"
											className="bg-transparent border-none outline-none"
											placeholder={"Enter User ID"}
											value={userId}
											onChange={onChange}
										/>
										<img
											src="/icons/search.svg"
											alt="icon"
											className="cursor-pointer"
											onClick={onSubmit}
										/>
									</div>
									{/* <div
										className="protocolFields"
										style={{
											display: "flex",
											justifyContent: "space-around",
											alignItems: "center",
										}}
									>
										<span
											className="font-bold"
											style={{ padding: "0px 10px" }}
										>
											Protocol:
										</span>
										<div>
											{["radio"].map((type) => (
												<div
													key={`Protocol-inline-${type}`}
												>
													<Form.Check
														inline
														label="https"
														type={type}
														id={`Protocol-${type}-1`}
														checked={
															protocolVal ===
															"https"
																? true
																: false
														}
														onChange={(e) => {
															setHideIframe(true);
															setProtocolVal(
																e.target.checked
																	? "https"
																	: ""
															);
														}}
													/>
													<Form.Check
														inline
														label="http"
														type={type}
														id={`Protocol-${type}-2`}
														checked={
															protocolVal ===
															"http"
																? true
																: false
														}
														onChange={(e) => {
															setHideIframe(true);
															setProtocolVal(
																e.target.checked
																	? "http"
																	: ""
															);
														}}
													/>
												</div>
											))}
										</div>
									</div> */}
								</div>
								<h1 className="font-bold mb-3">
									My USER ID Is: {userId}
								</h1>
							</div>
						</div>

						<div className="my-3 w-full border-b-2 border-[#ced4da]"></div>

						<div className="grid grid-cols-1 mb-3">
							<div className="flex flex-col text-center ">
								<div
									className="protocolFields"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<span
										className="font-bold"
										style={{ padding: "0px 10px" }}
									>
										Tag:
									</span>
									<div>
										{["radio"].map((type) => (
											<div key={`Tag-inline-${type}`}>
												<Form.Check
													inline
													label="Default"
													type={type}
													id={`Tag-${type}-1`}
													checked={
														tagType === "Default"
													}
													onChange={(e) => {
														setTagType(
															e.target.checked
																? "Default"
																: ""
														);
														setTagVal("");
													}}
												/>
												<Form.Check
													inline
													label="QA"
													type={type}
													id={`Tag-${type}-2`}
													checked={tagType === "qa"}
													onChange={(e) => {
														setTagType(
															e.target.checked
																? "qa"
																: ""
														);
														setTagVal("");
													}}
												/>
												<Form.Check
													inline
													label="Custom"
													type={type}
													id={`Tag-${type}-3`}
													checked={
														tagType === "Custom"
													}
													onChange={(e) => {
														setTagType(
															e.target.checked
																? "Custom"
																: ""
														);
													}}
												/>
											</div>
										))}
									</div>
								</div>

								{tagType === "Default" || tagType === "qa" ? (
									<div
										className="protocolFields my-3"
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<span
											className="font-bold"
											style={{ padding: "0px 10px" }}
										>
											Parameters:
										</span>
										<div>
											{["radio"].map((type) => (
												<div
													key={`Parameter-inline-${type}`}
												>
													<Form.Check
														inline
														label="Default"
														type={type}
														id={`Parameter-${type}-1`}
														checked={
															parameterType ===
															"Default"
														}
														onChange={(e) => {
															setParameterType(
																e.target.checked
																	? "Default"
																	: ""
															);
															setParameterVal("");
														}}
													/>
													<Form.Check
														inline
														label="Custom"
														type={type}
														id={`Parameter-${type}-2`}
														checked={
															parameterType ===
															"custom"
														}
														onChange={(e) => {
															setParameterType(
																e.target.checked
																	? "custom"
																	: ""
															);
															setParameterVal("");
														}}
													/>
												</div>
											))}
										</div>
									</div>
								) : (
									<FloatingLabel
										controlId="floatingTextarea2"
										label=""
										className="pt-2 text-center"
									>
										<Form.Control
											as="textarea"
											placeholder="Leave a comment here"
											disabled={
												tagType === "Default" ||
												tagType === "qa"
											}
											style={{
												minHeight: "150px",
												maxWidth: "720px",
												display: "inline-block",
											}}
											onChange={(e) =>
												setTagVal(e.target.value)
											}
											value={
												tagType === "Custom"
													? tagVal
													: ""
											}
										/>
									</FloatingLabel>
								)}

								{parameterType === "custom" &&
									(tagType === "Default" ||
										tagType === "qa") && (
										<form className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4 max-w-[900px] w-full mx-auto">
											{FORM_FIELDS.map((item, i) => {
												return (
													<div className="flex items-center text-left justify-start gap-4">
														<label
															className="w-1/3"
															htmlFor={item.name}
														>
															{item.label}
														</label>
														<input
															id={item.name}
															name={item.name}
															value={
																formField[
																	item.name
																]
															}
															className="border border-solid rounded-md h-9 px-2 w-2/3"
															onChange={(e) =>
																setFormField(
																	(prev) => ({
																		...prev,
																		[e
																			.target
																			.name]:
																			e
																				.target
																				.value,
																	})
																)
															}
														/>
													</div>
												);
											})}
										</form>
									)}
							</div>
						</div>

						<div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mx-auto">
							<button
								onClick={handleRefreshClick}
								className={`flex-1 bg-[#0A1A44] text-[#fff] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center ${
									userId ? "" : " cursor-not-allowed"
								}`}
								disabled={userId ? false : true}
							>
								Refresh Ad
								<img
									src="/icons/add.svg"
									alt="icon"
									className="h-6"
									style={{ color: "#fff" }}
								/>
							</button>
						</div>
					</form>
				</div>
				{!hideIframe && (
					<div
						className="flex flex-col justify-center items-center max-w-5xl w-full mx-auto "
						style={{ margin: "50px 0px " }}
					>
						{refreshLoader ? (
							<ReactLoading
								type={"spin"}
								color={"#333"}
								height={25}
								width={25}
								style={{
									textAlign: "center",
									margin: "0 auto",
									width: "30px",
									height: "30px",
								}}
							/>
						) : tagType && tagType === "qa" ? (
							<TestAdsterraQA
								userId={userId}
								width={width}
								protocolVal={protocolVal}
								tagType={tagType}
								tagVal={tagVal}
								formField={formField}
							/>
						) : (
							<TestAdsterra
								userId={userId}
								width={width}
								protocolVal={protocolVal}
								tagType={tagType}
								tagVal={tagVal}
								formField={formField}
							/>
						)}
					</div>
				)}
			</section>
			<Footer isShowBgColor={true} />
		</div>
	);
};

export default Test;
