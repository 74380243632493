import axios from 'axios';

const withAuthInstance = axios.create(
  { headers: { 'Content-Type': 'application/json' } }
);

withAuthInstance.interceptors.request.use(
  (config) => {
    // config.headers['X-Forwarded-For'] = '127.0.0.1'; // Set the desired IP address
    // config.headers['X-Custom-Header'] = 'custom_value'; // Custom Header 
    return config;
  }, 
  (error) => console.error(error)
);

export default withAuthInstance; 