import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card } from "react-bootstrap"; 
import ReactLoading from "react-loading";

import Table from "../../components/Pagination/Table";
import { getExternalLoggerJSONOnly } from "../../actions/loggerActions";
 

const ExternalLoggerJSON = () => {
  const { loggerJsonData, loggerJsonLoader } = useSelector( (state) => state.loggerReducer );
  const dispatch = useDispatch(); 

  useEffect(() => {
    dispatch(getExternalLoggerJSONOnly());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizePerPageList = [ { text: "10", value: 10 },{ text: "20", value: 20 }, { text: "25", value: 25 }, { text: "50", value: 50 } ];

  // const onItemClick=(record)=>{
  //   console.log(`369--->record`,record);
  // }
  const columns = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
      sort: true,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      Header: "Request Data",
      accessor: "requestData",
      sort: false,
      maxWidth: 1200,
      minWidth: 900,
      width: 900,
    },
    // {
    //   Header: 'Actions',
    //   Cell: ({ row }) => (
    //     // <a href={`/profile/${row.original.id}`}>Click</a>
    //     <div onClick={()=>onItemClick(row.original)} style={{color:'blue',cursor:'pointer'}}>Click</div>
    //   ),
    //   maxWidth: 100,
    //   minWidth: 80,
    //   width: 80,
    // },
  ]; 
  
  return (
    <>
      {loggerJsonLoader ? (
        <ReactLoading type={"spin"} color={"#333"} height={25} width={25} style={{ textAlign: "center", margin: "80px 130px", width: "50px", height: "50px" }} />
      ) : (
        <>
          <Card> 
            <Card.Body>
              <Table columns={columns} data={loggerJsonData} pageSize={20} sizePerPageList={sizePerPageList} isSortable={true} pagination={true} isSearchable={false} customTableClassName={"LoggerTable"} searchBoxClass={'globalsearchbox'} />
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default ExternalLoggerJSON;