import React from 'react'
import { Table } from 'react-bootstrap'
import "./IpHistoryTable.css"
export default function IpHistoryTable(cookies) {
    const timeStampToDateTime = (timestamp) => (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timestamp));
    return (
        <div>
            <div className='history-table-title'>Your IP History</div>
            {cookies?.cookies?.ipHistory?.length > 0 ?
                <Table responsive>
                    <thead>
                        <tr>
                            <th>IP ADDRESS</th>
                            <th>TIME</th>
                            <th>LOCATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cookies?.cookies?.ipHistory?.slice(0, 10).map((e) => {
                            return (<tr>
                                <td> IPv4 : {e?.ipAddress?.IPv4}, IPv6 : {e?.ipAddress?.IPv6}</td>
                                <td>{timeStampToDateTime(e?.time)}</td>
                                <td>{e?.location}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table >
                :
                <div className='history-table-title'> NO IPs Exist</div>}
        </div>
    )
}
