import React from "react"; 
const Footer = () => {
  return (
    <footer className={`px-3`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-10 w-full mx-auto max-w-5xl">
         
      </div>
    </footer>
  );
};

export default Footer;
