import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card } from "react-bootstrap"; 
import ReactLoading from "react-loading";

import Table from "../../components/Pagination/Table";
import { getExternalLogger } from "../../actions/userAgentAction";
 

const ExternalLogger = () => {
  const { loggerData, externalloggerLoader } = useSelector( (state) => state.userAgentReducer );
  const dispatch = useDispatch(); 

  useEffect(() => {
    dispatch(getExternalLogger());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizePerPageList = [ { text: "10", value: 10 },{ text: "20", value: 20 }, { text: "25", value: 25 }, { text: "50", value: 50 } ];

  const columns = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
      sort: false,
    },
    {
      Header: "URL",
      accessor: "url",
      sort: false,
      maxWidth: 500,
      minWidth: 450,
      width: 450,
    },
    {
      Header: "User IP",
      accessor: "userIP",
      sort: false,
    },
    {
      Header: "Referrer",
      accessor: "referrer",
      sort: false,
      maxWidth: 300,
      minWidth: 180,
      width: 180,
    },
    {
      Header: "Client Type",
      accessor: "clientType",
      sort: false,
    },
    {
      Header: "Client Name",
      accessor: "clientName",
      sort: false,
    },
    {
      Header: "Device Type",
      accessor: "devicetype",
      sort: false,
    },
    {
      Header: "Device Brand",
      accessor: "deviceBrand",
      sort: false,
    },
    {
      Header: "Device Model",
      accessor: "deviceModel",
      sort: false,
    },
    {
      Header: "OS Name",
      accessor: "osName",
      sort: false,
    },
    {
      Header: "OS Version",
      accessor: "osVersion",
      sort: false,
    },
    {
      Header: "User Agent",
      accessor: "userAgent",
      sort: false,
    },
  ]; 
  
  return (
    <>
      {externalloggerLoader ? (
        <ReactLoading type={"spin"} color={"#333"} height={25} width={25} style={{ textAlign: "center", margin: "80px 130px", width: "50px", height: "50px" }} />
      ) : (
        <>
          <Card> 
            <Card.Body>
              <Table columns={columns} data={loggerData} pageSize={20} sizePerPageList={sizePerPageList} isSortable={false} pagination={true} isSearchable={false} customTableClassName={"LoggerTable"} searchBoxClass={'globalsearchbox'} />
            </Card.Body>
          </Card> 
        </>
      )}
    </>
  );
};

export default ExternalLogger;