import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Card, Col, Form, Row } from "react-bootstrap";
import { CSVLink } from "react-csv";
import ReactLoading from "react-loading";
import { useHistory,useLocation  } from 'react-router-dom';


import { getExternalLogger,getExternalLoggerPOST,resetExternalEvents } from "../../actions/userAgentAction";
import Table from "../../components/Pagination/Table";
import ResetConfirmModal from "./ResetConfirmModal";

const ExternalLoggerPost = () => {
  const [jsonData, setJsonData] = useState(''); 
  const [dialogVisible,setDialogVisible] = useState(false);
  const { loggerDataPost, externalloggerPostLoader,resetEventLoader } = useSelector(
    (state) => state.userAgentReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(getExternalLogger());
  // }, []);

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
  ];

  const columns = [
    {
      Header: "Timestamp",
      accessor: "timestamp",
      sort: false,
    },
    {
      Header: "URL",
      accessor: "url",
      sort: false,
      maxWidth: 500,
      minWidth: 450,
      width: 450,
    },
    {
      Header: "User IP",
      accessor: "userIP",
      sort: false,
    },
    {
      Header: "Referrer",
      accessor: "referrer",
      sort: false,
      maxWidth: 300,
      minWidth: 180,
      width: 180,
    },
    {
      Header: "Client Type",
      accessor: "clientType",
      sort: false,
    },
    {
      Header: "Client Name",
      accessor: "clientName",
      sort: false,
    },
    {
      Header: "Device Type",
      accessor: "devicetype",
      sort: false,
    },
    {
      Header: "Device Brand",
      accessor: "deviceBrand",
      sort: false,
    },
    {
      Header: "Device Model",
      accessor: "deviceModel",
      sort: false,
    },
    {
      Header: "OS Name",
      accessor: "osName",
      sort: false,
    },
    {
      Header: "OS Version",
      accessor: "osVersion",
      sort: false,
    },
    {
      Header: "User Agent",
      accessor: "userAgent",
      sort: false,
    },
  ];

  let hhh = [];
  let ddd = [];

  hhh = [
    { label: "Timestamp", key: "timestamp" },
    { label: "URL", key: "url" },
    { label: "User IP", key: "userIP" },
    { label: "Referrer", key: "referrer" },
    { label: "Client Type", key: "clientType" },
    { label: "Client Name", key: "clientName" },
    { label: "Device Type", key: "devicetype" },
    { label: "Device Brand", key: "deviceBrand" },
    { label: "Device Model", key: "deviceModel" },
    { label: "OS Name", key: "osName" },
    { label: "OS Version", key: "osVersion" },
    { label: "User Agent", key: "userAgent" },
  ];
  ddd = loggerDataPost;

  const resetCallbackFun = ()=>{
    dispatch(getExternalLogger());
    setDialogVisible(false);
  }
  const handleEventReset = ()=>{ 
    dispatch(resetExternalEvents(resetCallbackFun)); 
  }

  const callbackFunction=()=>{ 
    console.log(`369----->jsonData`,jsonData);
    const queryString = new URLSearchParams(JSON.stringify(jsonData)).toString();
    console.log(`369----->queryString`,queryString);
    history.push(`/?${queryString}`);
  }

  const handleSubmit =(e)=>{
    e.preventDefault();
    dispatch(getExternalLoggerPOST(callbackFunction)); 
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('jsonData', jsonData);
    history.push("/reverseevent-json");

    // history.push({ search: searchParams.toString() });
  }
  
  
  return (
    <>
      {externalloggerPostLoader ? (
        <ReactLoading
          type={"spin"}
          color={"#333"}
          height={25}
          width={25}
          style={{
            textAlign: "center",
            margin: "80px 130px",
            width: "50px",
            height: "50px",
          }}
        />
      ) : (
        <>
          <Card>
            <Card.Header>
              <div className="tableActions py-2"> 
                <Row> 
                  <Col style={{ display: "flex", justifyContent: "start" }} >
                    <Form.Control type="text" placeholder="JSON..." onChange={(e)=>setJsonData(JSON.stringify(e.target.value))} />     
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "start" }} >
                    <button type="button"  className="focus:outline-none relative z-10 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 font-medium rounded-lg text-sm px-4 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800" disabled={jsonData?false:true} onClick={handleSubmit} >
                          Submit
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="tableActions"> 
                <Col></Col>
                <Col
                  style={{ display: "flex", justifyContent: "end" }}
                  //className="gap-2"
                >
                  <div className="order-1">
                    {/* <Button color="red" className="noMargin color:red" >color red</Button>  */}
                    <button
                      type="button"
                      //onClick={()=>setDialogVisible(!dialogVisible)}
                      className="focus:outline-none relative z-10 text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-4 py-2.5 me-2 mb-2 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800"
                    >
                      Reset
                    </button>
                  </div>
                  <div className="order-2">
                    <CSVLink
                      data={ddd}
                      headers={hhh}
                      filename={"External_Logger.csv"}
                    > 
                      <button
                        type="button" 
                        className="focus:outline-none relative z-10 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 font-medium rounded-lg text-sm px-4 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                      >
                        Export to CSV
                      </button>
                    </CSVLink>
                  </div>
                </Col>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                columns={columns}
                data={loggerDataPost}
                pageSize={10}
                sizePerPageList={sizePerPageList}
                isSortable={false}
                pagination={true}
                isSearchable={true}
                customTableClassName={"LoggerTable"}
                searchBoxClass={'globalsearchbox'}
              />
            </Card.Body>
          </Card>
        
          {dialogVisible && <ResetConfirmModal title={'Reset Events'} message={'Are you sure you want to reset events?'} visible={dialogVisible} setVisible={setDialogVisible} onConfirmation={handleEventReset} loading={resetEventLoader}  /> }
        </>
      )}
    </>
  );
};

export default ExternalLoggerPost;
