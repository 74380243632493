import { useState,useRef,useEffect  } from 'react';
import './style.css'
import JsonCsVMenuBar from '../../components/JsonCsVMenuBar/JsonCsVMenuBar';
 
const JsonToCSV = () => {
    const [jsonData, setJsonData] = useState(null);
    const [error, setError] = useState(null);
    const [fileName, setFileName] = useState('');
    
    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null); 
  
    const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileName(file.name);

			const reader = new FileReader();
			reader.onload = (event) => {
				const content = event.target.result;
                
				try {
					const json = JSON.parse(content);
					setJsonData(json);
					setError(null);
				} catch (err) {
					setError("Invalid JSON file");
					setJsonData(null);
				}
			};
			reader.readAsText(file);
		}
	};


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleSaveClick = () => {
        try {
            // Function to flatten the JSON
            const flattenJson = (data, parentKey = '', result = {}) => {
                for (let key in data) {
                    const newKey = parentKey ? `${parentKey}.${key}` : key;
                    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
                        flattenJson(data[key], newKey, result);
                    } else {
                        result[newKey] = data[key];
                    }
                }
                return result;
            };

            // Function to convert JSON to CSV format
            const convertJsonToCsv = (jsonData) => {
                const flattenedData = flattenJson(jsonData);
                const csvRows = [];

                // Headers
                csvRows.push('Key,Value');

                // Data
                for (let key in flattenedData) {
                    const value = flattenedData[key].replace(/\n/g, '\\n');
                    csvRows.push(`${key},${value}`);
                }

                return csvRows.join('\n');
            };

            const csv = convertJsonToCsv(jsonData);

            // Create a Blob from the CSV string
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            // Create a temporary link element for download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName.replace(".json", "")}.csv`);

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
            cleareJsonData()
        } catch (err) {
            console.error(err);
        }

    }

    const cleareJsonData =()=>{
        setJsonData(null);
        setError(null);
        setFileName('');
        fileInputRef.current.value = '';
        textAreaRef.current.value = '';
    }

    useEffect(()=>{
        document.title='JSON To CSV';
    },[])

    return (
        <>
        <div className={`jsonUpdatePage bg-[#eef1f1] px-3 w-full flex flex-col h-screen overflow-hidden`}>
            <JsonCsVMenuBar />
            <div className='flex flex-col gap-2 flex-1'>
                {/* <h1 className='font-bold'> JSON To CSV</h1> */}
                <h2>Load JSON File {` `} : {` `} 
                    <input type="file" accept=".json" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
                    <button className='bg-[#404cf1] text-[#fff] ml-2  font-bold p-2 px-3 rounded' onClick={handleButtonClick} >Browse</button> 
                    {jsonData && <button className='bg-[#404cf1] text-[#fff] ml-2  font-bold p-2 px-3 rounded' onClick={handleSaveClick} >Save as CSV</button> }
                </h2>
                <div className='flex-1'>
                    <textarea
                        ref={textAreaRef}
                        rows={5}
                        className='bg-transparent border border-solid border-black w-full h-full p-4'
                        placeholder='Add a json code!'
                        value={jsonData ? JSON.stringify(jsonData, null, 2) : ""}
                        onChange={(e) => setJsonData(JSON.parse(e.target.value))}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </div>
        </div>
        </>
    );
};

export default JsonToCSV;
