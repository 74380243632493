import axios from "axios";
import {
	GET_AGENT_FAIL,
	GET_AGENT_SUCCESS,
	GET_AGENT_REQUEST,
	GET_USER_AGENT_LOOKUP_REQUEST,
	GET_USER_AGENT_LOOKUP_SUCCESS,
	GET_USER_AGENT_LOOKUP_FAIL,
	GET_SYSTEM_INFO_SUCCESS,
	GET_SYSTEM_INFO_FAILED,
	GET_SYSTEM_INFO_REQUEST,
	GET_EXTERNAL_LOGGER_SUCCESS,
	GET_EXTERNAL_LOGGER_FAILED,
	GET_EXTERNAL_LOGGER_REQUEST,
	RESET_EVENTS_REQUEST,
	RESET_EVENTS_SUCCESS,
	RESET_EVENTS_FAILED,
	GET_EXTERNAL_LOGGER_POST_REQUEST,
	GET_EXTERNAL_LOGGER_POST_SUCCESS,
	GET_EXTERNAL_LOGGER_POST_FAILED,
} from "../types";
import { BASE_URL } from "../config";
//import platform from "platform";
import axiosConfigXForwardIP from "./../axiosConfigXForwardIP";

export const GetUserAgentData = (payloadData, callback = () => {}) => {
	return async (dispatch) => {
		dispatch({ type: GET_AGENT_REQUEST });
		axios
			.post(`${BASE_URL}ip-lookup`, payloadData)
			.then((res) => {
				if (res.data)
					dispatch({ type: GET_AGENT_SUCCESS, payload: res.data });
				callback(res.data);
			})
			.catch((err) => {
				dispatch({ type: GET_AGENT_FAIL });
				//CustomException(err)
			});
	};
};

export const GetUserAgentLookup = (useragent) => (dispatch) => {
	const payload = { useragent: useragent };
	dispatch({ type: GET_USER_AGENT_LOOKUP_REQUEST });

	axios
		.post(`${BASE_URL}user_agent-lookup`, payload)
		.then((response) => {
			return dispatch({
				type: GET_USER_AGENT_LOOKUP_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_USER_AGENT_LOOKUP_FAIL,
				payload: error,
			});
		});
};

export const getSystemInfoAction = () => (dispatch) => {
	dispatch({ type: GET_SYSTEM_INFO_REQUEST });

	let realAgentText;
	try {
		navigator.userAgentData
			.getHighEntropyValues([
				"platform",
				"platformVersion",
				"architecture",
				"model",
				"uaFullVersion",
			])
			.then((ua) => {
				realAgentText = `Platform: ${ua.platform} / Platform Version: ${
					ua.platformVersion
				} / Architecture: ${ua.architecture} / Model: ${
					ua.model ? ua.model : `Not Found`
				} / UA Full Version: ${ua.uaFullVersion} `;
				return dispatch({
					type: GET_SYSTEM_INFO_SUCCESS,
					payload: realAgentText,
				});
			});
	} catch (e) {
		return dispatch({ type: GET_SYSTEM_INFO_FAILED, payload: "" });
	}
};

export const getExternalLogger = () => (dispatch) => {
	dispatch({ type: GET_EXTERNAL_LOGGER_REQUEST });
	axiosConfigXForwardIP
		.get("https://events.myweb3id.io/reverse_events")
		.then((response) => {
			let oldData = response.data;
			let latestData = oldData.filter(
				(obj) => Object.keys(obj).length > 1
			);
		 

			return dispatch({
				type: GET_EXTERNAL_LOGGER_SUCCESS,
				payload: latestData,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_EXTERNAL_LOGGER_FAILED,
				payload: error.data,
			});
		});
};

export const getExternalLoggerPOSTONLYAPPENDJSONINURLANDLOADDATA =
	({ callback = () => {} }) =>
	(dispatch) => {
		dispatch({ type: GET_EXTERNAL_LOGGER_POST_REQUEST });
		axiosConfigXForwardIP
			.get(`https://events.myweb3id.io/reverse_events`)
			.then((response) => {
				let oldData = response.data;
				let latestData = oldData.filter(
					(obj) => Object.keys(obj).length > 1
				);
				if (callback) {
					callback();
				}
				return dispatch({
					type: GET_EXTERNAL_LOGGER_POST_SUCCESS,
					payload: latestData,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_EXTERNAL_LOGGER_POST_FAILED,
					payload: error.data,
				});
			});
	};

export const getExternalLoggerPOST = (payload) => (dispatch) => {
	console.log(`369----->payload`, payload);
	dispatch({ type: GET_EXTERNAL_LOGGER_POST_REQUEST });
	axiosConfigXForwardIP
		.get(`https://events.myweb3id.io/reverse_events?data=${JSON.stringify(payload.jsonData)}`)
		.then((response) => {
			let oldData = response.data;
			let latestData = oldData.filter(
				(obj) => Object.keys(obj).length > 1
			);
			return dispatch({
				type: GET_EXTERNAL_LOGGER_POST_SUCCESS,
				payload: latestData,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_EXTERNAL_LOGGER_POST_FAILED,
				payload: error.data,
			});
		})
		.finally(() => {
			axiosConfigXForwardIP
				.get(`https://events.myweb3id.io/reverseevents`)
				.then((response) => { 
					// const htmlContent = response.data;
					// const blob = new Blob([htmlContent], { type: 'text/html' });
					// const url = URL.createObjectURL(blob)
				});

			//
			// const jsonBlob = new Blob([JSON.stringify(payload.jsonData, null, 2)], { type: "application/json" });

			// const url = URL.createObjectURL(jsonBlob);

			// window.open(url, "_blank");

			// setTimeout(() => {
			//     URL.revokeObjectURL(url);
			// }, 1000);
		});
};

export const resetExternalEvents =
	(callbackFun = "") =>
	(dispatch) => {
		dispatch({ type: RESET_EVENTS_REQUEST });
		axiosConfigXForwardIP
			.get("https://events.myweb3id.io/reset")
			.then((response) => {
				if (callbackFun) {
					callbackFun();
				}
				return dispatch({ type: RESET_EVENTS_SUCCESS });
			})
			.catch((error) => {
				return dispatch({
					type: RESET_EVENTS_FAILED,
					payload: error.data,
				});
			});
	};
