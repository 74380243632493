import React, { useEffect, useState } from "react";
import Menubar from "../../components/Menubar/Menubar";
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetUserAgentLookup, getSystemInfoAction } from "../../actions/userAgentAction";
import ReactLoading from "react-loading";

//css
import "./UserAgent.css";

// image
import AdPlacement from "../../components/AdPlacement/AdPlacement";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-scroll";

const UserAgent = () => {
  const dispatch = useDispatch();
  const { lookupAgentData,readUserAgentSystemInfo,systemInfoLoader } = useSelector(
    (state) => state.userAgentReducer
  );
  const [Error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [showAgentLookupTable, setshowAgentLookupTable] = useState(false);
  const [agentData, setAgentData] = useState({ // eslint-disable-line no-unused-vars
    useragent: window.navigator.userAgent,
    platform: window.navigator.platform,
    browser: "",
    screenSizeWidth: window.screen.availWidth,
    screenSizeHeight: window.screen.availHeight,
    cookie: window.navigator.cookieEnabled ? "Enabled" : "Disabled",
    javascript: window.navigator.javaEnabled ? "Enabled" : "Disabled",
  });
  const [UserAgentString, setUserAgentString] = useState("");
  const [realUserAgentVisible, setRealUserAgentVisible] = useState(false);

  const getBrowserName = () => {
    if (agentData?.useragent?.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (agentData?.useragent?.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (
      agentData?.useragent?.includes("Opera") ||
      agentData?.useragent?.includes("OPR")
    ) {
      return "Opera";
    } else if (agentData?.useragent?.includes("Trident")) {
      return "Microsoft Internet Explorer";
    } else if (agentData?.useragent?.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (agentData?.useragent?.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (agentData?.useragent?.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (agentData?.useragent?.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }
  };
  const submitHandler = () => {
    if (!UserAgentString) {
      setShowError(true);
      setError(" Please enter a valid String...!");
      setshowAgentLookupTable(false);
    } else {
      dispatch(GetUserAgentLookup(UserAgentString));
      setshowAgentLookupTable(true);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isCopied]);

  const handleShowRealAgent= async()=>{ 
    if(readUserAgentSystemInfo){
      setRealUserAgentVisible(!realUserAgentVisible);
    }else{
      await dispatch(getSystemInfoAction())
      await setRealUserAgentVisible(!realUserAgentVisible);
    } 
  }
  return (
    <div>
      <Menubar />

      <section className="p-3">
        <div className="flex flex-col gap-2 items-center w-full max-w-5xl mx-auto">
          <img
            src="/images/user-agent.svg"
            alt="user-agent"
            className="w-40 mt-10"
          />
          <div className="uppercase text-center font-lg font-semibold text-[#00A1C4]">
            USER AGENT
          </div>
          <p className="max-w-md text-[#4A5571]">
            Discover what your user agent displays as the websites you visit
            during your browsing session using our user agent lookup tool.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 border-y mt-4 border-x border-[#6FA5AE] rounded-lg overflow-hidden">
            <div className="flex flex-row gap-3 items-center p-3 bg-[#D6EAEC]">
              <div className="text-[#0A1A44]">
                <div className="uppercase text-lg font-semibold py-2 ">
                  Your User Agent is:
                </div>
                <div>{agentData?.useragent}</div>
              </div>
              <div className="border-r h-full border-[#6FA5AE]"></div>
              <div>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip
                      id={`tooltip-top`}
                      style={{ width: "150px", textAlign: "center" }}
                    >
                      {isCopied ? "Copied!" : "Copy to clipboard"}
                    </Tooltip>
                  }
                >
                  <img
                    src="/icons/copy.svg"
                    alt="icon"
                    className="w-12 cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(agentData?.useragent);
                      setCopied(true);
                    }}
                  />
                </OverlayTrigger>
              </div>
            </div>
            <div className="col-span-1 md:col-span-2">
              <table className="w-full border-t md:border-t-0 md:border-l border-[#6FA5AE]">
                <tbody>
                  <tr>
                    <td className="py-2 border-b text-center font-semibold">
                      Platform
                    </td>
                    <td className="py-2 border-b text-center border-l border-[#6FA5AE]">
                      {agentData?.platform}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
                      Browser
                    </td>
                    <td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
                      {getBrowserName()}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 border-b text-center font-semibold">
                      Screen Size
                    </td>
                    <td className="py-2 border-b text-center border-l border-[#6FA5AE]">
                      {agentData?.screenSizeWidth} *{" "}
                      {agentData?.screenSizeHeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
                      Cookie
                    </td>
                    <td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
                      {agentData?.cookie}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-center font-semibold">
                      Javascript
                    </td>
                    <td className="py-2 text-center border-l border-[#6FA5AE]">
                      {agentData?.javascript}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div className="flex flex-col gap-4 items-center p-2 w-full mt-1  ">
            <Button variant="info uppercase" style={{backgroundColor:'#01B1BD'}} onClick={handleShowRealAgent}>{realUserAgentVisible?'Hide':'Show'} MY REAL USER AGENT</Button>
            {systemInfoLoader && <ReactLoading type={'spin'} color="#01B1BD" /> }
            {realUserAgentVisible && !systemInfoLoader && 
            (
            <div className="grid  border-y mt-1 border-x border-[#6FA5AE]  overflow-hidden">
              <div className="flex flex-row gap-3 items-center p-3 bg-[#D6EAEC]">
                <div className="text-[#0A1A44]">
                  <div className="uppercase text-lg font-semibold py-2">
                    Your Real User Agent Is:
                  </div>
                  <div>{readUserAgentSystemInfo}</div>
                </div>
                <div className="text-[#0A1A44]">
                  <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip
                          id={`tooltip-top`}
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          {isCopied ? "Copied!" : "Copy to clipboard"}
                        </Tooltip>
                      }
                    >
                      <img
                        src="/icons/copy.svg"
                        alt="icon"
                        className="w-12 cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(readUserAgentSystemInfo);
                          setCopied(true);
                        }}
                      />
                    </OverlayTrigger>
                </div> 
              </div> 
            </div>
            )}

          </div>

         
          <div className="flex flex-col gap-4 items-center border-y border-x border-[#0A1A44] rounded-lg p-2 w-full mt-5 py-4">
            <div className="uppercase text-lg font-semibold text-[#0A1A44]">
              User Agent Lookup
            </div>
            <p className="max-w-md text-[#4A5571]">
              Do you have a User Agent string that you want to lookup? The tool
              below will parse the User String, and provide you with the
              platform, operating system, and browser.
            </p>
            <div className="pt-2" id="input2">
              <div className="flex flex-row gap-3 rounded-lg bg-[#C2EFF3] px-3 py-2 my-4">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                  }}
                >
                  <input
                    type="text"
                    className="bg-transparent border-none outline-none"
                    placeholder="Search"
                    onChange={(e) => {
                      setUserAgentString(e.target.value);
                      setShowError(false);
                    }}
                  />
                </form>
                <Link
                  to="input2"
                  spy={true}
                  smooth={true}
                  duration={0}
                  delay={0}
                >
                  <img
                    className="cursor-pointer"
                    src="/icons/search.svg"
                    alt="icon"
                    onClick={() => submitHandler()}
                  />
                </Link>
              </div>
            </div>
          </div>
          <AdPlacement />
          <div id="result" className="w-full">
            {showError ? (
              <h4
                className="error-message"
                style={{ textAlign: "center", color: "red" }}
              >
                {Error}
              </h4>
            ) : (
              ""
            )}
            {showAgentLookupTable ? (
              <div className="w-full max-w-xl mx-auto rounded-lg border-x border-y border-[#6FA5AE] my-10">
                <table className="w-full table-fixed">
                  <tbody>
                    <tr>
                      <td className="py-2 border-b text-center font-semibold">
                        User Agent
                      </td>
                      <td className="py-2 border-b text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.user_agent}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
                        Platform
                      </td>
                      <td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.platform}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 border-b text-center font-semibold">
                        Browser
                      </td>
                      <td className="py-2 border-b text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.browser}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
                        Version
                      </td>
                      <td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.version}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 border-b text-center font-semibold">
                        Operating System
                      </td>
                      <td className="py-2 border-b text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.operating_system}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 bg-[#D6EAEC] text-center font-semibold">
                        Screen Size
                      </td>
                      <td className="py-2 bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
                        {lookupAgentData?.screen_size}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              What is a User Agent and Why Should Professionals Care?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-1.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              The term "User-Agent" is one you may not have heard before. It has
              everything to do with how users access the internet and browse
              websites, but it also has implications for professional web
              developers and designers.
              <br />
              <br />
              Let's take a closer look at what a user agent is and why
              professionals should care.
            </div>
          </div>
          <img
            src="/images/useragent/user-agent-page-img-1.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/useragent/user-agent-page-img-2.jpg"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              What is a User Agent?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-2.jpg"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              A user agent (UA) is a software program that allows users to
              access the internet, including websites, applications, search
              engines, and more. It allows users to interact with their digital
              environment by providing information about the device they are
              using and its capabilities.
              <br />
              <br />
              Examples of user agents include browsers like Chrome or Firefox;
              email programs such as Outlook or Gmail; operating systems like
              Windows, Mac OS, iOS, or Android; bots that collect data for
              search engine optimization (SEO); and desktop applications such as
              Adobe Creative Suite.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              How Can Professionals Benefit from Understanding User Agents and
              Their Capabilities?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-3.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              A thorough understanding of user agents and their capabilities can
              provide a great benefit to professionals. When working with
              content delivery networks, analytics engines, or web browsers,
              having the knowledge to develop content in accordance with the
              specific needs, rules, and available features of different user
              agents helps to ensure that each individual agent's experience is
              optimized.
              <br />
              <br />
              For instance, recognizing what devices or browsers can support
              media extensions can help to create more interactive content while
              understanding the limitations of some web crawlers can better
              inform how websites are structured for events such as indexing.
              <br />
              <br />
              Moreover, professionals who have an understanding of user agents
              have an advantage when it comes to building accessible interfaces;
              not only do they know what technical specifications need to be met
              for successful execution, but they are also more aware of how
              people interact with different types of agents and therefore can
              craft even more inclusive applications.
            </div>
          </div>
          <img
            src="/images/useragent/user-agent-page-img-3.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/useragent/user-agent-page-img-4.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              What are Some of the Most Popular User Agents on the Market Today,
              and Why are they So Popular?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-4.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Today, user agents have become an essential part of the internet
              landscape. Perhaps the most popular user agent is Google Chrome, a
              web browser created by Google that runs on desktop and mobile
              devices. It is popular due to its streamlined and user-friendly
              interface, as well as its support for rich media content.
              <br />
              <br />
              Additionally, Chrome has integrated features such as history sync
              and autofill that make it very attractive for users who want to
              access their data from multiple devices simultaneously. Other
              popular user agents include Apple Safari, Mozilla Firefox,
              Microsoft Internet Explorer, and Microsoft Edge. These are also
              popular because of their ability to provide comprehensive browsing
              experiences for both basic and complex tasks.
              <br />
              <br />
              Each offers great security features along with customizable
              preferences, allowing users to control their own experience within
              these browsers.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              How Can Professionals Make Sure that their Website or Application
              is Optimized for Different User Agents and Devices?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-5.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Ensuring that a website or application is optimized for different
              user agents and devices is critical for all professionals looking
              to create digital products. There are several techniques that can
              be employed to make sure the user experience of each visitor is
              top-notch, no matter which device they are using or what software
              agent they have installed.
              <br />
              <br />
              First and foremost, webmasters should use tools like the Google
              Search Console to provide feedback on how the website is
              performing, then take action based on the insights it provides.
              Similarly, developers need to invest time in mobile optimization
              strategies, including testing their site with various screen
              resolutions and network speeds.
              <br />
              <br />
              Finally, all website owners should consider implementing hosted
              chat services so as to communicate better with their end users. By
              following these steps, professionals can be assured they have made
              all necessary efforts to ensure their websites and applications
              are optimized for any user and device - ultimately leading to a
              better overall experience.
            </div>
          </div>
          <img
            src="/images/useragent/user-agent-page-img-5.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/useragent/user-agent-page-img-6.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              Are There Any Risks Associated with Using Certain User Agents, and
              How Can These be Avoided or Mitigated?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-6.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Although user agents can be a great way to quickly access digital
              information, there are potential risks associated with their use.
              As user agents often store and make use of personal or sensitive
              data, bad actors may try to exploit vulnerabilities in the user
              agent. To help mitigate this risk, users should always ensure that
              their user agents are up-to-date and avoid using unofficial or
              outdated versions that may contain security flaws.
              <br />
              <br />
              Additionally, it is important to be aware of which websites are
              attempting to gain access to an individual's browsing activity
              through the use of user agents, as malicious actors may also be
              attempting to track users' online activity without their
              knowledge. By carefully managing their user agent usage and
              staying aware of security risks, individuals can greatly reduce
              the chances of compromising their own privacy or safety when using
              this technology.
              <br />
              <br />
              If an investigation is needed, it would be beneficial to find
              other witnesses or evidence regarding the illegal usage and
              provide them with relevant information about what happened. The
              quicker you act on this situation, the more chances of catching
              the perpetrator before they cause more harm with your IP address.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">
              What Are Some Best Practices for Working with User Agents in a
              Professional Setting?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-7.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              Working with user agents in a professional setting requires the
              utmost respect and etiquette. It is important to remember that
              user agents may have different levels of technical knowledge than
              you and to communicate accordingly.
              <br />
              <br />
              First, always take their problems seriously, listen intently, and
              allow them to explain their concerns as much as needed until your
              mutual understanding is reached. Additionally, try not to pressure
              them into decisions they are not ready for, always provide honest
              feedback about the capacities of your system and acknowledge that
              it might not meet all of their needs.
              <br />
              <br />
              Furthermore, show appreciation for their service when you can and
              strive to resolve any issues within the timeframe promised.
              Finally, keep good records in case a problem arises after you've
              worked together. Following these best practices will help build
              long-term working relationships with user agents while ensuring
              satisfaction on both sides.
            </div>
          </div>
          <img
            src="/images/useragent/user-agent-page-img-7.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
        </div>
      </section>
      <section className="px-3 py-20 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <img
            src="/images/useragent/user-agent-page-img-8.png"
            alt="home"
            className="rounded-xl hidden md:block"
          />
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold text-[#0A1A44]">
              Why Should Professionals Care About User Agents?
            </div>
            <img
              src="/images/useragent/user-agent-page-img-8.png"
              alt="home"
              className="rounded-xl md:hidden my-3"
            />
            <div>
              User agents are an important part of website optimization because
              they allow developers to understand who their visitors are and how
              they access the content. Knowing this information can help with
              decisions like making sure that the website works properly on all
              devices (i.e., mobile phones versus laptops).
              <br />
              <br />
              It can also be helpful in understanding how people navigate your
              website so that you can make changes accordingly. For example, if
              you notice that your visitors tend to click on certain links more
              than others, you might want to move those links higher up on the
              page for easier access.
              <br />
              <br />
              Additionally, user agents can be used to detect malicious activity
              or suspicious behavior so that your site remains secure from
              hackers and other cybercriminals.
            </div>
          </div>
        </div>
      </section>
      <section className="px-3 py-20 bg-[#B3C6CB]  md:hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
          <div className="flex flex-col gap-2">
            <div className="uppercase text-3xl font-semibold">Conclusion</div>
            <div>
              User agents are an essential part of any professional web designer
              or developer's toolkit because they provide valuable insights into
              their customers' behaviors online. They allow developers to
              understand who their visitors are, how they access content, and
              what kind of device they use so that websites can be optimized for
              maximum performance across multiple platforms.
              <br />
              <br />
              With user agents playing such an important role in website
              security and optimization, having an understanding of them is key
              for any professional looking to stay ahead of the game when it
              comes to creating successful web designs! you.
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UserAgent;
