import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const ResetConfirmModal = ({ title,message, visible, setVisible, onConfirmation, loader }) => {
  return (
    <>
      <Modal show={visible} onHide={() => setVisible(false)} centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {title?title:'Title'}
          </Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => setVisible(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i className="uil-no-entry text-warning display-3"></i>

          <p className="w-75 mx-auto text-muted"> 
            {message?message:'Are you sure want to continue?'}
          </p>
          <div className="mt-4">
            <button
              onClick={() => setVisible(false)}
              className="focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
            >
              Cancel
            </button>
            <button
              onClick={() => onConfirmation()}
              disabled={loader}
              className="focus:outline-none text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800"
            >
              {" "}
              {loader ? (
                <>
                  {" "}
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetConfirmModal;
