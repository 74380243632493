import React from "react";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Menubar/Menubar";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[rgb(20,33,64)] w-full">
      <Menubar />
      <main className="flex flex-col  text-white w-full p-3 max-w-5xl mx-auto">
        <h1 className="text-center text-3xl mb-4 mt-8">Privacy Policy</h1>
        <p className="mb-4 font-light">
          MYIPINFO.IO will refer to itself as "Provider," "We," or "Us" in this
          Privacy Policy. We want to be transparent about how we collect and use
          your Personal Information, which includes information that can
          directly or indirectly identify you such as your name, address,
          telephone number, email address, and date of birth. Our Privacy Policy
          outlines how we handle your Personal Information. Note that this
          policy may change in the future as legal, technology, and operational
          factors change, and any updates will be posted on our Website.
        </p>
        <h3 className="font-semibold text-lg">Information Collection</h3>
        <p className="mb-4 font-light">
          We collect Personal Information through various means, such as: Our
          Website sign-up/registration process (in the future). Providing
          support to registered account holders (in the future). We also collect
          other information that cannot identify you unless combined with your
          Personal Information. This "Other Information" includes your Public
          IPv7 and IPv4 addresses, location, and your Internet Service
          Provider's identity.
        </p>
        <p className="mb-4 font-light">
          We may also store information when you visit our Website, such as your
          IP address, to diagnose issues with our server, improve our Website,
          and for other purposes. Any information you voluntarily provide to us
          will be used to customize your Website experience, respond to your
          requests and comments, and improve our Website.
        </p>
        <p className="mb-4 font-light">
          We may perform statistical analyses of user behavior and
          characteristics to measure the popularity of different areas of our
          Website and inform advertisers of this information. However, we will
          only provide aggregated data from these analyses to third parties.
        </p>
        <p className="mb-4 font-light">
          Please be aware that when you voluntarily provide Personal Information
          on our Website, it may be subject to unauthorized collection by third
          parties, which is beyond our control. To safeguard your Personal
          Information, we take standard precautions, but standard web server
          technology does not encrypt or otherwise secure the information you
          provide.
        </p>
        <p className="mb-4 font-light">
          We may employ agents to fulfill functions on our behalf, such as
          marketing, data analysis, content maintenance, payment processing, and
          customer service. These agents will have access to user information as
          required to perform their functions, but they are not allowed to use
          that information for any other purposes.
        </p>
        <h3 className="font-semibold text-lg">Age of Majority Requirement</h3>
        <p className="mb-4 font-light">
          Individuals who are below the age of majority in their respective
          jurisdictions must obtain permission from their parents or legal
          guardians before providing any personal information to us through the
          Website or to third-party payment processors.
        </p>
        <h3 className="font-semibold text-lg">Personal Information Use</h3>
        <p className="mb-4 font-light">
          We may use your email address to keep you updated on news, updates,
          and information related to the Website, our services, and the sale of
          our domain name inventory. We will also use it to respond to customer
          service inquiries and comments. You have the option to opt out of
          receiving promotional emails from us at any time. If you do not wish
          to receive notifications of special offers or updates to our site(s),
          please reach out to us as indicated below.
        </p>
        <h3 className="font-semibold text-lg">Cookie and Web Beacon Usage</h3>
        <p className="mb-4 font-light">
          Our Website may automatically collect non-personal data through the
          standard operation of our internet servers or through the use of
          cookies. Cookies are small text files that recognize repeat users and
          allow us to track usage behavior, improve content, and display
          targeted advertising. However, we do not control the use of cookies or
          the information collected by advertisers or third parties hosting data
          for us. If you do not wish for your information to be collected
          through cookies, you can deny or accept the cookie feature through
          your browser settings. Please note that cookies may be necessary to
          provide certain features on the Website.
        </p>
        <p className="mb-4 font-light">
          Google, as a third-party vendor, uses cookies to display ads on our
          Website. The DART cookie allows Google to show ads based on your
          visits to our Website and other internet sites. You have the option to
          opt out of the DART cookie by visiting the Google ad and content
          network Privacy Policy. Our Website also features advertisements from
          third parties. Some of these advertisers may use technology such as
          cookies and web beacons to collect information, including your IP
          address, ISP, and browser information. This information is generally
          used for geo-targeting and showing specific ads based on your site
          visits. You can choose to disable or turn off cookies in your browser
          settings, but this may affect your ability to interact with our
          Website and other websites. This could include not being able to log
          in to services or programs.
        </p>
        <h3 className="font-semibold text-lg">Log Files Collection</h3>
        <p className="mb-4 font-light">
          We, like most websites, gather and utilize the information stored in
          log files. This information may include your IP address, Internet
          Service Provider, the browser used to access our website, the time of
          visit, and the pages viewed during the visit.
        </p>
        <h3 className="font-semibold text-lg">Transfer of Customer Data</h3>
        <p className="mb-4 font-light">
          Customer data is a valuable business asset, and in the event of a
          transfer or divestment of our business assets, customer data may also
          be transferred as part of these assets.
        </p>
        <h3 className="font-semibold text-lg">Information Release</h3>
        <p className="mb-4 font-light">
          If required by law, we will release customer data to the relevant
          authorities. Additionally, we may release such data if we feel it is
          necessary to protect our rights, those of our customers, affiliates,
          partners, or any other party, as well as to prevent fraud and credit
          risk.
        </p>
        <h3 className="font-semibold text-lg">External Websites</h3>
        <p className="mb-4 font-light">
          The website may contain links to external sites that are not governed
          by our Privacy Policy. We are not responsible for the collection, use,
          or disclosure of personal information by these organizations or
          websites. When submitting personal information on these sites, we
          recommend reading their privacy policy.
        </p>
        <h3 className="font-semibold text-lg">API Use</h3>
        <p className="mb-4 font-light">
          If you have registered for our API, you may collect and process
          personal information through its use with or for third parties. In
          this case, you must comply with any relevant laws or regulations
          regarding the collection and use of this information.
        </p>
        <h3 className="font-semibold text-lg">Consent for Data Collection</h3>
        <p className="mb-4 font-light">
          Consent for the collection, use, and disclosure of personal
          information can be given in various ways. Express consent can be given
          orally, electronically, or through a submitted form. Implied consent
          may be given when providing information for a requested service or
          when notice has been provided about our intentions with regards to
          personal information and consent has not been withdrawn. Consent can
          also be given by an authorized representative. By providing us with
          personal information, we assume that you have consented to our
          collection, use, and disclosure of the information for the specified
          purposes or as described at the time of collection. You can withdraw
          your consent at any time, with prior notice, subject to contractual
          and legal restrictions. Withdrawal of consent may result in us being
          unable to provide certain services.
        </p>
        <p className="mb-4 font-light">
          We do not collect, use, or disclose personal information other than as
          described in this Privacy Policy without your consent, unless allowed
          by law. We may be required by law to collect, use, or disclose
          personal information without consent, for example, in response to a
          court order or to comply with regulations.
        </p>
        <h3 className="font-semibold text-lg">
          Data Protection under the GDPR
        </h3>
        <p className="mb-4 font-light">
          If you are a resident of the European Economic Area (EEA), you have
          the following data protection rights:
          <br />
          • You can access, correct, update, or request the deletion of your
          personal information at any time by contacting us.
          <br />
          • You can also object to the processing of your personal information,
          request restriction of processing, or request portability of your
          personal information by contacting us.
          <br />
          • You have the right to opt-out of marketing communications at any
          time by clicking the "unsubscribe" or "opt-out" link in marketing
          emails or by contacting us for other forms of marketing.
          <br />
          • If your personal information was collected and processed with your
          consent, you have the right to withdraw your consent at any time.
          <br />
          • You have the right to file a complaint with a data protection
          authority regarding our collection and use of your personal
          information.
          <br />
          We will respond to all requests from individuals exercising their data
          protection rights in accordance with applicable data protection laws.
          To extend GDPR-mandated privacy requirements to you, you may be
          required to identify yourself as a person subject to the GDPR. If you
          are a resident of the EEA and believe we maintain your personal data
          subject to the GDPR, you can find more information and the complaint
          procedure at https://edps.europa
        </p>
        <h3 className="font-semibold text-lg">
          Your CCPA Data Protection Rights
        </h3>
        <p className="mb-4 font-light">
          As a California resident, you are entitled to the following rights
          under this Privacy Policy and by law:
          <br />
          Right to Notice - You have the right to be informed about the
          categories of Personal Data that are being collected and the reasons
          for their use.
          <br />
          Right to Access - You have the right to request and receive
          information about the disclosure of your Personal Data collected by
          the Company or its subsidiaries in the past 12 months to third parties
          for direct marketing purposes.
          <br />
          Right to Opt Out of Data Sale - You have the right to ask the Company
          not to sell your Personal Data to third parties. You can submit this
          request by visiting our "Do Not Sell My Personal Information" section
          or web page.
          <br />
          Right to Know - You have the right to request and receive information
          about the categories of Personal Data collected, the sources of
          collection, the business or commercial purposes for collecting and
          selling the Personal Data, the third parties with whom the Personal
          Data is shared, and the specific pieces of Personal Data collected
          about you.
          <br />
          Right to Delete - You have the right to request the deletion of your
          Personal Data that has been collected in the past 12 months.
          <br />
          Right to Non-Discrimination - You have the right not to be
          discriminated against for exercising your rights, including being
          denied goods or services, being charged different prices or rates for
          goods or services, receiving a different level or quality of goods or
          services, or being suggested to receive a different price or rate for
          goods or services.
        </p>
        <h3 className="font-semibold text-lg">Exercising Your CCPA Rights</h3>
        <p className="mb-4 font-light">
          If you are a California resident, you can contact the Company to
          exercise any of your rights under the CCPA. The Company will provide
          the required information free of charge within 45 days of receiving
          your request, which may be extended by an additional 45 days if
          necessary and with prior notice.
        </p>
        <h3 className="font-semibold text-lg">
          Do Not Sell My Personal Information
        </h3>
        <p className="mb-4 font-light">
          The Company does not sell personal information, but our Service
          Providers (such as advertising partners) may use technology that is
          considered as "selling" personal information according to CCPA law. If
          you wish to opt out of interest-based advertising and potential data
          sales under CCPA, you can do so by following the instructions
          provided. Please note that the opt-out is only applicable to the
          browser you use and you may need to opt-out on every browser you use.
        </p>
        <h3 className="font-semibold text-lg">How to Contact Us</h3>
        <p className="mb-4 font-light">
          If you have any questions about this privacy statement, the practices
          of this site, or your dealings with the Website, you can write via
          snail mail at our above-noted mailing address. Any dispute arising
          between you and us with respect to privacy is subject to any
          applicable terms and conditions of use of the Website, and this
          Privacy Policy, including limitations on damages, arbitration, and
          jurisdiction. The foregoing policies are effective as of February 1,
          2023. This statement and the policies outlined herein are not intended
          to and do not create any contractual or other legal rights in or on
          behalf of any party.
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
