import React, { useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import filterStore from "../store/zustand/filter.store";

const Searchbar = ({ onSearchChange }) => {
  const [value, setValue] = useState("");
  const debouncedVal = useDebounce(value, 300);

	// Zustand Store
	const { search, setSearch } = filterStore();

  useEffect(() => {
    onSearchChange?.(debouncedVal);
		setSearch(debouncedVal);
  }, [debouncedVal]);

	useEffect(() => {
		setValue(search);
	}, [search]);

	return (
		<span className="flex items-center gap-2">
			Search :
			<input
				type="search"
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				className="form-control w-auto ms-1 color-4b4b5a"
			/>
		</span>
	);
};

export default Searchbar;
