import React from "react";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Menubar/Menubar";

const TermsAndCondition = () => {
  return (
    <div className="bg-[rgb(20,33,64)] w-full">
      <Menubar />
      <main className="flex flex-col  text-white w-full p-3 max-w-5xl mx-auto">
        <h1 className="text-center text-3xl mt-8">
          Terms And Conditions Of Use
        </h1>
        <h2 className="text-center text-2xl mb-4">MYIPINFO.IO</h2>
        <p className="mb-4 font-light">
          This document constitutes the agreement between you and MYIPINFO.IO,
          the owner and operator of the IP address search website (the
          "Website"). By accessing and using the Website, you agree to be bound
          by these terms and conditions, as well as any additional terms to
          which you agree when using certain aspects of the Website.
        </p>
        <h3 className="font-semibold text-lg">Use of Website</h3>
        <p className="mb-4 font-light">
          The Website is intended for your personal and non-commercial use. You
          are authorized to use the Website only for lawful purposes and in
          accordance with these terms and conditions. Any use of the Website
          that is in violation of any applicable laws, regulations, or these
          terms and conditions is strictly prohibited.
        </p>
        <h3 className="font-semibold text-lg">Intellectual Property</h3>
        <p className="mb-4 font-light">
          The Website and its entire contents, features, and functionality,
          including all information, software, text, displays, images, video,
          and audio, and the design, selection, and arrangement thereof, are the
          exclusive property of MYIPINFO.IO or its licensors and are protected
          by United States and international copyright, trademark, patent, trade
          secret, and other intellectual property or proprietary rights laws.
          These rights are reserved and any unauthorized use may violate these
          laws.
        </p>

        <h3 className="font-semibold text-lg">Limitations on Use</h3>
        <p className="mb-4 font-light">
          You agree not to use the Website to:
          <br />
          Harass, abuse, or harm another person.
          <br />
          Provide false or misleading information.
          <br />
          Engage in any activity that interferes with or disrupts the Website
          (or the servers and networks that are connected to the Website).
          <br />
          Modify, adapt, translate, or reverse engineer any portion of the
          Website.
          <br />
          Remove any copyright, trademark, or other proprietary rights notices
          from the Website.
          <br />
          Reproduce, duplicate, copy, sell, trade, or resell any portion of the
          Website for any commercial purpose.
          <br />
        </p>
        <h3 className="font-semibold text-lg">Disclaimer of Warranties</h3>
        <p className="mb-4 font-light">
          The Website is provided on an "as is" and "as available" basis.
          MYIPINFO.IO makes no representations or warranties of any kind,
          express or implied, as to the operation of the Website or the
          information, content, materials, or products included on the Website.
          You expressly agree that your use of the Website is at your sole risk.
        </p>
        <h3 className="font-semibold text-lg">Limitation of Liability</h3>
        <p className="mb-4 font-light">
          MYIPINFO.IO shall not be liable for any damages of any kind arising
          from the use of the Website, including, but not limited to, direct,
          indirect, incidental, punitive, and consequential damages.
        </p>
        <h3 className="font-semibold text-lg">Indemnification</h3>
        <p className="mb-4 font-light">
          You agree to defend, indemnify, and hold harmless MYIPINFO.IO, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, agents, licensors,
          suppliers, successors, assigns, content providers, and other
          contractors from and against any claims, liabilities, damages,
          judgments, awards, losses, costs, expenses, or fees (including
          reasonable attorneys' fees) arising out of or relating to your
          violation of these terms and conditions or your use of the Website.
        </p>
        <h3 className="font-semibold text-lg">
          Changes to Terms and Conditions
        </h3>
        <p className="mb-4 font-light">
          MYIPINFO.IO reserves the right, in its sole discretion, to modify
          these terms and conditions at any time by posting the revised terms on
          the Website. Your continued use of the Website following the posting
          of changes to these terms will mean you accept those changes.
        </p>
        <h3 className="font-semibold text-lg">Governing Law</h3>
        <p className="mb-4 font-light">
          These terms and conditions are governed by and construed in accordance
          with the laws of the State of MYIPINFO.IO and the laws of the United
          States, without giving effect to any conflicts of law principles.
        </p>
        <h3 className="font-semibold text-lg">Dispute Resolution</h3>
        <p className="mb-4 font-light">
          Any dispute arising out of or relating to these terms and conditions
          or the Website shall be resolved through binding arbitration in
          accordance with the commercial arbitration rules
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
