import React, { useRef, useEffect, forwardRef, useState } from "react";
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter, useExpanded } from "react-table";
import classNames from "classnames";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";

// components
import Pagination from "../Pagination/Pagination";
import { getExternalLogger } from "../../actions/userAgentAction";
import filterStore from "../../store/zustand/filter.store";
import Excludebar from "../Excludebar";
import Searchbar from "../Searchbar";
import { CSVLink } from "react-csv";

// Define a default UI for filtering
// const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchBoxClass }) => {
// 	const count = preGlobalFilteredRows.length;
// 	const [value, setValue] = useState(globalFilter);

// 	const onChange = useAsyncDebounce((value) => {
// 		setGlobalFilter(value || undefined);
// 	}, 200);

// 	return (
// 		<div className={classNames(searchBoxClass)}>
// 			<span className="d-flex align-items-center color-4b4b5a">
// 				Search :{" "}
// 				<input
// 					type="search"
// 					value={value || ""}
// 					onChange={(e) => {
// 						setValue(e.target.value);
// 						onChange(e.target.value);
// 					}}
// 					placeholder={`${count} records...`}
// 					className="form-control w-auto ms-1 color-4b4b5a"
// 				/>
// 			</span>
// 		</div>
// 	);
// };

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<div className="form-check">
				<input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
				<label htmlFor="form-check-input" className="form-check-label"></label>
			</div>
		</>
	);
});

const Table = (props) => {
	const webWorker = new Worker(new URL("../../worker.js", import.meta.url));
	const dispatch = useDispatch();
	const isSearchable = props["isSearchable"] || false;
	const isSortable = props["isSortable"] || false;
	const pagination = props["pagination"] || false;
	const isSelectable = props["isSelectable"] || false;
	const isExpandable = props["isExpandable"] || false;
	const sizePerPageList = props["sizePerPageList"] || [];
	const { externalloggerLoader, loggerData } = useSelector((state) => state.userAgentReducer);
	const [filteredData, setFilteredData] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false);

	// filter Store
	const { excludeValue, search } = filterStore();

	let otherProps = {};

	if (isSearchable) {
		otherProps["useGlobalFilter"] = useGlobalFilter;
	}
	if (isSortable) {
		otherProps["useSortBy"] = useSortBy;
	}
	if (isExpandable) {
		otherProps["useExpanded"] = useExpanded;
	}
	if (pagination) {
		otherProps["usePagination"] = usePagination;
	}
	if (isSelectable) {
		otherProps["useRowSelect"] = useRowSelect;
	}

	const dataTable = useTable(
		{
			columns: props["columns"],
			data: filteredData,
			initialState: { pageSize: props["pageSize"] || 10, ...props["initialState"] },
		},
		otherProps.hasOwnProperty("useGlobalFilter") && otherProps["useGlobalFilter"],
		otherProps.hasOwnProperty("useSortBy") && otherProps["useSortBy"],
		otherProps.hasOwnProperty("useExpanded") && otherProps["useExpanded"],
		otherProps.hasOwnProperty("usePagination") && otherProps["usePagination"],
		otherProps.hasOwnProperty("useRowSelect") && otherProps["useRowSelect"],
		(hooks) => {
			isSelectable &&
				hooks.visibleColumns.push((columns) => [
					// Let's make a column for selection
					{
						id: "selection",
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllPageRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				]);

			isExpandable &&
				hooks.visibleColumns.push((columns) => [
					// Let's make a column for selection
					{
						// Build our expander column
						id: "expander", // Make sure it has an ID
						Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? "-" : "+"}</span>,
						Cell: ({ row }) =>
							// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
							// to build the toggle for expanding a row
							row.canExpand ? (
								<span
									{...row.getToggleRowExpandedProps({
										style: {
											// We can even use the row.depth property
											// and paddingLeft to indicate the depth
											// of the row
											paddingLeft: `${row.depth * 2}rem`,
										},
									})}>
									{row.isExpanded ? "-" : "+"}
								</span>
							) : null,
					},
					...columns,
				]);
		}
	);

	let rows = pagination ? dataTable.page : dataTable.rows;

	useEffect(() => {
		setFilterLoading(true);
		if (webWorker) {
			webWorker.onmessage = (e) => {
				const { data } = e;
				setFilterLoading(false);
				setFilteredData(data);
			};

			webWorker.postMessage({ excludeValue, search, loggerData });
		}
	}, [excludeValue, search, loggerData]);

	let hhh = [];
	hhh = props["columns"]?.map((item) => {
		return { label: item["Header"], key: item["accessor"] };
	});

	return (
		<>
			{isSearchable && (
				<div className="flex gap-4 mb-4">
					<Searchbar />
					{/* <GlobalFilter preGlobalFilteredRows={dataTable.preGlobalFilteredRows} globalFilter={dataTable.state.globalFilter} setGlobalFilter={dataTable.setGlobalFilter} searchBoxClass={props["searchBoxClass"]} /> */}
					<Excludebar />
					{filterLoading && (
						<div className="flex items-center justify-center">
							<div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin"></div>
						</div>
					)}
					<div className="ml-auto flex items-center gap-2">
						<p className="text-gray-500">{dataTable.preGlobalFilteredRows.length} Records</p>
						<button
							type="button"
							className="focus:outline-none relative z-10 text-white bg-sky-400 hover:bg-sky-600 focus:ring-4 focus:ring-blue-100 font-bold rounded-lg text-sm px-4 py-2.5 dark:bg-blue-300 dark:hover:bg-blue-300 dark:focus:ring-blue-800"
							onClick={() => {
								dispatch(getExternalLogger(excludeValue, search));
							}}>
							Refresh
						</button>
						<CSVLink data={filteredData} headers={hhh} filename={"External_Logger.csv"}>
							<button disabled={externalloggerLoader} type="button" className="focus:outline-none relative z-10 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-100 font-bold rounded-lg text-sm px-4 py-2.5 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800 disabled:opacity-50">
								Export to CSV
							</button>
						</CSVLink>
					</div>
				</div>
			)}

			<div className="table-responsive mb-3 min-h-[400px]">
				<table {...dataTable.getTableProps()} className={classNames(`${props?.customTableClassName ? props.customTableClassName : `table`} table-centered react-table`, props["tableClass"])}>
					<thead className={props["theadClass"]}>
						{(dataTable.headerGroups || []).map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{(headerGroup.headers || []).map((column) => (
									<th
										{...column.getHeaderProps(
											{
												style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
											},
											column.sort && column.getSortByToggleProps()
										)}
										className={classNames({
											sorting_desc: column.isSortedDesc === true,
											sorting_asc: column.isSortedDesc === false,
											sortable: column.sort === true,
										})}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					{externalloggerLoader ? (
						<tbody>
							<tr>
								<td colSpan={props["columns"]?.length} className="text-center items-center !border-none">
									<ReactLoading
										type={"spin"}
										color={"#333"}
										height={25}
										width={25}
										style={{
											borderBottom: "none",
											textAlign: "center",
											margin: "50px auto",
											width: "50px",
											height: "50px",
										}}
									/>
								</td>
							</tr>
						</tbody>
					) : (
						<tbody {...dataTable.getTableBodyProps()}>
							{(rows || []).map((row, i) => {
								dataTable.prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{(row.cells || []).map((cell) => {
											return (
												<td
													{...cell.getCellProps({
														style: {
															className: cell.column.className,
															width: cell.column.width,
															minWidth: cell.column.minWidth,
															maxWidth: cell.column.maxWidth,
														},
													})}>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					)}
				</table>
			</div>
			{pagination && <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} totalRecords={filteredData.length} />}
		</>
	);
};

export default Table;
