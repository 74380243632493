import React from "react";


export default class AdPlacement extends React.Component {

  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({}); 
  }
  

  render(){
  return (
      <ins className="adsbygoogle"
        style={{display:'inline-block', width:window.innerWidth<780?`${window.innerWidth-(window.innerWidth*10/100)}px`:'728px', height:'90px'}}
        data-ad-client="ca-pub-3023471399174118"
        data-ad-slot="4742555558"
        data-full-width-responsive="true"
        data-ad-format="auto"
        ></ins>
  );
}
}

