import React, { useEffect } from "react";

import Footer from "../../components/Footer/Footer";

import ExternalLoggerJSON from "./ExternalLoggerJSON";

const LoggerJson = () => {

	useEffect(() => {
		document.title = `Logger JSON`;
	}, []);

	return (
		<div className='pt-4'>
			{/* <Menubar /> */}
			<section
				className="px-3 externalLoggerSection"
				style={{
					minHeight: "calc(100vh - 136px)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<ExternalLoggerJSON />
			</section>
			<Footer />
		</div>
	);
};

export default LoggerJson;
